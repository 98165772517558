import React, { Component } from 'react';

import { StyledCheckbox } from '../../../../common';
import InputPair from './InputPair';

import theme from './styles.module.scss';

class ImageSettings extends Component {
  render() {
    const { handleChange, values } = this.props;
    return (
      <>
        <InputPair
          values={values}
          handleChange={handleChange}
          nameOfField={'width'}
        />
        <InputPair
          values={values}
          handleChange={handleChange}
          nameOfField={'height'}
        />
        <StyledCheckbox
          name="aspectRatio"
          value={values.aspectRatio}
          text="Aspect ratio"
          style={{ padding: 0, height: 'auto' }}
          passSettings={handleChange}
          checked={values.aspectRatio || false}
        />
        <label htmlFor="description">Description</label>
        <input
          type="text"
          name="description"
          id={theme.imageDescription}
          onChange={handleChange}
          value={values.description}
        />
      </>
    );
  }
}

export default ImageSettings;
