import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import StyledButton from '../../common/StyledButton';
import {
  fetchEntityData,
  fetchLabelData,
  fetchCurrentLabelInfo,
  fetchLabelDataForEdit,
  fetchCurrentLabelInfoForEdit,
} from '../../../store/references';
import AutoComplete from '../AutoComplete';

import theme from './styles.module.scss';

class ReferenceModal extends Component {
  render() {
    const {
      isOpened,
      handleOpenModal,
      handleSubmitReferenceModal,
      connectors_entity,
      entity_label,
      account_data,
      fetchLabelData,
      fetchCurrentLabelInfo,
      connectorId,
      selectedReference,
      openEditingReferenceModal,
      fetchLabelDataForEdit,
      fetchCurrentLabelInfoForEdit,
      closeEditReferenceModal,
      entity_label_for_edit,
      account_data_for_edit,
    } = this.props;

    const entityLabel =
      openEditingReferenceModal && entity_label_for_edit.length
        ? entity_label_for_edit
        : entity_label;
    const accountData =
      openEditingReferenceModal && account_data_for_edit.length
        ? account_data_for_edit
        : account_data;

    return (
      <Modal
        isOpen={isOpened || openEditingReferenceModal}
        title="Add reference"
      >
        <div className={theme.addReference}>
          <header>
            <h1>Add a Reference</h1>
            <p>
              Add a Reference to your Fuse using Dynamics 365 data. Select the
              primary entity, the record and the fields that correspond to the
              values of the record you wish to display. Use the pane on the
              right side in the HTML editor to finalize your settings.
            </p>
          </header>
          <Formik
            initialValues={
              openEditingReferenceModal
                ? {
                    entity: selectedReference.entity,
                    record:
                      (entity_label &&
                        entity_label.find(
                          item => item.id === selectedReference.recordId
                        ) &&
                        entity_label.find(
                          item => item.id === selectedReference.recordId
                        ).label) ||
                      (entity_label_for_edit &&
                        entity_label_for_edit.find(
                          item => item.id === selectedReference.recordId
                        ) &&
                        entity_label_for_edit.find(
                          item => item.id === selectedReference.recordId
                        ).label) ||
                      '',
                    field: selectedReference.field,
                  }
                : {
                    entity: '',
                    record: '',
                    field: '',
                  }
            }
            onSubmit={values => handleSubmitReferenceModal(values)}
            render={({ handleSubmit, setFieldValue, values, setValues }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <AutoComplete
                    label="Dynamics 365 entity"
                    name="entity"
                    suggestions={connectors_entity.map(({ entity, label }) => ({
                      label,
                      value: entity,
                      entity,
                    }))}
                    connectorId={connectorId}
                    onChangeAutoComplete={(a, b, c) => {
                      if (openEditingReferenceModal) {
                        return fetchLabelDataForEdit(a, b, c);
                      }
                      return fetchLabelData(a, b, c);
                    }}
                    setFieldFormik={setFieldValue}
                    setValues={setValues}
                    valuesToReset={{ field: '', record: '' }}
                    valueFormik={values.entity}
                  />
                  <AutoComplete
                    label="Record"
                    name="record"
                    suggestions={entityLabel.map(({ label, id }) => ({
                      value: label,
                      label,
                      entity: values.entity,
                      id: id,
                    }))}
                    connectorId={connectorId}
                    onChangeAutoComplete={
                      openEditingReferenceModal
                        ? fetchCurrentLabelInfoForEdit
                        : fetchCurrentLabelInfo
                    }
                    setFieldFormik={setFieldValue}
                    valueFormik={values.record}
                  />
                  <AutoComplete
                    label="Field"
                    name="field"
                    suggestions={accountData.map(
                      ({ label, field, type, value }) => ({
                        label: label,
                        value: field,
                      })
                    )}
                    setFieldFormik={setFieldValue}
                    valueFormik={values.field}
                  />
                  <footer>
                    <StyledButton
                      action={() => {
                        openEditingReferenceModal
                          ? closeEditReferenceModal()
                          : handleOpenModal();
                      }}
                      width="auto"
                      variant="cancel"
                    >
                      Cancel
                    </StyledButton>
                    <StyledButton
                      type="submit"
                      width="auto"
                      disabled={Object.values(values).some(
                        value => value === ''
                      )}
                    >
                      {openEditingReferenceModal ? 'Change' : 'Add'}
                    </StyledButton>
                  </footer>
                </form>
              );
            }}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = store => {
  return {
    connectors_entity: store.references.connectors_entity,
    entity_label: store.references.entity_label,
    account_data: store.references.account_data,
    connectorId: store.fuses.selectedConnectorId,
    referencesConfig: store.fuses.referencesConfig,
    entity_label_for_edit: store.references.entity_label_for_edit,
    account_data_for_edit: store.references.account_data_for_edit,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLabelDataForEdit,
      fetchCurrentLabelInfoForEdit,
      fetchEntityData,
      fetchLabelData,
      fetchCurrentLabelInfo,
    },
    dispatch
  );

ReferenceModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  connectorId: PropTypes.number,
  fetchCurrentLabelInfo: PropTypes.func.isRequired,
  entity_label: PropTypes.array,
  connectors_entity: PropTypes.array,
  fetchLabelData: PropTypes.func.isRequired,
  handleSubmitReferenceModal: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceModal);
