import React, { Component } from 'react';
import PropTypes from 'prop-types';

import theme from './styles.module.scss';

class CheckboxInputPair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      checked: false,
    };
    this.myInp = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedLoop.id !== this.props.selectedLoop.id) {
      this.setState({
        checked: false,
      });
    }
  }

  handleChange = e => {
    const { setFieldValue, label } = this.props;
    setFieldValue(`limiting.${label}`, '');

    this.setState(
      prevState => ({
        checked: !prevState.checked,
      }),
      () => this.state.checked && this.myInp.current.focus()
    );
  };

  render() {
    const {
      handleChange,
      values,
      nameOfField,
      descriptionField,
      label,
    } = this.props;

    const { checked } = this.state;
    const isChecked = checked || (values.limiting && values.limiting[label]);

    return (
      <div className="checkboxWrapper">
        <div>
          <aside>
            <input
              type="checkbox"
              name={`limiting.${label}`}
              id={`checkbox${nameOfField}`}
              onChange={this.handleChange}
              checked={isChecked || false}
            />
            <label htmlFor={`checkbox${nameOfField}`}>{nameOfField}</label>
            <input
              id={`${nameOfField}`}
              name={`limiting.${label}`}
              type="number"
              min="0"
              className={theme.inputField}
              value={isChecked ? values.limiting[label] : ''}
              onChange={handleChange}
              disabled={!isChecked}
              ref={this.myInp}
            />
            <label>{descriptionField}</label>
          </aside>
        </div>
      </div>
    );
  }
}

CheckboxInputPair.propTypes = {
  label: PropTypes.string.isRequired,
  descriptionField: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  nameOfField: PropTypes.string.isRequired,
};

export default CheckboxInputPair;
