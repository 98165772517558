import Immutable from 'seamless-immutable';

import { baseRequest } from '../helpers/base-request';

const types = {
  FETCH_DASHBOARD_STATISTICS_REQUEST: 'FETCH_DASHBOARD_STATISTICS_REQUEST',
  FETCH_DASHBOARD_STATISTICS_SUCCESS: 'FETCH_DASHBOARD_STATISTICS_SUCCESS',
  FETCH_DASHBOARD_STATISTICS_ERROR: 'FETCH_DASHBOARD_STATISTICS_ERROR',
};

const initialState = Immutable({
  statistics_data: null,
  loading: false,
  showNotification: false,
  notificationVariant: '',
  notificationMessage: '',
});

const fetchStatisticsRequest = () => ({
  type: types.FETCH_DASHBOARD_STATISTICS_REQUEST,
});

export const fetchStatistics = () => {
  const success = response => ({
    type: types.FETCH_DASHBOARD_STATISTICS_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.FETCH_DASHBOARD_STATISTICS_ERROR,
    payload: error,
  });
  return dispatch => {
    dispatch(fetchStatisticsRequest);
    return baseRequest('GET', '/api/Account/statistics')
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DASHBOARD_STATISTICS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_DASHBOARD_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics_data: action.payload.data,
        loading: false,
      };
    case types.FETCH_DASHBOARD_STATISTICS_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };
    default:
      return state;
  }
}
