export const FontFamilyList = [
  {
    name: '',
    display_name: '-',
  },
  { name: 'Roboto', options: '400,400i,700,700i' },
  {
    name: '"Times New Roman", Times, serif',
    display_name: 'Times New Roman',
    options: '400,400i,700,700i',
  },
  {
    name: '"Courier New", Courier, monospace',
    display_name: 'Courier',
    options: '400,400i,700,700i',
  },
  { name: 'Ubuntu Condensed', options: '400' },
  { name: 'Ubuntu', options: '400,400i,700,700i' },
];

export const FontSizeList = [6, 12, 14, 16, 18, 20, 24, 26, 28, 30, 35, 40];
