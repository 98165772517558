import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { setOrderInformation, resetNotifications } from '../../store/profile';
import { DashboardLayout } from '../../components/Dashboard/DashboardLayout';
import {
  StyledInput,
  StyledCheckbox,
  StyledSelect,
  StyledButton,
} from '../../components/common/';
import { inputsData, titleText, editions, subscriptions } from './data';
import SnackBar from '../../components/Snackbar';

import theme from './styles.module.scss';

const initialValues = {
  terms: false,
  edition: 'Professional',
  subscription: 'Yearly',
};
inputsData.forEach(({ name }) => (initialValues[name] = ''));

const orderSchema = Yup.object().shape({
  city: Yup.string()
    .min(2, 'Please enter your city')
    .required('Please enter your city'),
  company: Yup.string()
    .min(2, 'Please enter your company')
    .required('Please enter your company'),
  country: Yup.string()
    .min(2, 'Please enter your country')
    .required('Please enter your country'),
  edition: Yup.string()
    .min(2, 'Please enter the edition')
    .required('Please enter the edition'),
  email: Yup.string()
    .email()
    .min(2, 'Please enter your e-mail')
    .required('Please enter your e-mail'),
  firstName: Yup.string()
    .min(2, 'Please enter your first name')
    .required('Please enter your first name'),
  lastName: Yup.string()
    .min(2, 'Please enter your last name')
    .required('Please enter your last name'),
  number: Yup.number()
    .min(6, 'Please enter your number')
    .required('Please enter your number'),
  subject: Yup.number()
    .min(2, 'Please enter the subject')
    .required('Please enter the subject'),
  postalCode: Yup.number()
    .min(6, 'Please enter your postal code')
    .required('Please enter your postal code'),
  street: Yup.string()
    .min(2, 'Please enter your street')
    .required('Please enter your street'),
  subscription: Yup.string()
    .min(2, 'Please indicate the subscription')
    .required('Please indicate the subscription'),
  vat: Yup.string()
    .min(2, 'Please enter your VAT')
    .required('Please enter your VAT'),
  terms: Yup.bool().oneOf(
    [true],
    'Please agree with the Terms & Conditions first'
  ),
});

const Order = ({
  showNotification,
  notificationMessage,
  notificationVariant,
  resetNotifications,
  setOrderInformation,
}) => {
  return (
    <DashboardLayout>
      <div className={theme.orderWrapper}>
        <p className={theme.titleText}>{titleText}</p>

        <Formik
          initialValues={initialValues}
          validationSchema={orderSchema}
          onSubmit={values => setOrderInformation(values)}
        >
          {({ handleChange, values, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} className={theme.orderForm}>
                {inputsData.map(input => (
                  <StyledInput
                    key={input.name}
                    type="text"
                    name={input.name}
                    label={input.label}
                    value={values[input.name]}
                    handleChange={handleChange}
                    required={input.required}
                    Class={theme.orderInput}
                  />
                ))}

                <div className={theme.editionBox}>
                  <StyledSelect
                    options={editions.map(option => {
                      return {
                        label: option.value,
                        value: option.value,
                        id: option.id,
                      };
                    })}
                    label="Edition"
                    required
                    name="edition"
                    value={values.edition}
                    Class={theme.orderSelect}
                  />
                </div>
                <div className={theme.editionBox}>
                  <StyledSelect
                    options={subscriptions.map(option => {
                      return {
                        label: option.value,
                        value: option.value,
                        id: option.id,
                      };
                    })}
                    label="Subscription"
                    required
                    name="subscription"
                    value={values.subscription}
                    Class={theme.orderSelect}
                  />
                </div>
                <div className={theme.checkboxContainer}>
                  <StyledCheckbox
                    name="terms"
                    text={
                      <span>
                        By choosing for the Professional Edition, I accept the
                        general
                        <a href="http://www.webfuse.io/termsandconditions">
                          Terms and Conditions.
                        </a>{' '}
                        Your license will be activated shortly.
                      </span>
                    }
                    passSettings={handleChange}
                    style={{ padding: 0, height: 30 }}
                    value={values.terms}
                  />
                  <StyledButton type="submit">Confirm</StyledButton>
                </div>
              </form>
            );
          }}
        </Formik>
        <SnackBar
          open={showNotification}
          message={notificationMessage}
          variant={notificationVariant}
          close={resetNotifications}
        />
      </div>
    </DashboardLayout>
  );
};

const mapStateToProps = store => ({
  showNotification: store.profile.showNotification,
  notificationVariant: store.profile.notificationVariant,
  notificationMessage: store.profile.notificationMessage,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setOrderInformation, resetNotifications }, dispatch);

Order.propTypes = {
  showNotification: PropTypes.bool.isRequired,
  notificationVariant: PropTypes.string.isRequired,
  notificationMessage: PropTypes.string.isRequired,
  resetNotifications: PropTypes.func.isRequired,
  setOrderInformation: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Order);
