import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page from '../../Page';
import Container from '../../Container';
import { SideBar } from '../SideBar/SideBar';
import Header from '../Header/Header';
import { Loader } from '../../common/';
import theme from './styles.module.scss';

const DashboardLayout = ({
  children,
  loadingFuses,
  loadingConnectors,
  loadingProfile,
  loadingReferences,
  loadingUsers,
}) => {
  const loading =
    loadingFuses ||
    loadingConnectors ||
    loadingProfile ||
    loadingReferences ||
    loadingUsers;
  return (
    <Page>
      {loading && <Loader />}
      <Container width={1280}>
        <section className={theme.dashboardWrapper}>
          <SideBar />
          <Header />
          <section className={theme.dashboardContent}>{children}</section>
        </section>
      </Container>
    </Page>
  );
};

const mapStateToProps = store => ({
  loadingConnectors: store.connectors.loading,
  loadingFuses: store.fuses.loading,
  loadingProfile: store.profile.loading,
  loadingReferences: store.references.loading,
  loadingUsers: store.users.loading,
});

export default connect(mapStateToProps)(DashboardLayout);

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
