import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const StyledButton = ({
  customStyle,
  children,
  link,
  Class,
  variant,
  action,
  disabled,
  type = 'submit',
  onMouseDownHandler,
}) => {
  return (
    <button
      type={type}
      className={clsx(
        styles.customButton,
        variant === 'cancel' && styles.customCancelButton,
        variant === 'danger' && styles.customDangerButton,
        Class
      )}
      style={customStyle}
      href={link}
      onClick={action}
      disabled={disabled}
      onMouseDown={onMouseDownHandler}
    >
      {children}
    </button>
  );
};

StyledButton.propTypes = {
  customStyle: PropTypes.object,
  link: PropTypes.string,
  Class: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
};
