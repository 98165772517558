import React from 'react';
import { Button, Icon } from './ToolbarControls';
import { insertImage } from '../helpers';

export const ImageButton = ({ editor, value, className, icon }) => {
  return (
    <Button
      onMouseDown={event => onClickImage({ event, editor, value, className })}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const onClickImage = ({ event, editor, value, className }) => {
  event.preventDefault();
  const src = window.prompt('Enter the URL of the image:');
  const width = window.prompt('Enter width of the image:');
  const height = window.prompt('Enter height of the image:');
  if (!src) return;
  editor.command(insertImage, src, null, width, height);
};
