import React from 'react';
import { withRouter } from 'react-router-dom';

import logoIcon from '../../../assets/logo.png';
import { logo } from './styles.module.scss';

const Logo = ({ history }) => (
  <img
    className={logo}
    src={logoIcon}
    alt="Webfuse"
    onClick={() => history.push('/')}
  />
);

export default withRouter(Logo);
