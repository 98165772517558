import React from 'react';
import clsx from 'clsx';

import { FontSizeList } from './FontList';

import { hasMark, getMark, onClickChangeFont } from './RenderComponents';

const TYPE = 'font-size';

export const FontSizeSelect = ({ editor, value, className }) => {
  let fontSizeDefault = 14;
  if (hasMark({ value, markType: TYPE })) {
    fontSizeDefault = getMark({ value, markType: TYPE }).data.get('fontSize');
  }

  return (
    <select
      className={clsx(className)}
      onChange={event => {
        onClickChangeFont({ editor, value, event, markType: TYPE });
      }}
      value={fontSizeDefault}
    >
      {FontSizeList.map((font, index) => (
        <option key={`slate-font-family-${index}`} value={font}>
          {font}
        </option>
      ))}
    </select>
  );
};
