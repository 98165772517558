import Immutable from 'seamless-immutable';

const types = {
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  CLEAR_ERROR_MESSAGE: 'CLEAR_ERROR_MESSAGE',
};

export const errorMessage = error => ({
  type: types.ERROR_MESSAGE,
  errorMessage: error,
});

export const clearErrorMessage = () => ({
  type: types.CLEAR_ERROR_MESSAGE,
});

const initialState = Immutable({
  errorMessage: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.ERROR_MESSAGE:
      return state.set('errorMessage', action.errorMessage);
    case types.CLEAR_ERROR_MESSAGE:
      return state.set('errorMessage', null);

    default:
      return state;
  }
}
