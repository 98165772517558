import React from 'react';
import { Button, Icon } from './ToolbarControls';

const hasMark = (value, type) => {
  return (
    value &&
    value.activeMarks &&
    value.activeMarks.some(mark => mark.type === type)
  );
};

export const MarkButton = ({ editor, value, type, icon }) => {
  const isActive = hasMark(value && value, type);

  return (
    <Button
      active={isActive}
      onMouseDown={event => onClickMark(event, editor, type)}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const onClickMark = (event, editor, type) => {
  event.preventDefault();
  editor.toggleMark(type);
};
