import React, { Component, createRef } from 'react';

import { StyledCheckbox } from '../../../../common';
import { settingsFormats } from '../../formats-mock';

import theme from './styles.module.scss';

class BarcodeSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedHeight: false,
      checkedWidth: false,
    };
    this.heightRef = createRef();
    this.widthRef = createRef();
  }
  enableInput = ({ target }) => {
    if (target.htmlFor === 'height') {
      this.setState(
        prevState => ({
          checkedHeight: !prevState.checkedHeight,
        }),
        () => this.heightRef.current.focus()
      );
    } else {
      this.setState(
        prevState => ({
          checkedWidth: !prevState.checkedWidth,
        }),
        () => this.widthRef.current.focus()
      );
    }
  };
  render() {
    const { handleChange, values, settings } = this.props;
    const { checkedHeight, checkedWidth } = this.state;
    const isDisabledHeight = checkedHeight || values.height;
    const isDisabledWidth = checkedWidth || values.width;
    return (
      <>
        <StyledCheckbox
          name="convertBarcode"
          value={values.ConvertBarcode}
          text="Convert to barcode"
          style={{ padding: 0, height: 'auto' }}
          checked={values.convertBarcode || false}
        />

        <label htmlFor="barcode">Type</label>
        <select name="type" onChange={handleChange} value={values.type || ''}>
          <option value="">Choose item</option>
          {settingsFormats.Barcode.Type.map(type => {
            return (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            );
          })}
        </select>
        <label htmlFor="locale">Locale</label>
        <select
          onChange={handleChange}
          name="locale"
          value={values.locale || ''}
        >
          <option value="">Choose item</option>
          {settings.locales.map(locale => (
            <option key={locale.value} value={locale.value}>
              {locale.trimmedValue}
            </option>
          ))}
        </select>
        <div className={theme.barcode}>
          <label id={theme.barcode} htmlFor="height" onClick={this.enableInput}>
            Bar height
          </label>
          <input
            onChange={handleChange}
            min="0"
            name="height"
            id={isDisabledHeight && theme.activeInput}
            type="number"
            value={values.height || ''}
            ref={this.heightRef}
            disabled={!isDisabledHeight}
          />
        </div>
        <div className={theme.barcode}>
          <label id={theme.barcode} htmlFor="width" onClick={this.enableInput}>
            Bar width
          </label>
          <input
            onChange={handleChange}
            min="0"
            name="width"
            id={isDisabledWidth && theme.activeInput}
            type="number"
            value={values.width || ''}
            ref={this.widthRef}
            disabled={!isDisabledWidth}
          />
        </div>
        <StyledCheckbox
          name="lockRatio"
          value={values.lockRatio}
          text="Lock ratio"
          style={{ padding: 0, height: 'auto' }}
          passSettings={handleChange}
          checked={values.lockRatio || false}
        />
      </>
    );
  }
}

export default BarcodeSettings;
