import React, { Component } from 'react';

import { settingsFormats, trimmedTimezones } from '../../formats-mock';

class TimeSettings extends Component {
  render() {
    const { handleChange, values, settings } = this.props;
    return (
      <>
        <label htmlFor="locale">Locale</label>
        <select
          onChange={handleChange}
          name="locale"
          value={values.locale || ''}
        >
          <option value="">Choose item</option>
          {settings.locales.map(locale => (
            <option key={locale.value} value={locale.value}>
              {locale.trimmedValue}
            </option>
          ))}
        </select>
        <label htmlFor="timezone">Timezone</label>
        <select
          onChange={handleChange}
          name="timezone"
          value={values.timezone || ''}
        >
          <option value="">Choose item</option>
          {trimmedTimezones(20).map((timezone, index) => (
            <option key={`${timezone.time} ${index}`} value={timezone.label}>
              {timezone.trimmedValue}
            </option>
          ))}
        </select>
        <label htmlFor="time">Type</label>
        <select onChange={handleChange} name="type" value={values.type || ''}>
          <option value="">Choose item</option>
          {settingsFormats.Time.types.map((type, index) => (
            <option key={`${type.time} ${index}`} value={type.format}>
              {type.time}
            </option>
          ))}
        </select>
      </>
    );
  }
}

export default TimeSettings;
