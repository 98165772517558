import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import reference from '../../../../assets/svgs/reference.svg';
import filter from '../../../../assets/svgs/filter.svg';
import form from '../../../../assets/svgs/form.svg';
import loop from '../../../../assets/svgs/loop.svg';
import {
  Toolbar,
  FontSizeSelect,
  FontFamilySelect,
  MarkButton,
  BlockButton,
  AlignButton,
  TableComponent,
  LinkButton,
  ImageButton,
} from './RenderComponents';

import './style.scss';

const markButtons = [
  {
    type: 'b',
    icon: 'format_bold',
  },
  {
    type: 'i',
    icon: 'format_italic',
  },
  {
    type: 'u',
    icon: 'format_underlined',
  },
  {
    type: 'code',
    icon: 'code',
  },
];

const alignButtons = [
  {
    className: 'align_left',
    icon: 'format_align_left',
  },
  {
    className: 'align_justify',
    icon: 'format_align_justify',
  },
  {
    className: 'align_center',
    icon: 'format_align_center',
  },
  {
    className: 'align_right',
    icon: 'format_align_right',
  },
];

const blockButtons = [
  {
    type: 'h1',
    icon: 'looks_one',
  },
  {
    type: 'h2',
    icon: 'looks_two',
  },
  {
    type: 'blockquote',
    icon: 'format_quote',
  },
  {
    type: 'ol',
    icon: 'format_list_numbered',
  },
  {
    type: 'ul',
    icon: 'format_list_bulleted',
  },
];

export const CustomToolbar = ({
  handleReferenceModalClick,
  handleInsertLoop,
  openLoopModal,
  toggleFilterModal,
  openFormModal,
  onClickRaw,
  showRaw,
  editor,
  value,
  onChange,
}) => {
  const buttonsArr = [
    { name: 'Reference', icon: reference, onClick: handleReferenceModalClick },
    { name: 'Filter', icon: filter, onClick: toggleFilterModal },
    { name: 'Form', icon: form, onClick: openFormModal },
    { name: 'Loop', icon: loop, onClick: openLoopModal },
  ];

  return (
    <div className="custom-toolbar">
      <div className="toolbar-top-section">
        {buttonsArr.map(({ name, icon, onClick }) => (
          <button className="top-toolbar-buttons" key={name} onClick={onClick}>
            <img className="top-toolbar-icon" src={icon} alt="icon" />
            {name}
          </button>
        ))}
        <button
          className={clsx(
            'top-toolbar-buttons show-html-button',
            showRaw && 'active'
          )}
          onClick={onClickRaw}
        >
          Show HTML
        </button>
      </div>
      {!showRaw && (
        <Toolbar>
          <FontFamilySelect editor={editor} value={value} />
          <FontSizeSelect editor={editor} value={value} />
          {markButtons.map(button => (
            <MarkButton
              editor={editor}
              value={value}
              type={button.type}
              icon={button.icon}
              key={`${button.icon} ${value}`}
            />
          ))}
          <div className="separator" />
          {alignButtons.map(button => (
            <AlignButton
              editor={editor}
              value={value}
              className={button.className}
              icon={button.icon}
              key={`${button.icon} ${value}`}
            />
          ))}
          <div className="separator" />
          {blockButtons.map(button => (
            <BlockButton
              editor={editor}
              value={value}
              type={button.type}
              icon={button.icon}
              key={`${button.icon} ${value}`}
            />
          ))}
          <div className="separator" />
          <LinkButton editor={editor} value={value} />
          <ImageButton editor={editor} value={value} icon="image" />
          <div className="separator" />
          <TableComponent editor={editor} value={value} onChange={onChange} />
        </Toolbar>
      )}
    </div>
  );
};

CustomToolbar.propTypes = {
  handleReferenceModalClick: PropTypes.func.isRequired,
};
