export const setSettingsToSpecificObject = ({
  object,
  filtering,
  filteringDeep,
  count = 0,
}) => {
  if (filteringDeep === count) {
    if (object) {
      object.settings
        ? (object.settings.filtering = filtering)
        : (object.settings = {
            filtering,
          });
    }
    return;
  }
  setSettingsToSpecificObject({
    object: object.children[0],
    filtering,
    filteringDeep,
    count: count + 1,
  });
};
