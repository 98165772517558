import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'react-sidebar';

class SettingsSidebar extends Component {
  render() {
    const {
      handleSettingsOpen,
      isOpened,
      children,
      className,
      content = '',
      selectedReference,
    } = this.props;
    const barStyles = {
      sidebar: {
        width: '25%',
        backgroundColor: '#e8e9ea',
        borderTop: '2px solid #fff',
      },
      overlay: {
        display: 'none',
      },
    };
    return (
      <>
        <Sidebar
          styles={barStyles}
          sidebarClassName={className}
          overlayClassName="overlay"
          open={isOpened}
          onSetOpen={handleSettingsOpen}
          sidebar={content}
          pullRight={true}
          shadow={false}
          selectedReference={selectedReference}
        >
          {children}
        </Sidebar>
      </>
    );
  }
}

SettingsSidebar.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  content: PropTypes.object,
  referenceId: PropTypes.string,
};
export default SettingsSidebar;
