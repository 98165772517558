import React, { Component } from 'react';

import { StyledCheckbox } from '../../../../common';
import { settingsFormats } from '../../formats-mock';

class PercentageSettings extends Component {
  render() {
    const { handleChange, values, settings } = this.props;
    return (
      <>
        <label htmlFor="locale">Locale</label>
        <select
          onChange={handleChange}
          name="locale"
          value={values.locale || ''}
        >
          <option value="">Choose item</option>
          {settings.locales.map(locale => (
            <option key={locale.value} value={locale.value}>
              {locale.trimmedValue}
            </option>
          ))}
        </select>
        <label htmlFor="decimalPlaces">Decimals</label>
        <select
          onChange={handleChange}
          name="decimalPlaces"
          value={values.decimalPlaces || ''}
        >
          <option value="">Choose item</option>
          {settingsFormats.Percentage.decimalPlaces.map(decimal => (
            <option key={decimal} value={decimal}>
              {decimal}
            </option>
          ))}
        </select>
        <div className="checkbox-container">
          <StyledCheckbox
            name="useSeparator"
            value={values.useSeparator}
            text="Use thousand separator"
            style={{ padding: 0, height: 'auto' }}
            passSettings={handleChange}
            checked={values.useSeparator || false}
          />
        </div>
      </>
    );
  }
}

export default PercentageSettings;
