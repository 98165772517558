import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../Modal';

import close_icon from '../../../assets/close-cancel-img.png';

import './styles.scss';

class FusePreviewModal extends Component {
  render() {
    const { isOpened, templatePreview, handleClosePreviewModal } = this.props;
    return (
      <Modal title="Preview modal" isOpen={isOpened}>
        <section className="previewModal">
          <header>
            <h3>Preview Page</h3>
            <img
              onClick={handleClosePreviewModal}
              src={close_icon}
              alt="close-icon"
            />
          </header>
          <main
            className="mainPreview"
            dangerouslySetInnerHTML={{
              __html: templatePreview,
            }}
          />
        </section>
      </Modal>
    );
  }
}

FusePreviewModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleClosePreviewModal: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    templatePreview: store.fuses.templatePreview,
  };
};

export default connect(
  mapStateToProps,
  null
)(FusePreviewModal);
