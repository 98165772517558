import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import { StyledButton } from '../';

import theme from './styles.module.scss';

export const ConfirmModal = ({
  open,
  action,
  text,
  header,
  deletedItems = '',
  handleClose,
}) => {
  return (
    <Modal
      isOpen={open}
      className={theme.confirmModal}
      title="Are you sure, you want delete?"
    >
      <h2>{header}</h2>
      <p>
        {text}
        {deletedItems && <span>{deletedItems.join(', ')} ?</span>}
      </p>
      <div className={theme.modalButtons}>
        <StyledButton action={handleClose} variant="cancel">
          Cancel
        </StyledButton>
        <StyledButton
          action={action}
          customStyle={{ marginRight: '10px' }}
          variant="danger"
        >
          Remove
        </StyledButton>
      </div>
    </Modal>
  );
};
ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
  text: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  deletedItems: PropTypes.array,
};
