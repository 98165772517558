import React from 'react';

import { DashboardLayout } from '../components/Dashboard/DashboardLayout';

export const Activity = () => (
  <DashboardLayout>
    <div>
      <p>activity tab</p>
    </div>
  </DashboardLayout>
);
