import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import {
  fetchSettingsLocales,
  fetchSettingsCurrencies,
} from '../../../store/references';
import { settingsSwitcher } from './settingsSwitcher';
import { settingsFormats } from './formats-mock';
import theme from './styles.module.scss';
import { StyledButton } from '../../common';
import { getCurrentFormat } from '../HtmlEditor/helpers/getCurrentSettingsFormat';

class ReferenceSettings extends Component {
  componentDidMount() {
    const { fetchSettingsLocales, fetchSettingsCurrencies } = this.props;
    fetchSettingsLocales();
    fetchSettingsCurrencies();
  }

  render() {
    const {
      settings_locales,
      settings_currencies,
      selectedItem,
      setSettings,
      account_data,
      handleCloseSidebar,
      openEdit,
      title,
    } = this.props;

    const initialFormat =
      selectedItem.settings && getCurrentFormat(selectedItem.settings);

    const selectedItemValues = selectedItem.settings && {
      ...selectedItem.settings[initialFormat],
      format: initialFormat,
    };

    return (
      <section className={theme.referenceSettings}>
        <header>
          <h3>{title}</h3>
        </header>
        <article className={theme.generalContainer}>
          <div className={theme.header_wrapper}>
            <h4>General</h4>
            {openEdit && (
              <StyledButton
                customStyle={{
                  width: '100px',
                  color: '#226cad',
                  background: 'transparent',
                  padding: '0',
                  margin: '0',
                  height: 'auto',
                }}
                action={openEdit}
              >
                <i className="fas fa-cog"></i>Edit
              </StyledButton>
            )}
          </div>
          <Formik>
            <form>
              <label htmlFor="name">Field name</label>
              <input
                value={(selectedItem && selectedItem.field) || ''}
                name="fieldName"
                id="name"
                type="text"
                disabled
              />
              <label htmlFor="value">Field value</label>
              <input
                value={(selectedItem && account_data[selectedItem.field]) || ''}
                name="fieldValue"
                id="value"
                type="text"
                disabled
              />
            </form>
          </Formik>
        </article>
        <article className={theme.formattingContainer}>
          <h4>Formatting settings</h4>
          <Formik
            initialValues={selectedItemValues}
            enableReinitialize
            onSubmit={values => {
              setSettings(values);
              handleCloseSidebar();
            }}
          >
            {({ handleSubmit, handleChange, values = {}, setValues }) => {
              return (
                <form>
                  <label htmlFor="format">Format as</label>
                  <select
                    onChange={e => {
                      const cloneSelectedItemValues = selectedItemValues;
                      delete cloneSelectedItemValues.format;
                      Object.keys(cloneSelectedItemValues).length
                        ? setValues({
                            format: e.target.value,
                          })
                        : setValues({
                            ...selectedItemValues,
                            format: e.target.value,
                          });
                    }}
                    className={theme.settingsFormats}
                    name="format"
                    value={values.format}
                  >
                    {Object.values(settingsFormats).map(format => (
                      <option key={format.name}>{format.name}</option>
                    ))}
                  </select>
                  {settingsSwitcher({
                    values,
                    handleChange,
                    settings_locales,
                    settings_currencies,
                  })}
                  <StyledButton
                    action={handleSubmit}
                    customStyle={{ margin: '10px' }}
                  >
                    Apply
                  </StyledButton>
                </form>
              );
            }}
          </Formik>
        </article>
      </section>
    );
  }
}

ReferenceSettings.propTypes = {
  setSettings: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired,
};

const mapStateToProps = store => ({
  settings_locales: store.references.settings_locales,
  settings_currencies: store.references.settings_currencies,
  account_data: store.references.account_data,
});

const mapDispatchToProps = dispatch => ({
  fetchSettingsLocales: () => dispatch(fetchSettingsLocales()),
  fetchSettingsCurrencies: () => dispatch(fetchSettingsCurrencies()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceSettings);
