import React, { useRef, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { StyledButton } from '../../common/';
import theme from './styles.module.scss';

const formSettingsSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  submit: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  redirectUrl: Yup.string().url(),
});

const FormSettings = ({
  handleCloseSidebar,
  selectedForm,
  handleFormSettings,
}) => {
  const inputEl = useRef(null);
  const [disabled, setDisabled] = useState(true);
  return (
    <section className={theme.formSettings}>
      <header>
        <h3>Form settings</h3>
      </header>
      <Formik
        initialValues={{
          name: selectedForm.name || '',
          submitAction: selectedForm.submitAction || '1',
          submit: selectedForm.submit || '',
          successMessage: selectedForm.successMessage || '',
          redirectUrl: selectedForm.redirectUrl || '',
        }}
        validationSchema={formSettingsSchema}
        enableReinitialize
        onSubmit={values => {
          handleFormSettings(values);
          handleCloseSidebar();
        }}
      >
        {({ handleSubmit, handleChange, values = {}, setValues }) => {
          return (
            <>
              <article className={theme.generalContainer}>
                <div className={theme.editWrapper}>
                  <h4>General options</h4>
                  <StyledButton
                    customStyle={{
                      color: '#226cad',
                      background: 'transparent',
                      padding: '0',
                      height: 'auto',
                      marginRight: '15px',
                    }}
                    action={async () => {
                      await setDisabled(false);
                      inputEl.current.focus();
                    }}
                  >
                    <i className="fas fa-cog"></i>Edit
                  </StyledButton>
                </div>

                <form>
                  <label htmlFor="name">Name</label>
                  <input
                    ref={inputEl}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    id="name"
                    type="text"
                    disabled={disabled}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className={theme.warningMsg}
                  />
                </form>
              </article>
              <article className={theme.formattingContainer}>
                <h4>Submit settings</h4>

                <form>
                  <label htmlFor="name">Submit button label</label>
                  <input
                    value={values.submit}
                    onChange={handleChange}
                    name="submit"
                    id="name"
                    type="text"
                  />
                  <ErrorMessage
                    name="submit"
                    component="div"
                    className={theme.warningMsg}
                  />
                  <label>
                    <input
                      className={theme.radioBox}
                      type="radio"
                      name="submitAction"
                      value={'1'}
                      onChange={handleChange}
                      checked={'1' === values.submitAction}
                    />
                    Display success message
                  </label>
                  <label>
                    <input
                      className={theme.radioBox}
                      type="radio"
                      name="submitAction"
                      value={'2'}
                      onChange={handleChange}
                      checked={'2' === values.submitAction}
                    />
                    Redirect to another page
                  </label>
                  {values.submitAction === '1' ? (
                    <>
                      <label htmlFor="name">Success message</label>
                      <textarea
                        name="successMessage"
                        type="text"
                        value={values.successMessage}
                        onChange={handleChange}
                      />
                    </>
                  ) : (
                    <>
                      <label htmlFor="name">Redirect URL</label>
                      <input
                        value={values.redirectUrl}
                        onChange={handleChange}
                        name="redirectUrl"
                        id="name"
                        type="text"
                      />
                      <ErrorMessage
                        name="redirectUrl"
                        component="div"
                        className={theme.warningMsg}
                      />
                    </>
                  )}
                  <StyledButton
                    action={handleSubmit}
                    customStyle={{ margin: '10px' }}
                  >
                    Apply
                  </StyledButton>
                </form>
              </article>
            </>
          );
        }}
      </Formik>
    </section>
  );
};

export default FormSettings;
