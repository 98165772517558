import Immutable from 'seamless-immutable';

import { baseRequest } from '../helpers/base-request';
import { isString } from '../helpers/isString';
import { history } from '../helpers/history';

const types = {
  CREATE_FUSE_REQUEST: 'CREATE_FUSE_REQUEST',
  CREATE_FUSE_SUCCESS: 'CREATE_FUSE_SUCCESS',
  CREATE_FUSE_ERROR: 'CREATE_FUSE_ERROR',

  DELETE_FUSE_REQUEST: 'DELETE_FUSE_REQUEST',
  DELETE_FUSE_SUCCESS: 'DELETE_FUSE_SUCCESS',
  DELETE_FUSE_ERROR: 'DELETE_FUSE_ERROR',

  FETCH_FUSES_REQUEST: 'FETCH_FUSES_REQUEST',
  FETCH_FUSES_SUCCESS: 'FETCH_FUSES_SUCCESS',
  FETCH_FUSES_ERROR: 'FETCH_FUSES_ERROR',
  ADD_CONNECTOR_ID: 'ADD_CONNECTOR_ID',

  ADD_FUSE_ID: 'ADD_FUSE_ID',

  SAVE_TEMPLATE_DATA_REQUEST: 'SAVE_TEMPLATE_DATA_REQUEST',
  SAVE_TEMPLATE_DATA_SUCCESS: 'SAVE_TEMPLATE_DATA_SUCCESS',
  SAVE_TEMPLATE_DATA_ERROR: 'SAVE_TEMPLATE_DATA_ERROR',
  STORE_TEMPLATE_DATA: 'STORE_TEMPLATE_DATA',

  TEMPLATE_PREVIEW_REQUEST: 'TEMPLATE_PREVIEW_REQUEST',
  TEMPLATE_PREVIEW_SUCCESS: 'TEMPLATE_PREVIEW_SUCCESS',
  TEMPLATE_PREVIEW_ERROR: 'TEMPLATE_PREVIEW_ERROR',

  FETCH_TEMPLATE_DATA_REQUEST: 'FETCH_TEMPLATE_DATA_REQUEST',
  FETCH_TEMPLATE_DATA_SUCCESS: 'FETCH_TEMPLATE_DATA_SUCCESS',
  FETCH_TEMPLATE_DATA_ERROR: 'FETCH_TEMPLATE_DATA_ERROR',
  CLEAR_TEMPLATE_DATA: 'CLEAR_TEMPLATE_DATA',

  FETCH_TEMPLATE_DELTA_REQUEST: 'FETCH_TEMPLATE_DELTA_REQUEST',
  FETCH_TEMPLATE_DELTA_SUCCESS: 'FETCH_TEMPLATE_DELTA_SUCCESS',
  FETCH_TEMPLATE_DELTA_ERROR: 'FETCH_TEMPLATE_DELTA_ERROR',

  FETCH_SNIPPET_REQUEST: 'FETCH_SNIPPET_REQUEST',
  FETCH_SNIPPET_SUCCESS: 'FETCH_SNIPPET_SUCCESS',
  FETCH_SNIPPET_ERROR: 'FETCH_SNIPPET_ERROR',

  FETCH_OPTIONS_FOR_SELECT_INPUT_REQUEST:
    'FETCH_OPTIONS_FOR_SELECT_INPUT_REQUEST',
  FETCH_OPTIONS_FOR_SELECT_INPUT_SUCCESS:
    'FETCH_OPTIONS_FOR_SELECT_INPUT_SUCCESS',
  FETCH_OPTIONS_FOR_SELECT_INPUT_ERROR: 'FETCH_OPTIONS_FOR_SELECT_INPUT_ERROR',

  SAVE_SNIPPET_OWNER_REQUEST: 'SAVE_SNIPPET_OWNER_REQUEST',
  SAVE_SNIPPET_OWNER_SUCCESS: 'SAVE_SNIPPET_OWNER_SUCCESS',
  SAVE_SNIPPET_OWNER_ERROR: 'SAVE_SNIPPET_OWNER_ERROR',

  RESET_SNIPPET_DATA: 'RESET_SNIPPET_DATA',
  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',

  THROW_ERROR_IN_LOOP: 'THROW_ERROR_IN_LOOP',
};

export const resetNotifications = () => ({
  type: types.RESET_NOTIFICATIONS,
});

export const clearTemplateData = () => ({
  type: types.CLEAR_TEMPLATE_DATA,
});

const createFuseRequest = () => ({
  type: types.CREATE_FUSE_REQUEST,
});

const deleteFuseRequest = () => ({
  type: types.DELETE_FUSE_REQUEST,
});

const fetchFusesRequest = () => ({
  type: types.FETCH_FUSES_REQUEST,
});

const saveTemplateDataRequest = () => ({
  type: types.SAVE_TEMPLATE_DATA_REQUEST,
});

const fetchTemplateDataRequest = () => ({
  type: types.FETCH_TEMPLATE_DATA_REQUEST,
});

const fetchTemplateDeltaRequest = () => ({
  type: types.FETCH_TEMPLATE_DELTA_REQUEST,
});

const getTemplatePreviewRequest = () => ({
  type: types.TEMPLATE_PREVIEW_REQUEST,
});

const getSelectOptionsRequest = () => ({
  type: types.FETCH_OPTIONS_FOR_SELECT_INPUT_REQUEST,
});

const fetchSnippetRequest = () => ({
  type: types.FETCH_SNIPPET_REQUEST,
});

export const resetSnippetData = () => ({
  type: types.RESET_SNIPPET_DATA,
});

const saveSnippetOwnerRequest = () => ({
  type: types.SAVE_SNIPPET_OWNER_REQUEST,
});

export const addSelectedConnectorId = connectorId => ({
  type: types.ADD_CONNECTOR_ID,
  payload: connectorId,
});

export const addSelectedFuseId = id => ({
  type: types.ADD_FUSE_ID,
  payload: id,
});

export const storeTemplateData = templateData => ({
  type: types.STORE_TEMPLATE_DATA,
  payload: templateData,
});

const initialState = Immutable({
  fuses: [],
  error: false,
  fuse_id: null,
  selectedConnectorId: null,
  selectedFuseId: null,
  templateData: {},
  fetchedTemplate: null,
  editorDelta: null,
  referencesConfig: null,
  templatePreview: null,
  selectOptions: [],
  snippet: {},
  loading: false,
  showNotification: false,
  notificationVariant: '',
  notificationMessage: '',
});

export const createFuse = (name, id) => {
  const success = response => {
    history.push(`/fuses/${response.data.id}/edit`);
    return {
      type: types.CREATE_FUSE_SUCCESS,
      payload: response,
    };
  };

  const failure = error => ({
    type: types.CREATE_FUSE_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(createFuseRequest());
    return baseRequest('POST', `/api/Fuses`, { name: name, connectorId: id })
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const removeFuses = fuses => {
  const success = id => {
    return {
      type: types.DELETE_FUSE_SUCCESS,
      fuse_id: id,
    };
  };
  const failure = error => {
    return {
      type: types.DELETE_FUSE_ERROR,
      payload: isString(error),
    };
  };

  return dispatch => {
    dispatch(deleteFuseRequest());
    fuses.forEach(async id => {
      baseRequest('DELETE', `/api/Fuses/${id}`)
        .then(() => dispatch(success(id)))
        .catch(error => dispatch(failure(error)));
    });
  };
};

export const fetchFuses = () => {
  const success = response => ({
    type: types.FETCH_FUSES_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.FETCH_FUSES_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchFusesRequest());
    return baseRequest('GET', '/api/Fuses')
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchTemplate = selectedFuseId => {
  const success = response => ({
    type: types.FETCH_TEMPLATE_DATA_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.FETCH_TEMPLATE_DATA_ERROR,
    payload: isString(error),
  });
  return dispatch => {
    dispatch(fetchTemplateDataRequest());
    baseRequest('GET', `/api/Fuses/${selectedFuseId}/template`)
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchEditorDelta = selectedFuseId => {
  const success = response => ({
    type: types.FETCH_TEMPLATE_DELTA_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.FETCH_TEMPLATE_DELTA_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchTemplateDeltaRequest());
    return baseRequest('GET', `/api/Fuses/${selectedFuseId}/config`)
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => dispatch(failure(error)));
  };
};

export const saveTemplateData = (templateData, selectedFuseId) => {
  const success = response => ({
    type: types.SAVE_TEMPLATE_DATA_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.SAVE_TEMPLATE_DATA_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(saveTemplateDataRequest());
    return baseRequest(
      'POST',
      `/api/Fuses/${selectedFuseId}/save`,
      templateData
    )
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const getTemplatePreview = (templateData, selectedFuseId) => {
  const success = response => ({
    type: types.TEMPLATE_PREVIEW_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.TEMPLATE_PREVIEW_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(getTemplatePreviewRequest());
    return baseRequest(
      'POST',
      `/api/Fuses/${selectedFuseId}/preview`,
      templateData
    )
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchSnippet = selectedFuseId => {
  const success = response => ({
    type: types.FETCH_SNIPPET_SUCCESS,
    payload: response.data,
  });

  const failure = error => ({
    type: types.FETCH_SNIPPET_ERROR,
    payload: isString(error),
  });
  return dispatch => {
    dispatch(fetchSnippetRequest());

    return baseRequest('GET', `/api/Fuses/${selectedFuseId}/snippets`)
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };
};

export const saveSnippetOwner = (selectedFuseId, data) => {
  const success = response => ({
    type: types.SAVE_SNIPPET_OWNER_SUCCESS,
    payload: response.data,
  });

  const failure = error => ({
    type: types.SAVE_SNIPPET_OWNER_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(saveSnippetOwnerRequest());

    return baseRequest('PUT', `/api/Fuses/${selectedFuseId}`, data)
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };
};

export const fetchSelectOptions = (connectorId, entity, field) => {
  const success = response => ({
    type: types.FETCH_OPTIONS_FOR_SELECT_INPUT_SUCCESS,
    payload: response.data,
  });

  const failure = error => ({
    type: types.FETCH_OPTIONS_FOR_SELECT_INPUT_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(getSelectOptionsRequest());

    return baseRequest(
      'GET',
      `/api/Connectors/${connectorId}/entities/${entity}/fields/${field}/options`
    )
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };
};

export const throwErrorInLoop = error => {
  const failure = error => ({
    type: types.THROW_ERROR_IN_LOOP,
    payload: error,
  });
  return dispatch => {
    return dispatch(failure(error));
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_FUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_FUSE_SUCCESS:
      return {
        ...state,
        fuses: [...state.fuses, action.payload.data],
        selectedFuseId: action.payload.data.id,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Fuse was successfully created, start editing now',
      };

    case types.CREATE_FUSE_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_OPTIONS_FOR_SELECT_INPUT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_OPTIONS_FOR_SELECT_INPUT_SUCCESS:
      return {
        ...state,
        selectOptions: action.payload,
        loading: false,
      };
    case types.FETCH_OPTIONS_FOR_SELECT_INPUT_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_FUSES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_FUSES_SUCCESS:
      return {
        ...state,
        fuses: action.payload.data,
        loading: false,
      };

    case types.FETCH_FUSES_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.DELETE_FUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.DELETE_FUSE_SUCCESS:
      const filteredFuses = state.fuses.filter(
        fuse => fuse.id !== action.fuse_id
      );
      return {
        ...state,
        fuses: filteredFuses,
        fuse_id: null,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Fuse was successfully removed',
      };

    case types.DELETE_FUSE_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.ADD_CONNECTOR_ID:
      return {
        ...state,
        selectedConnectorId: action.payload,
      };

    case types.ADD_FUSE_ID:
      return {
        ...state,
        selectedFuseId: action.payload,
      };

    case types.SAVE_TEMPLATE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SAVE_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Fuse is successfully saved',
      };

    case types.SAVE_TEMPLATE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.TEMPLATE_PREVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        templatePreview: null,
      };

    case types.TEMPLATE_PREVIEW_SUCCESS:
      return {
        ...state,
        templatePreview: action.payload.data,
        loading: false,
      };

    case types.TEMPLATE_PREVIEW_ERROR:
      return {
        ...state,
        templatePreview: action.payload.data,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.STORE_TEMPLATE_DATA:
      return {
        ...state,
        templateData: action.payload,
      };

    case types.FETCH_TEMPLATE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        fetchedTemplate: action.payload.data,
        loading: false,
      };

    case types.FETCH_TEMPLATE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_TEMPLATE_DELTA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_TEMPLATE_DELTA_SUCCESS:
      return {
        ...state,
        editorDelta: action.payload.data.delta,
        referencesConfig: action.payload.data,
        loading: false,
      };

    case types.FETCH_TEMPLATE_DELTA_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.CLEAR_TEMPLATE_DATA:
      return {
        ...state,
        editorDelta: null,
        referencesConfig: null,
        fetchedTemplate: null,
        templatePreview: null,
      };

    case types.FETCH_SNIPPET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_SNIPPET_SUCCESS:
      return {
        ...state,
        snippet: action.payload,
        loading: false,
      };

    case types.FETCH_SNIPPET_ERROR:
      return {
        ...state,
        snippet: {},
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.RESET_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        showNotification: false,
        notificationVariant: '',
        notificationMessage: '',
      };

    case types.THROW_ERROR_IN_LOOP:
      return {
        ...state,
        showNotification: true,
        notificationVariant: 'warning',
        notificationMessage: action.payload,
      };

    case types.RESET_SNIPPET_DATA:
      return {
        ...state,
        snippet: {},
      };

    case types.SAVE_SNIPPET_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SAVE_SNIPPET_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Snippet updated',
      };
    case types.SAVE_SNIPPET_OWNER_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    default:
      return state;
  }
}
