import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { customStyles, customStylesSuccess } from './style';

import './style.scss';

class AutoComplete extends Component {
  setFormikField = selected => {
    const {
      name,
      setFieldFormik,
      onChangeAutoComplete,
      fetchOptions,
      connectorId,
      setReferenceRecordId,
      setValues = null,
      valuesToReset,
    } = this.props;

    const valueToSet = setReferenceRecordId
      ? setReferenceRecordId(selected.value)
      : selected.value;

    setValues && setValues(valuesToReset);
    setFieldFormik(name, valueToSet);
    onChangeAutoComplete &&
      onChangeAutoComplete(connectorId, selected.entity, selected.id);
    fetchOptions && selected.value === 'select' && fetchOptions();
  };

  render() {
    const {
      suggestions = [],
      name,
      label,
      isDisabled,
      valueFormik,
      required = true,
    } = this.props;
    const value =
      suggestions.find(option => option.value === valueFormik) || '';

    return (
      <>
        <label
          className={clsx(
            required ? 'select-label-required' : 'select-label',
            isDisabled && 'autocomplete-disabled'
          )}
        >
          {label}
        </label>
        <Select
          isDisabled={isDisabled}
          styles={valueFormik ? customStylesSuccess : customStyles}
          options={suggestions}
          value={value}
          onChange={this.setFormikField}
          name={name}
          className={clsx(isDisabled && 'autocomplete-disabled')}
        />
      </>
    );
  }
}

AutoComplete.propTypes = {
  name: PropTypes.string,
  suggestions: PropTypes.array,
};

export default AutoComplete;
