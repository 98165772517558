import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { loginUser, resetNotifications } from '../../store/authorization';

import { Layout } from '../../components/Layout';
import {
  StyledButton,
  Logo,
  StyledInput,
  Loader,
} from '../../components/common';
import { SnackBar } from '../../components/';
import {
  validateEmail,
  validateNameforSpaces,
} from '../../helpers/validations';

import theme from './styles.module.scss';

const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required')
    .test(
      'validateEmail',
      'E-mail is not allowed, please use a registered company domain',
      value => validateEmail(value)
    ),
  password: Yup.string()
    .required('Required')
    .min(6, 'Please use a minimum of 6 characters')
    .max(20, 'Please use a maximum of 20 characters')
    .test(
      'Warning, password can`t contain spaces',
      'Warning, password can`t contain spaces',
      value => validateNameforSpaces(value)
    ),
});

class SignIn extends Component {
  state = {
    verifyEmailSuccessNotification: false,
    verifyEmailErrorNotification: false,
  };

  componentDidMount() {
    this.handleShowNotification();
  }

  closeSnackbarHandler = () => {
    this.setState({
      verifyEmailSuccessNotification: false,
      signUpNotification: false,
      wrongPasswordNotification: false,
      verifyEmailErrorNotification: false,
    });
    this.props.resetNotifications();
  };

  handleShowNotification() {
    if (new URLSearchParams(window.location.search).get('verifyEmail')) {
      const verifyEmail = new URLSearchParams(window.location.search).get(
        'verifyEmail'
      );

      if (verifyEmail === 'true') {
        this.setState({
          verifyEmailSuccessNotification: true,
          verifyEmailErrorNotification: false,
        });
      } else if (verifyEmail === 'false')
        this.setState({
          verifyEmailErrorNotification: true,
          verifyEmailSuccessNotification: false,
        });
    }
  }

  render() {
    const {
      loginUser,
      notificationMessage,
      notificationVariant,
      showNotification,
      resetNotifications,
      loading,
    } = this.props;
    const {
      verifyEmailSuccessNotification,
      verifyEmailErrorNotification,
    } = this.state;
    return (
      <>
        {loading && <Loader />}
        <Layout>
          <section className={theme.signinWrapper}>
            <Logo />
            <p>Please use your credentials to log in.</p>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={signInSchema}
              onSubmit={values => {
                loginUser(values);
                setTimeout(() => {
                  resetNotifications();
                }, 3000);
              }}
            >
              {({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <StyledInput type="email" name="email" label="E-mail" />
                    <StyledInput
                      type="password"
                      name="password"
                      label="Password"
                    />
                    <StyledButton customStyle={{ width: '100%' }} type="submit">
                      Sign In
                    </StyledButton>
                  </form>
                );
              }}
            </Formik>
            <p>
              <Link to="/reset-password" className="sign-in-link">
                Forgot your password?
              </Link>
            </p>
            <p>
              Don`t have an account yet?{' '}
              <Link to="/registration" className="sign-in-link">
                Sign up now!
              </Link>
            </p>
            <SnackBar
              open={showNotification}
              variant={notificationVariant}
              message={notificationMessage}
              close={resetNotifications}
            />

            {(verifyEmailSuccessNotification ||
              verifyEmailErrorNotification) && (
              <SnackBar
                variant={verifyEmailSuccessNotification ? 'success' : 'warning'}
                open={
                  verifyEmailSuccessNotification || verifyEmailErrorNotification
                }
                message={
                  verifyEmailSuccessNotification
                    ? 'E-mail verified. Enjoy Webfuse!'
                    : 'Please check your e-mail to finalize registration'
                }
                close={this.closeSnackbarHandler}
              />
            )}
          </section>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = store => {
  return {
    showNotification: store.authorization.showNotification,
    notificationVariant: store.authorization.notificationVariant,
    notificationMessage: store.authorization.notificationMessage,
    loading: store.authorization.loading,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginUser, resetNotifications }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
