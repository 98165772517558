import React, { Component } from 'react';

import { StyledCheckbox } from '../../../../common/StyledCheckbox/StyledCheckbox';
import { settingsFormats } from '../../formats-mock';

import theme from './styles.module.scss';

class CurrencySettings extends Component {
  render() {
    const { handleChange, values, settings } = this.props;

    return (
      <>
        <label htmlFor="symbol">Symbol</label>
        <select
          onChange={handleChange}
          name="symbol"
          value={values.symbol || ''}
        >
          <option value="">Choose item</option>
          {settings.currencies.map(currency => (
            <option key={currency.label} value={String(currency.value)}>
              {currency.trimmedValue}
            </option>
          ))}
        </select>
        <div className={theme.radioBox}>
          {settingsFormats.Currency.symbolPositions.map(symbolPosition => (
            <aside key={symbolPosition.label}>
              <input
                id={symbolPosition.label}
                name="symbolPosition"
                type="radio"
                value={symbolPosition.value}
                onChange={handleChange}
                checked={values.symbolPosition === symbolPosition.value}
              />
              <label htmlFor={symbolPosition.label}>
                {symbolPosition.label}
              </label>
            </aside>
          ))}
        </div>
        <label htmlFor="decimalPlaces">Decimals</label>
        <select
          onChange={handleChange}
          name="decimalPlaces"
          value={values.decimalPlaces || ''}
        >
          <option value="">Choose item</option>
          {settingsFormats.Currency.decimalPlaces.map(decimal => (
            <option key={decimal} value={decimal}>
              {decimal}
            </option>
          ))}
        </select>
        <label htmlFor="locale">Locale</label>
        <select
          onChange={handleChange}
          name="locale"
          value={values.locale || ''}
        >
          <option value="">Choose item</option>
          {settings.locales.map(locale => (
            <option key={locale.value} value={locale.value}>
              {locale.trimmedValue}
            </option>
          ))}
        </select>

        <div className="checkbox-container">
          <StyledCheckbox
            name="useSeparator"
            value={values.useSeparator}
            text="Use thousand separator"
            style={{ padding: 0, height: 'auto' }}
            passSettings={handleChange}
            checked={values.useSeparator || false}
          />
        </div>
      </>
    );
  }
}

export default CurrencySettings;
