import yellow_tr from '../../assets/svgs/yellow-triangle.svg';
import grey_tr1 from '../../assets/svgs/grey-tr-1.svg';
import grey_tr2 from '../../assets/svgs/grey-tr-2.svg';
import grey_tr3 from '../../assets/svgs/grey-tr-3.svg';
import green_tr from '../../assets/svgs/green-triangle.svg';

export const Images = [
  { img: yellow_tr, class: 'yellow-tr' },
  { img: grey_tr1, class: 'grey-tr-1' },
  { img: grey_tr2, class: 'grey-tr-2' },
  { img: grey_tr3, class: 'grey-tr-3' },
];

export const greenTriangle = green_tr;
