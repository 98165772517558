import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import theme from './styles.module.scss';

export const CustomCheckbox = ({
  link,
  text,
  linkText,
  passSettings,
  checked,
  disabled,
  field: { name, value, onChange },
}) => {
  return (
    <label htmlFor={name} className={theme.checkboxLabel}>
      <input
        type="checkbox"
        field={name}
        id={name}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={e => (passSettings ? passSettings(e, onChange) : onChange(e))}
      />
      <span />
      <span className={theme.agreeTxt}>{text}</span>
    </label>
  );
};

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export const StyledCheckbox = ({
  name,
  text,
  linkText,
  style,
  passSettings,
  checked,
  disabled,
  link = '#',
}) => {
  return (
    <div className={theme.checkboxWrapper} style={style}>
      <Field
        name={name}
        component={CustomCheckbox}
        text={text}
        linkText={linkText}
        passSettings={passSettings}
        checked={checked}
        disabled={disabled}
        link={link}
      />
      <ErrorMessage name={name} component="div" className={theme.warningMsg} />
    </div>
  );
};

StyledCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  linkText: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};
