import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  changeUserName,
  changeUserCompany,
  changeUserEmail,
  changeUserPassword,
  fetchUserData,
  resetNotifications,
} from '../../store/profile';
import { StyledInput, StyledButton } from '../../components/common';
import { SnackBar } from '../../components/';
import { DashboardLayout } from '../../components/Dashboard/DashboardLayout';
import PasswordStrength from '../../components/PasswordStrength/PasswordStrength';
import { validateNameforSpaces } from '../../helpers/validations';

import theme from './styles.module.scss';

const profileNameSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Please enter your first name')
    .required('Required'),

  lastName: Yup.string()
    .min(2, 'Please enter your last name')
    .required('Required'),

  companyName: Yup.string()
    .min(2, 'Please enter your company name')
    .required('Required'),
  email: Yup.string()
    .email()
    .required('Required')
    .test(
      'Warning, e-mail can`t contain spaces',
      'Warning, e-mail can`t contain spaces',
      value => validateNameforSpaces(value)
    ),
});

const profilePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(6, 'Please use a minimum of 6 characters')
    .max(20, 'Please use a maximum of 20 characters ')
    .required('Required')
    .test(
      'Warning, password can`t contain spaces',
      'Warning, password can`t contain spaces',
      value => validateNameforSpaces(value)
    ),
  newPassword: Yup.string()
    .min(6, 'Please use a minimum of 6 characters')
    .max(20, 'Please use a maximum of 20 characters ')
    .required('Required')
    .test(
      'Warning, password can`t contain spaces',
      'Warning, password can`t contain spaces',
      value => validateNameforSpaces(value)
    ),
});

class Profile extends Component {
  state = {
    profileDisableButton: true,
    passwordDisableButton: true,
  };

  handleChange = ({ target }) => {
    if (target.value === this.props.userData[target.name]) {
      this.setState({ profileDisableButton: true });
    } else if (this.props.userData[target.name]) {
      this.setState({ profileDisableButton: false });
    }
    if (!this.props.userData[target.name] && target.value.length > 5) {
      this.setState({ passwordDisableButton: false });
    } else {
      this.setState({ passwordDisableButton: true });
    }
  };

  componentDidMount() {
    this.props.fetchUserData();
  }

  render() {
    const {
      userData,
      changeUserPassword,
      changeUserEmail,
      changeUserCompany,
      changeUserName,
      showNotification,
      notificationVariant,
      notificationMessage,
      resetNotifications,
    } = this.props;

    const { passwordDisableButton, profileDisableButton } = this.state;

    return (
      <DashboardLayout>
        <section className={theme.profileWrapper}>
          <p className={theme.titleText}>Profile settings</p>
          {userData &&
          userData.firstName &&
          userData.lastName &&
          userData.email ? (
            <Formik
              initialValues={userData}
              validationSchema={profileNameSchema}
              onSubmit={values => {
                const { firstName, lastName, companyName, email } = values;
                Promise.all([
                  changeUserName({ firstName, lastName }),
                  changeUserCompany({ name: companyName }),
                  changeUserEmail({ newEmail: email }),
                ]);
              }}
            >
              {({ handleSubmit, touched, values }) => {
                return (
                  <form onSubmit={handleSubmit} className={theme.signUpForm}>
                    <SnackBar
                      open={showNotification}
                      message={notificationMessage}
                      variant={notificationVariant}
                      close={resetNotifications}
                    />

                    <StyledInput
                      type="text"
                      name="firstName"
                      label="First Name"
                      handleChange={this.handleChange}
                      value={values.firstName || ''}
                    />
                    <StyledInput
                      type="text"
                      name="lastName"
                      label="Last Name"
                      handleChange={this.handleChange}
                      value={values.lastName || ''}
                    />
                    <StyledInput
                      type="text"
                      name="companyName"
                      label=" Company Name"
                      handleChange={this.handleChange}
                      value={values.companyName || ''}
                    />
                    <StyledInput
                      type="email"
                      name="email"
                      label="E-mail"
                      handleChange={this.handleChange}
                      value={values.email || ''}
                    />
                    <StyledButton
                      customStyle={{ width: '100%', marginBottom: '20px' }}
                      type="submit"
                      disabled={profileDisableButton}
                    >
                      Update Profile
                    </StyledButton>
                  </form>
                );
              }}
            </Formik>
          ) : null}
          <Formik
            validationSchema={profilePasswordSchema}
            initialValues={{ currentPassword: '', newPassword: '' }}
            onSubmit={values => {
              const { currentPassword, newPassword } = values;
              changeUserPassword({ currentPassword, newPassword });
            }}
          >
            {({ handleSubmit, values, touched }) => {
              return (
                <form onSubmit={handleSubmit} className={theme.signUpForm}>
                  <StyledInput
                    type="password"
                    name="currentPassword"
                    label="Current Password"
                    handleChange={this.handleChange}
                  />
                  <StyledInput
                    type="password"
                    name="newPassword"
                    label="New Password"
                    handleChange={this.handleChange}
                  />
                  <PasswordStrength password={values.newPassword} />
                  <StyledButton
                    customStyle={{ width: '100%' }}
                    type="submit"
                    disabled={passwordDisableButton}
                  >
                    Update Password
                  </StyledButton>
                </form>
              );
            }}
          </Formik>
        </section>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = store => {
  return {
    userData: store.profile.userData,
    showNotification: store.profile.showNotification,
    notificationVariant: store.profile.notificationVariant,
    notificationMessage: store.profile.notificationMessage,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserData,
      changeUserPassword,
      changeUserEmail,
      changeUserCompany,
      changeUserName,
      resetNotifications,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
