import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { history } from '../../../helpers/history';
import logo from '../../../assets/logo.png';
import arrow from '../../../assets/svgs/arrow.svg';
import { fetchUserData } from '../../../store/profile';
import { fetchFuses } from '../../../store/fuses';
import { getBreadcrumb } from './helpers';

import theme from './styles.module.scss';

class Header extends Component {
  state = {
    showMenu: false,
    breadcrumb: getBreadcrumb(this.props.location.pathname),
  };

  componentDidMount() {
    this.props.fetchUserData();
    if (/(\/fuses\/\d+\/edit)/.test(this.props.location.pathname)) {
      this.props.fetchFuses();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fuses !== prevProps.fuses) {
      if (/(\/fuses\/\d+\/edit)/.test(this.props.location.pathname)) {
        if (this.props.fuses.length) {
          const selectedFuse = this.props.fuses.find(
            fuse => fuse.id === +this.props.location.pathname.match(/\d+/g)[0]
          ).name;
          this.setState({ breadcrumb: { text: `Fuses/${selectedFuse}` } });
        }
      }
    }
  }

  showMenu = e => {
    e.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  logOut = () => {
    localStorage.removeItem('token');
    history.push('/login');
  };

  render() {
    const { showMenu, breadcrumb } = this.state;
    const { userData } = this.props;
    return (
      <section className={theme.headerContainer}>
        <div className={theme.logoBox}>
          <NavLink to="/dashboard">
            <img className={theme.logo} src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className={theme.navBox}>
          <p className={theme.activeTab}>
            {breadcrumb && (breadcrumb.breadCrumbs || breadcrumb.text)}
          </p>
          <div className={theme.menuWrapper}>
            <div className={theme.userMenuTriangle} />
            <div className={theme.userMenu} onClick={this.showMenu}>
              {userData && userData.firstName ? userData.firstName : null}
              <img src={arrow} alt="arrow-icon" />
            </div>
            {showMenu ? (
              <div
                className={theme.menuBox}
                ref={element => {
                  this.dropdownMenu = element;
                }}
              >
                <ul>
                  <li>
                    <NavLink to="/profile" style={{ padding: '8px 16px' }}>
                      Profile
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://webfuse.freshdesk.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ padding: '8px 16px' }}
                    >
                      Support
                    </a>
                  </li>
                  <li onClick={this.logOut}>Log out</li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = store => {
  return { userData: store.profile.userData, fuses: store.fuses.fuses };
};

const mapDispatchToProps = dispatch => ({
  fetchUserData: () => dispatch(fetchUserData()),
  fetchFuses: () => dispatch(fetchFuses()),
});

Header.propTypes = {
  userData: PropTypes.object.isRequired,
  fuses: PropTypes.array,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
