import Immutable from 'seamless-immutable';

import { baseRequest } from '../helpers/base-request';
import { isString } from '../helpers/isString';
import { history } from '../helpers/history';

const types = {
  CHANGE_USER_NAME_REQUEST: 'CHANGE_USER_NAME_REQUEST',
  CHANGE_USER_NAME_SUCCESS: 'CHANGE_USER_NAME_SUCCESS',
  CHANGE_USER_NAME_ERROR: 'CHANGE_USER_NAME_ERROR',

  CHANGE_USER_COMPANY_REQUEST: 'CHANGE_USER_COMPANY_REQUEST',
  CHANGE_USER_COMPANY_SUCCESS: 'CHANGE_USER_COMPANY_SUCCESS',
  CHANGE_USER_COMPANY_ERROR: 'CHANGE_USER_COMPANY_ERROR',

  CHANGE_USER_EMAIL_REQUEST: '  CHANGE_USER_EMAIL_REQUEST',
  CHANGE_USER_EMAIL_SUCCESS: 'CHANGE_USER_EMAIL_SUCCESS',
  CHANGE_USER_EMAIL_ERROR: 'CHANGE_USER_EMAIL_ERROR',

  CHANGE_USER_PASSWORD_REQUEST: 'CHANGE_USER_PASSWORD_REQUEST',
  CHANGE_USER_PASSWORD_SUCCESS: 'CHANGE_USER_PASSWORD_SUCCESS',
  CHANGE_USER_PASSWORD_ERROR: 'CHANGE_USER_PASSWORD_ERROR',

  FETCH_USER_DATA_REQUEST: 'FETCH_USER_DATA_REQUEST',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_USER_DATA_ERROR: 'FETCH_USER_DATA_ERROR',

  SET_PASSWORD_FOR_USER_REQUEST: 'SET_PASSWORD_FOR_USER_REQUEST',
  SET_PASSWORD_FOR_USER_SUCCESS: 'SET_PASSWORD_FOR_USER_SUCCESS',
  SET_PASSWORD_FOR_USER_ERROR: 'SET_PASSWORD_FOR_USER_ERROR',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

  SET_ORDER_INFORMATION_REQUEST: 'SET_ORDER_INFORMATION_REQUEST',
  SET_ORDER_INFORMATION_SUCCESS: 'SET_ORDER_INFORMATION_SUCCESS',
  SET_ORDER_INFORMATION_ERROR: 'SET_ORDER_INFORMATION_ERROR',

  FETCH_ACCOUNT_SETTINGS_REQUEST: 'FETCH_ACCOUNT_SETTINGS_REQUEST',
  FETCH_ACCOUNT_SETTINGS_SUCCESS: 'FETCH_ACCOUNT_SETTINGS_SUCCESS',
  FETCH_ACCOUNT_SETTINGS_ERROR: 'FETCH_ACCOUNT_SETTINGS_ERROR',

  SET_ACCOUNT_SETTINGS_REQUEST: 'SET_ACCOUNT_SETTINGS_REQUEST',
  SET_ACCOUNT_SETTINGS_SUCCESS: 'SET_ACCOUNT_SETTINGS_SUCCESS',
  SET_ACCOUNT_SETTINGS_ERROR: 'SET_ACCOUNT_SETTINGS_ERROR',

  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
};

export const resetNotifications = () => ({
  type: types.RESET_NOTIFICATIONS,
});
const fetchUserDataRequest = () => ({
  type: types.FETCH_USER_DATA_REQUEST,
});
const changeUserCompanyRequest = () => ({
  type: types.CHANGE_USER_COMPANY_REQUEST,
});
const changeUserNameRequest = () => ({
  type: types.CHANGE_USER_NAME_REQUEST,
});
const changeUserPasswordRequest = () => ({
  type: types.CHANGE_USER_PASSWORD_REQUEST,
});
const changeUserEmailRequest = () => ({
  type: types.CHANGE_USER_EMAIL_REQUEST,
});
const setPasswordForUserRequest = () => ({
  type: types.SET_PASSWORD_FOR_USER_REQUEST,
});
const setOrderInformationRequest = () => ({
  type: types.SET_ORDER_INFORMATION_REQUEST,
});
const setAccountSettingsRequest = () => ({
  type: types.SET_ACCOUNT_SETTINGS_REQUEST,
});
const fetchAccountSettingsRequest = () => ({
  type: types.FETCH_ACCOUNT_SETTINGS_REQUEST,
});

export const fetchUserData = () => {
  const success = response => ({
    type: types.FETCH_USER_DATA_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.FETCH_USER_DATA_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchUserDataRequest());
    return baseRequest('GET', '/api/Users/me')
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const changeUserName = data => {
  const success = response => {
    return {
      type: types.CHANGE_USER_NAME_SUCCESS,
      payload: response,
    };
  };

  const failure = error => {
    return {
      type: types.CHANGE_USER_NAME_ERROR,
      payload: isString(error),
    };
  };

  return dispatch => {
    dispatch(changeUserNameRequest());
    return baseRequest('PUT', '/api/Account/user', data)
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const changeUserCompany = data => {
  const success = response => {
    return {
      type: types.CHANGE_USER_COMPANY_SUCCESS,
    };
  };

  const failure = error => {
    return {
      type: types.CHANGE_USER_COMPANY_ERROR,
      payload: isString(error),
    };
  };

  return dispatch => {
    dispatch(changeUserCompanyRequest());
    return baseRequest('PUT', '/api/Account/company', data)
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const changeUserEmail = data => {
  const success = response => {
    return {
      type: types.CHANGE_USER_EMAIL_SUCCESS,
    };
  };

  const failure = error => {
    return {
      type: types.CHANGE_USER_EMAIL_ERROR,
      payload: isString(error),
    };
  };

  return dispatch => {
    dispatch(changeUserEmailRequest());
    return baseRequest('POST', '/api/Account/email/change', data)
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const forgotPassword = email => {
  const success = data => {
    return {
      type: types.FORGOT_PASSWORD_SUCCESS,
    };
  };
  const failure = error => {
    return {
      type: types.FORGOT_PASSWORD_ERROR,
      payload: isString(error),
    };
  };

  return dispatch =>
    baseRequest('POST', '/api/Account/password/forgot', email)
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
};

export const changeUserPassword = data => {
  const success = response => {
    return {
      type: types.CHANGE_USER_PASSWORD_SUCCESS,
    };
  };
  const failure = error => {
    return {
      type: types.CHANGE_USER_PASSWORD_ERROR,
      payload: isString(error),
    };
  };

  return dispatch => {
    dispatch(changeUserPasswordRequest());
    return baseRequest('POST', '/api/Account/password/change', data)
      .then(response => dispatch(success(response)))
      .catch(error => {
        return dispatch(failure(error));
      });
  };
};

export const setPasswordForInvitedUser = ({
  userId,
  passwordToken,
  password,
}) => {
  const success = () => {
    setTimeout(() => {
      history.push('/');
    }, 3000);
    return {
      type: types.SET_PASSWORD_FOR_USER_SUCCESS,
    };
  };

  const failure = error => ({
    type: types.SET_PASSWORD_FOR_USER_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(setPasswordForUserRequest());
    return baseRequest('POST', `/api/Account/password/set/${userId}`, {
      password: password,
      token: passwordToken,
    })
      .then(() => dispatch(success()))
      .catch(error => dispatch(failure(error)));
  };
};

export const setOrderInformation = orderInfo => {
  const success = response => {
    return {
      type: types.SET_ORDER_INFORMATION_SUCCESS,
    };
  };

  const failure = error => ({
    type: types.SET_ORDER_INFORMATION_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(setOrderInformationRequest());
    return baseRequest('POST', `/api/Orders`, orderInfo)
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchAccountSettings = () => {
  const success = response => {
    return {
      type: types.FETCH_ACCOUNT_SETTINGS_SUCCESS,
      payload: response.data,
    };
  };

  const failure = error => ({
    type: types.FETCH_ACCOUNT_SETTINGS_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchAccountSettingsRequest());
    return baseRequest('GET', `/api/Account/settings`)
      .then(response => {
        return dispatch(success(response));
      })
      .catch(error => dispatch(failure(error)));
  };
};

export const setAccountSettings = accountInfo => {
  const success = response => {
    return {
      type: types.SET_ACCOUNT_SETTINGS_SUCCESS,
    };
  };

  const failure = error => ({
    type: types.SET_ACCOUNT_SETTINGS_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(setAccountSettingsRequest());
    return baseRequest('PUT', `/api/Account/settings`, accountInfo)
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

const initialState = Immutable({
  loading: false,
  showNotification: false,
  notificationVariant: '',
  notificationMessage: '',
  errorMessage: '',
  userData: {},
  accountData: {},
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_USER_NAME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CHANGE_USER_NAME_SUCCESS:
      return {
        ...state,
        userData: action.payload.data,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Profile updated',
      };

    case types.CHANGE_USER_NAME_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Password updated',
      };

    case types.CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload.data,
        loading: false,
      };

    case types.FETCH_USER_DATA_ERROR:
      return {
        ...state,
        userData: action.payload.data,
        loading: false,
        showNotification: false,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage:
          'We have successfully received your request. To reset your password, please check your e-mail.',
      };

    case types.SET_PASSWORD_FOR_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_PASSWORD_FOR_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Password was successfully set',
      };

    case types.SET_PASSWORD_FOR_USER_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.SET_ORDER_INFORMATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_ORDER_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Information successfully sent',
      };

    case types.SET_ORDER_INFORMATION_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_ACCOUNT_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        accountData: action.payload,
      };

    case types.FETCH_ACCOUNT_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.SET_ACCOUNT_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SET_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Account information was sent',
      };

    case types.SET_ACCOUNT_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.RESET_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        showNotification: false,
        notificationVariant: '',
        notificationMessage: '',
      };

    default:
      return state;
  }
}
