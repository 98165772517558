import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, SnackbarContent, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import CheckMark from '../../assets/svgs/snackbar-success.svg';
import ErrorIcon from '../../assets/svgs/warning.svg';
import CloseIcon from '../../assets/svgs/close.svg';

import * as theme from './styles.module.scss';

const icons = {
  success: CheckMark,
  error: ErrorIcon,
  warning: ErrorIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: '#2fb9ad',
  },
  error: {
    backgroundColor: '#d3583b',
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: '#c99d3a',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
}));

const CustomizeSnackbarContent = ({ className, message, close, variant }) => {
  const classes = useStyles1();
  return (
    <SnackbarContent
      classes={{
        message: theme.contentWrapper,
      }}
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      onClose={close}
      message={
        <section>
          <div className={theme.snackbarMessageBox}>
            <img
              className={theme.snackbarIcon}
              src={icons[variant]}
              alt={variant}
            />
            <span className={theme.messageText}>{message}</span>
          </div>
          <div>
            <img
              className={theme.closeIcon}
              src={CloseIcon}
              alt="close"
              onClick={close}
            />
          </div>
        </section>
      }
    />
  );
};

CustomizeSnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  close: PropTypes.func,
  variant: PropTypes.string,
};

export const SnackBar = ({ message, open, close, variant }) => (
  <>
    <Snackbar
      classes={{
        root: theme.contentWrapper,
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={10000}
      onClose={close}
    >
      <CustomizeSnackbarContent
        close={close}
        message={message}
        variant={variant}
      />
    </Snackbar>
  </>
);

SnackBar.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  close: PropTypes.func,
  variant: PropTypes.string,
};
