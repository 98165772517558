import React from 'react';
import { FontFamilyList } from './FontList';

export const hasMark = ({ value, markType }) =>
  value.marks.some(mark => mark.type === markType);

export const getMark = ({ value, markType }) =>
  value.marks.filter(mark => mark.type === markType).first();

const createMark = ({ markType, data }) => ({
  type: markType,
  data:
    markType === 'font-family' ? { fontFamilyIndex: data } : { fontSize: data },
});

const reapplyMark = ({ editor, value, data, markType }) =>
  editor
    .removeMark(getMark({ value, markType }))
    .addMark(createMark({ data, markType }));

const applyMark = ({ editor, data, markType }) =>
  editor.addMark(createMark({ markType: markType, data: data }));

export const onClickChangeFont = ({ editor, value, event, markType }) => {
  event && event.preventDefault();
  const data = event && event.target.value;

  if (hasMark({ value, markType })) {
    return reapplyMark({
      editor: editor,
      value: value,
      data: data,
      markType: markType,
    }).focus();
  } else {
    return applyMark({
      editor: editor,
      data: data,
      markType: markType,
    }).focus();
  }
};

export const ConditionNode = ({ attributes, children, node }) => {
  return (
    <wf-condition class="condition" {...attributes}>
      {children}
    </wf-condition>
  );
};

export const LoopNode = ({ attributes, children, node }) => (
  <wf-loop id={node.data.get('id')} class="loop" {...attributes}>
    {children}
  </wf-loop>
);

export const LoopItemNode = ({ attributes, children, node }) => (
  <wf-loop-item
    id={node.data.get('id')}
    class="loop-item"
    data-name={node.data.get('name')}
    {...attributes}
  >
    {children}
  </wf-loop-item>
);

export const LoopTableNode = ({ attributes, children, node }) => (
  <wf-loop-table
    id={node.data.get('id')}
    class="loop loop-table"
    {...attributes}
  >
    {children}
  </wf-loop-table>
);

export const LoopTableHeadersNode = ({ attributes, children, node }) => (
  <wf-loop-table-headers
    id={node.data.get('id')}
    class="loop loop-table"
    {...attributes}
  >
    {children}
  </wf-loop-table-headers>
);

export const LoopTableNestedNode = ({ attributes, children, node }) => (
  <wf-loop-table-nested
    id={node.data.get('id')}
    class="loop loop-table"
    {...attributes}
  >
    {children}
  </wf-loop-table-nested>
);

export const LoopTableItemNode = ({ attributes, children, node }) => (
  <wf-loop-table-item
    id={node.data.get('id')}
    class="loop-item loop-table-item"
    data-name={node.data.get('name')}
    {...attributes}
  >
    {children}
  </wf-loop-table-item>
);

export const ReferenceNode = ({ node, attributes }) => (
  <wf-reference
    data-format={node.data.get('format')}
    id={node.data.get('id')}
    class="reference"
    {...attributes}
  >
    {node.data.get('name')}
  </wf-reference>
);

const FormNode = ({ node, attributes }) => (
  <wf-form
    id={node.data.get('id')}
    class="form"
    data-name={node.data.get('name')}
    {...attributes}
  >
    {node.data.get('name')}
  </wf-form>
);

const ReferenceImageNode = ({ node, attributes }) => (
  <wf-reference>
    {node.data.get('name')}
    <img
      src={node.data.get('id')}
      alt="img"
      width={node.data.get('width')}
      height={node.data.get('height')}
      class="img-reference"
      {...attributes}
    />
  </wf-reference>
);

const FieldNode = ({ node, attributes }) => {
  return (
    <wf-field class="field" {...attributes} id={node.data.get('id')}>
      {node.data.get('name')}
    </wf-field>
  );
};
const FieldTableNode = ({ node, attributes }) => {
  return (
    <wf-field-table class="field" {...attributes} id={node.data.get('id')}>
      {node.data.get('name')}
    </wf-field-table>
  );
};

export const renderBlock = (props, editor, next) => {
  const { attributes, children, node } = props;

  switch (node.type) {
    case 'p':
      return <p {...attributes}>{children}</p>;
    case 'h1':
      return <h1 {...attributes}>{children}</h1>;
    case 'h2':
      return <h2 {...attributes}>{children}</h2>;
    case 'blockquote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'ul':
      return <ul {...attributes}>{children}</ul>;
    case 'ol':
      return <ol {...attributes}>{children}</ol>;
    case 'li':
      return <li {...attributes}>{children}</li>;
    case 'wf-loop':
      return <LoopNode {...props} />;
    case 'wf-loop-item':
      return <LoopItemNode {...props} />;
    case 'wf-loop-table':
      return <LoopTableNode {...props} />;
    case 'wf-loop-table-headers':
      return <LoopTableHeadersNode {...props} />;
    case 'wf-loop-table-nested':
      return <LoopTableNestedNode {...props} />;
    case 'wf-loop-table-item':
      return <LoopTableItemNode {...props} />;
    case 'wf-form':
      return <FormNode {...props} />;
    case 'wf-condition':
      return <ConditionNode {...props} />;
    case 'div':
      const className = node.data.get('className');
      return (
        <div {...attributes} className={className}>
          {children}
        </div>
      );
    case 'img': {
      const src = node.data.get('src');
      const width = node.data.get('width');
      const height = node.data.get('height');
      return (
        <img
          {...attributes}
          src={src}
          alt={src}
          width={width}
          height={height}
          className="image"
        />
      );
    }
    default:
      return next();
  }
};

export const renderInline = (props, editor, next) => {
  const { attributes, children, node } = props;

  switch (node.type) {
    case 'wf-reference':
      return <ReferenceNode {...props} />;
    case 'wf-image-reference':
      return <ReferenceImageNode {...props} />;
    case 'wf-field':
      return <FieldNode {...props} />;
    case 'wf-field-table':
      return <FieldTableNode {...props} />;
    case 'a': {
      const { data } = node;
      const href = data.get('href');
      return (
        <a {...attributes} href={href}>
          {children}
        </a>
      );
    }

    default:
      return next();
  }
};

export const renderMark = (props, editor, next) => {
  const { children, mark, attributes } = props;

  switch (mark.type) {
    case 'b':
      return <b {...attributes}>{children}</b>;
    case 'i':
      return <i {...attributes}>{children}</i>;
    case 'u':
      return <u {...attributes}>{children}</u>;
    case 'code':
      return <code {...attributes}>{children}</code>;
    case 'font-family':
      return (
        <span
          {...attributes}
          style={{
            fontFamily: FontFamilyList[mark.data.get('fontFamilyIndex')].name,
          }}
        >
          {children}
        </span>
      );
    case 'font-size':
      return (
        <span
          style={{
            fontSize: parseInt(mark.data.get('fontSize'), 10),
          }}
        >
          {children}
        </span>
      );
    default:
      return next();
  }
};
