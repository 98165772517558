import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { authUser, resetNotifications } from '../../store/authorization';

import { history } from '../../helpers/history';
import { Layout } from '../../components/Layout';
import {
  StyledButton,
  Logo,
  StyledInput,
  StyledCheckbox,
} from '../../components/common';
import {
  validateEmail,
  validateNameforSpaces,
} from '../../helpers/validations';
import PasswordStrength from '../../components/PasswordStrength/PasswordStrength';

import theme from './styles.module.scss';

const signUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Please enter your first name')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Please enter your last name')
    .required('Required'),
  company: Yup.string()
    .min(2, 'Please enter your company name')
    .required('Required'),
  email: Yup.string()
    .email()
    .required('Required')
    .test(
      'validateEmail',
      'E-mail is not allowed, please use a registered company domain',
      value => validateEmail(value)
    ),
  password: Yup.string()
    .min(6, 'Please use a minimum of 6 characters')
    .max(20, 'Please use a maximum of 20 characters')
    .test(
      'Warning, password can`t contain spaces',
      'Warning, password can`t contain spaces',
      value => validateNameforSpaces(value)
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .min(6, 'Please use a minimum of 6 characters')
    .max(20, 'Please use a maximum of 20 characters')
    .test(
      'Warning, password can`t contain spaces',
      'Warning, password can`t contain spaces',
      value => validateNameforSpaces(value)
    )
    .oneOf([Yup.ref('password')], 'Passwords do not match')
    .required('Required'),
  checkbox: Yup.bool().oneOf([true], 'This field is required'),
});

class SignUp extends Component {
  render() {
    const { authUser } = this.props;
    return (
      <Layout>
        <section className={theme.signupContainer}>
          <Logo />
          <p>Create your account to get started with Webfuse.</p>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              company: '',
              email: '',
              password: '',
              confirmPassword: '',
              checkbox: false,
            }}
            onSubmit={values => {
              const { firstName, lastName, company, email, password } = values;
              authUser({ firstName, lastName, company, email, password });
              history.push('/login?verifyEmail=false');
            }}
            validationSchema={signUpSchema}
          >
            {({ values, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <StyledInput
                    type="text"
                    name="firstName"
                    label="First Name"
                  />
                  <StyledInput type="text" name="lastName" label="Last Name" />
                  <StyledInput
                    type="text"
                    name="company"
                    label="Company Name"
                  />
                  <StyledInput type="email" name="email" label="E-mail" />
                  <StyledInput
                    type="password"
                    name="password"
                    label="Password"
                  />
                  <PasswordStrength password={values.password} />
                  <StyledInput
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                  />
                  <StyledCheckbox
                    name="checkbox"
                    value={values.checkbox}
                    text={
                      <span>
                        I agree to the
                        <a href="https://www.webfuse.io/termsandconditions">
                          Terms & Conditions
                        </a>{' '}
                      </span>
                    }
                  />
                  <StyledButton customStyle={{ width: '100%' }} type="submit">
                    Create my account
                  </StyledButton>
                </form>
              );
            }}
          </Formik>
          <p>
            Already have an account? <Link to="/login">Sign in.</Link>
          </p>
        </section>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ authUser, resetNotifications }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(SignUp);
