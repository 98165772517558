import React, { Component, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';

import SnackBar from '../../Snackbar';
import Modal from '../../Modal';
import AutoComplete from '../AutoComplete';
import StyledButton from '../../common/StyledButton';
import { StyledInput } from '../../../components/common/';
import {
  resetSnippetData,
  saveSnippetOwner,
  fetchFuses,
} from '../../../store/fuses';
import { fetchUsers } from '../../../store/users';

import theme from './styles.module.scss';

class SnippetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
    this.javaScriptSnippetRef = createRef();
    this.htmlSnippetRef = createRef();
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  getAreaValue = ({ target }) => {
    if (target.name === 'htmlSnippet') {
      navigator.clipboard.writeText(this.htmlSnippetRef.current.value);
    } else {
      navigator.clipboard.writeText(this.javaScriptSnippetRef.current.value);
    }
    this.setState({
      copied: true,
    });
  };

  closeNotification = () => {
    this.setState({
      copied: false,
    });
  };

  handleSubmitSnippet = async (fuseId, userId) => {
    const {
      saveSnippetOwner,
      resetSnippetData,
      closeSnippetModal,
      fetchFuses,
    } = this.props;
    await saveSnippetOwner(fuseId, { userId });
    await resetSnippetData();
    await closeSnippetModal();
    await fetchFuses();
  };

  render() {
    const {
      showSnippetModal,
      closeSnippetModal,
      fuseId = '',
      users,
      snippet,
      resetSnippetData,
      fuses,
    } = this.props;
    const { copied } = this.state;

    return (
      <>
        <Modal title="Snippet modal" isOpen={showSnippetModal}>
          <div className="add-reference">
            <header>
              <h1>Fuse settings</h1>
              <p>
                Edit your Fuse`s basic settings, or copy the references for your
                website.
              </p>
            </header>
            <Formik
              initialValues={{
                connectorId:
                  (fuses.find(fuse => fuse.id === fuseId) &&
                    fuses.find(fuse => fuse.id === fuseId).connectorName) ||
                  '',
                userId:
                  (fuses.find(fuse => fuse.id === fuseId) &&
                    fuses.find(fuse => fuse.id === fuseId).userId) ||
                  '',
                htmlSnippet: snippet.htmlSnippet || '',
                javaScriptSnippet: snippet.javaScriptSnippet || '',
              }}
              enableReinitialize
              onSubmit={async values =>
                await this.handleSubmitSnippet(fuseId, values.userId)
              }
            >
              {({ setFieldValue, values, handleChange }) => {
                return (
                  <Form>
                    <StyledInput
                      type="text"
                      name="connectorId"
                      label="Connector"
                      value={values.connectorId}
                      handleChange={this.handleChange}
                      disabled
                    />

                    <div className={theme.textAreaWrapper}>
                      <label className={theme.textAreaLabel}>
                        Fuse reference
                      </label>
                      <input
                        ref={this.htmlSnippetRef}
                        name="htmlSnippet"
                        cols="50"
                        onChange={handleChange}
                        value={values.htmlSnippet}
                        readOnly
                      />
                      <button
                        type="button"
                        name="htmlSnippet"
                        onClick={this.getAreaValue}
                      >
                        copy
                      </button>
                    </div>
                    <div className={theme.textAreaWrapper}>
                      <label className={theme.textAreaLabel}>
                        JavaScript snippet
                      </label>
                      <textarea
                        ref={this.javaScriptSnippetRef}
                        name="javaScriptSnippet"
                        rows="10"
                        cols="50"
                        onChange={handleChange}
                        value={values.javaScriptSnippet}
                        readOnly
                      />
                      <button
                        type="button"
                        name="javaScriptSnippet"
                        onClick={this.getAreaValue}
                      >
                        copy
                      </button>
                    </div>
                    <AutoComplete
                      label="Connector"
                      name="userId"
                      suggestions={users.map(({ firstName, lastName, id }) => ({
                        value: id,
                        label: `${firstName} ${lastName}`,
                      }))}
                      setFieldFormik={setFieldValue}
                      valueFormik={values.userId}
                    />
                    <footer>
                      <StyledButton
                        action={() => {
                          resetSnippetData();
                          closeSnippetModal();
                        }}
                        width="auto"
                        variant="cancel"
                      >
                        Cancel
                      </StyledButton>
                      <StyledButton
                        type="submit"
                        width="auto"
                        disabled={Object.values(values).every(
                          value => value === ''
                        )}
                      >
                        Finish
                      </StyledButton>
                    </footer>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal>
        <SnackBar
          open={copied}
          variant="success"
          message="copied"
          close={this.closeNotification}
        />
      </>
    );
  }
}

const mapStateToProps = store => {
  return {
    connectors: store.connectors.connectors,
    users: store.users.users.length
      ? store.users.users
      : [store.profile.userData],
    fuses: store.fuses.fuses,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetSnippetData,
      saveSnippetOwner,
      fetchUsers,
      fetchFuses,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnippetModal);
