import React from 'react';
import {
  TableBody,
  Paper,
  Table,
  Checkbox,
  TableHead,
  TableSortLabel,
} from '@material-ui/core';
import {
  StyledTableCell,
  StyledTablePagination,
  StyledTableRow,
  StyledToolbar,
  useStyles,
} from './style';

export const TableComponent = ({
  headNav,
  headRows,
  children,
  rowsPerPage,
  selectedLength,
  totalLength,
  handleSelectAllClick,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  handleSort,
  columnToSort,
  sortDirection,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <StyledToolbar>{headNav}</StyledToolbar>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <StyledTableRow hover>
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    color="default"
                    onClick={handleSelectAllClick}
                    checked={selectedLength === totalLength}
                    indeterminate={
                      selectedLength > 0 && selectedLength < totalLength
                    }
                  />
                </StyledTableCell>
                {headRows &&
                  headRows.map(row => (
                    <StyledTableCell key={row.id} align="left">
                      <TableSortLabel
                        active={columnToSort === row.id}
                        direction={sortDirection || 'asc'}
                        onClick={() => handleSort(row.id)}
                        className={classes.sortLabel}
                      >
                        {row.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </div>
        <StyledTablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={rowsPerPage}
          count={totalLength}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
        />
      </Paper>
    </div>
  );
};
