export const inputsData = [
  {
    name: 'firstName',
    label: 'First Name',
    required: true,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    required: true,
  },
  {
    name: 'email',
    label: 'E-mail ',
    required: true,
  },
  {
    name: 'company',
    label: 'Company',
    required: true,
  },
  {
    name: 'vat',
    label: 'VAT',
    required: true,
  },
  {
    name: 'street',
    label: 'Street',
    required: true,
  },
  {
    name: 'number',
    label: 'Number',
    required: true,
  },
  {
    name: 'box',
    label: 'Box',
    required: false,
  },
  {
    name: 'postalCode',
    label: 'Postal Code',
    required: true,
  },
  {
    name: 'city',
    label: 'City',
    required: true,
  },
  {
    name: 'state',
    label: 'State/Region',
    required: false,
  },
  {
    name: 'country',
    label: 'Country',
    required: true,
  },
  {
    name: 'subject',
    label: 'Subject',
    required: true,
  },
];

export const titleText = `Thank you for your interest in Webfuse. We certainly hope your user
          experience has been satisfactory up until now. If you would like to
          keep on using Webfuse, please use the order form below.
          Upon ordering you will receive a confirmation message and invoice will
          be sent. Activation of the subscription will be done shortly. Any
          other questions, please leave a message either on our website
          (www.webfuse.io) or through the form below. Thanks for your faith and
          enjoy Webfuse!`;

export const editions = [
  { value: 'Professional', id: 1 },
  { value: 'Enterprise', id: 2 },
];

export const subscriptions = [
  { value: 'Yearly', id: 1 },
  { value: 'Other', id: 2 },
];
