import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Editor, findNode, getEventTransfer } from 'slate-react';
import { Value, Block, Inline, Text } from 'slate';
import isUrl from 'is-url';

import Html from 'slate-html-serializer';
import { merge, clone, pickBy, isObject } from 'lodash';

import ReferenceModal from '../ReferenceModal';
import FusePreviewModal from '../FusePreviewModal';
import FormModal from '../FormModal';
import LoopModal from '../LoopModal';
import FilterModal from '../FilterModal';
import LoopSettings from '../LoopSettings/';
import { StyledButton } from '../../common';
import SettingsSidebar from '../../SettingsSidebar';
import ReferenceSettings from '../ReferenceSettings';
import FormSettings from '../FormSettings/';
import { CustomToolbar } from './helpers/CustomToolbar';
import Snackbar from '../../Snackbar/';

import { plugins } from './helpers/plugins';
import SnippetModal from '../SnippetModal/';
import { rules, rawRules } from './helpers/rules';

import { setSettingsToSpecificObject } from './helpers/setSettingsToSpecificObject';
import {
  removeEmpty,
  findNestedLoop,
  getLastField,
  setFieldToLastChild,
  findLastLoopToSet,
  insertImage,
  isImage,
  findField,
} from './helpers/helpers';

import {
  renderBlock,
  renderInline,
  renderMark,
} from './helpers/RenderComponents';

import {
  saveTemplateData,
  storeTemplateData,
  fetchEditorDelta,
  fetchFuses,
  addSelectedConnectorId,
  addSelectedFuseId,
  getTemplatePreview,
  clearTemplateData,
  resetNotifications,
  fetchSnippet,
  throwErrorInLoop,
} from '../../../store/fuses';
import { fetchParentLoopData } from '../../../store/loops';
import {
  fetchEntityData,
  fetchCurrentLabelInfo,
} from '../../../store/references';

import './style.scss';

const schema = {
  blocks: {
    img: {
      isVoid: true,
    },
    'wf-form': {
      isVoid: true,
    },
  },
  inlines: {
    'wf-loop-item': {
      isVoid: true,
    },
    'wf-field': {
      isVoid: true,
    },
    'wf-reference': {
      isVoid: true,
    },
  },
};

const html = new Html({ rules });
const html2 = new Html({ rules: rawRules });

class Slate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: html.deserialize(''),
      rawHtml: '',
      showRaw: false,
      isOpened: false,
      loopModalOpened: false,
      filterModalOpened: false,
      previewModalOpened: false,
      formModalOpened: false,
      values: null,
      loopValues: null,
      templateData: {
        template: '',
        delta: '',
        references: [],
        loops: [],
        forms: [],
        conditions: [],
      },
      sidebarOpen: false,
      openLoopSidebar: false,
      openReferenceSidebar: false,
      openFormSidebar: false,
      openFieldSidebar: false,
      openSnippetModal: false,
      selectedReference: {},
      selectedReferenceNode: null,
      selectedLoop: {},
      selectedForm: {},
      selectedLoopField: {},
      editorWasMounted: false,
      selectedValue: '',
      openEditingReferenceModal: false,
      openEditingLoopModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchFuses();

    this.refs.editor
      .querySelector('.slate-editor')
      .addEventListener('click', this.handleRefClick);

    this.refs.editor
      .querySelector('.slate-editor')
      .addEventListener('click', this.handleLoopClick);

    this.refs.editor
      .querySelector('.slate-editor')
      .addEventListener('click', this.handleFieldClick);

    this.refs.editor
      .querySelector('.slate-editor')
      .addEventListener('click', this.handleFormClick);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.selectedFuseId && nextProps.fuses.length) {
      const selectedFuse = nextProps.fuses.find(
        fuse => fuse.id === +window.location.pathname.match(/\d+/g)[0]
      );
      nextProps.addSelectedConnectorId(selectedFuse.connectorId);
      nextProps.addSelectedFuseId(selectedFuse.id);
      nextProps.fetchEntityData(selectedFuse.connectorId);
      nextProps.fetchParentLoopData(selectedFuse.connectorId);
    }
    if (nextProps.selectedFuseId && !this.state.editorWasMounted) {
      await this.props.fetchEditorDelta(nextProps.selectedFuseId);
      this.setState({ editorWasMounted: true });
      if (nextProps.editorDelta) {
        this.setState(() => ({
          value: Value.fromJSON(JSON.parse(nextProps.editorDelta)),
          templateData: nextProps.referencesConfig,
        }));
      }
    }
  }

  componentWillUnmount() {
    this.refs.editor
      .querySelector('.slate-editor')
      .removeEventListener('click', this.handleRefClick);

    this.refs.editor
      .querySelector('.slate-editor')
      .removeEventListener('click', this.handleLoopClick);

    this.refs.editor
      .querySelector('.slate-editor')
      .removeEventListener('click', this.handleFormClick);
    this.props.clearTemplateData();
  }

  ref = editor => {
    this.editor = editor;
  };

  isLoop = () =>
    this.state.value.blocks.some(
      node => node.type === 'wf-loop-item' || node.type === 'wf-loop-table-item'
    );

  handleFieldClick = e => {
    const fieldNode = e.target.closest('.field');
    if (fieldNode) {
      const cloneTemplateData = { ...this.state.templateData };
      const currentField = findField(cloneTemplateData.loops, fieldNode.id);
      this.setState(
        {
          openFieldSidebar: true,
          openLoopSidebar: false,
          openReferenceSidebar: false,
          openFormSidebar: false,
          selectedLoopField: currentField,
        },
        () => this.handleOpenSideBar()
      );
    }
  };

  handleFormClick = async e => {
    const { referencesConfig } = this.props;
    const { templateData } = this.state;
    const formNode = e.target.closest('.form');
    let templatePath;
    if (templateData.forms.length) {
      templatePath = templateData;
    } else {
      templatePath = referencesConfig;
    }

    if (formNode) {
      await this.setState(
        () => ({
          selectedSlateNode: findNode(e.target, this.editor),
          selectedForm: templatePath.forms.find(
            item => item.id === formNode.id
          ),
          openLoopSidebar: false,
          openReferenceSidebar: false,
          openFieldSidebar: false,
          openFormSidebar: true,
        }),
        () => this.handleOpenSideBar()
      );
    }
  };

  handleRefClick = e => {
    const { referencesConfig, connectorId, fetchCurrentLabelInfo } = this.props;
    const { templateData } = this.state;
    const referenceNode = e.target.closest('.reference');
    let templatePath;
    if (templateData.references.length) {
      templatePath = templateData;
    } else {
      templatePath = referencesConfig;
    }
    if (referenceNode) {
      this.handleOpenSideBar();

      this.setState(
        () => {
          return {
            selectedReference: templatePath.references.find(
              item => item.id === referenceNode.id
            ),
            openLoopSidebar: false,
            openFormSidebar: false,
            openFieldSidebar: false,
            openReferenceSidebar: true,
            selectedReferenceNode: referenceNode,
          };
        },
        () =>
          fetchCurrentLabelInfo(
            connectorId,
            this.state.selectedReference.entity,
            this.state.selectedReference.recordId
          )
      );
    }
  };

  handleLoopClick = e => {
    const { referencesConfig } = this.props;
    const { templateData } = this.state;
    const loopNode = e.target.closest('.loop');
    let templatePath;
    if (templateData.loops.length) {
      templatePath = templateData;
    } else {
      templatePath = referencesConfig;
    }

    if (loopNode) {
      this.setState(() => {
        const cloneSelectedLoop = findNestedLoop(templatePath.loops, loopNode);

        const field =
          getLastField(cloneSelectedLoop).length === 1 &&
          getLastField(cloneSelectedLoop)[0];
        if (field) {
          cloneSelectedLoop.settings.grouping.field = field.field;
          cloneSelectedLoop.settings.sorting.field = field.field;
        }
        return {
          selectedLoop: cloneSelectedLoop,
          openFormSidebar: false,
          openReferenceSidebar: false,
          openFieldSidebar: false,
          openLoopSidebar: true,
        };
      });
      this.handleOpenSideBar();
    }
  };

  onChange = ({ value }) => {
    if (value.document !== this.state.value.document) {
      const string = html.serialize(value);
      this.setState(prevState => ({
        templateData: {
          ...prevState.templateData,
          template: string,
          delta: JSON.stringify(value),
        },
      }));
    }
    this.setState({ value });
    if (value.fragment.text) {
      this.setState({ selectedValue: value.fragment.text });
    }
  };

  handleOpenReferenceModal = () => {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened,
    }));
  };

  handleOpenPreviewModal = async () => {
    await this.cleanUpTemplateData();
    this.setState(prevState => ({
      previewModalOpened: !prevState.previewModalOpened,
    }));
    this.props.getTemplatePreview(
      this.state.templateData,
      this.props.selectedFuseId
    );
  };

  handleClosePreviewModal = () => {
    this.setState(prevState => ({
      previewModalOpened: !prevState.previewModalOpened,
    }));
  };

  handleOpenFormModal = () => {
    this.setState(prevState => ({
      formModalOpened: !prevState.formModalOpened,
    }));
    const { fetchEntityData, selectedConnectorId } = this.props;
    fetchEntityData(selectedConnectorId);
  };

  handleOpenSideBar = () => {
    this.setState({ sidebarOpen: true });
  };

  handleCloseSidebar = () => {
    this.setState({ sidebarOpen: false });
  };

  setLoopProperty = treePath =>
    this.props.loops_data.find(item => item.label === treePath).property;

  setLoopsRecordId = record =>
    this.props.loop_records.find(item => item.label === record).id;

  handleReferenceModalClick = () => {
    const {
      fetchEntityData,
      selectedConnectorId,
      throwErrorInLoop,
    } = this.props;
    this.isLoop() &&
      throwErrorInLoop('You cannot insert a reference inside the loop');
    if (!this.isLoop()) {
      this.setState({ isOpened: true });
      fetchEntityData(selectedConnectorId);
    }
  };

  openLoopModal = () => {
    this.setState(prevState => ({
      loopModalOpened: !prevState.loopModalOpened,
    }));
  };

  toggleFilterModal = () => {
    this.setState(prevState => ({
      filterModalOpened: !prevState.filterModalOpened,
    }));
  };

  setReferenceRecordId = record => {
    if (
      this.state.openEditingReferenceModal &&
      this.props.entity_label_for_edit.length
    ) {
      return this.props.entity_label_for_edit.find(
        item => item.label === record
      ).id;
    } else
      return this.props.entity_label.find(item => item.label === record).id;
  };

  handleSubmitReferenceModal = async values => {
    this.setState({ values });
    const { templateData, selectedReference } = this.state;
    const { referencesConfig } = this.props;
    if (this.state.openEditingReferenceModal) {
      let templatePath;
      if (templateData.references.length) {
        templatePath = templateData;
      } else {
        templatePath = referencesConfig;
      }
      const cloneTemplateData = clone(templatePath, true);
      const updatedValues = {
        entity: values.entity,
        recordId: this.setReferenceRecordId(values.record),
        field: values.field,
      };
      const updatedReference = {
        ...selectedReference,
        ...updatedValues,
      };
      const indexOfCurrentRef = cloneTemplateData.references.findIndex(
        reference => reference.id === updatedReference.id
      );
      if (indexOfCurrentRef !== -1) {
        cloneTemplateData.references[indexOfCurrentRef] = updatedReference;
      }

      await this.setState({
        templateData: cloneTemplateData,
        openEditingReferenceModal: false,
        selectedReference: updatedReference,
      });

      return;
    }
    const reference = {
      id: `reference-${uuidv4()}`,
      entity: values.entity,
      recordId: this.setReferenceRecordId(values.record),
      field: values.field,
      settings: {
        text: {},
      },
    };
    await this.setState(prevState => ({
      templateData: {
        ...prevState.templateData,
        references: [...prevState.templateData.references, reference],
      },
    }));
    this.setState({ isOpened: false });
    if (values) {
      this.editor.insertInline({
        object: 'inline',
        type: 'wf-reference',
        data: { name: reference.entity, id: reference.id, format: 'text' },
      });
    }
  };

  handleSubmitLoopModal = async ({
    values,
    allRecords,
    treePath,
    selectedLoopPath,
  }) => {
    const { selectedLoop, templateData } = this.state;

    const getLoopType = () => {
      if (values.isTable) {
        if (values.isHeaders) {
          return 'wf-loop-table-headers';
        } else if (this.isLoop()) {
          return 'wf-loop-table-nested';
        } else {
          return 'wf-loop-table';
        }
      } else {
        return 'wf-loop';
      }
    };

    this.setState({ loopValues: values });

    const initialLoop = {
      recordId:
        allRecords === false ? this.setLoopsRecordId(values.record) : '',
      allRecords: allRecords,
    };

    const loop = merge(initialLoop, selectedLoopPath);

    setFieldToLastChild(loop, values.fields);

    let loopsCopy = [];
    let lastLoopToSet = {};

    if (this.isLoop()) {
      loopsCopy = [...templateData.loops];

      const mutableLoop = findNestedLoop(loopsCopy, selectedLoop);

      lastLoopToSet = { ...findLastLoopToSet(loop) };

      if (mutableLoop.children) {
        mutableLoop.children.push(lastLoopToSet);
      } else {
        mutableLoop.children = [lastLoopToSet];
      }
    }

    setSettingsToSpecificObject({
      object: loop,
      filtering: values.conditions.length ? values.conditions : null,
      filteringDeep: values.filteringDeep,
    });

    await this.setState(prevState => ({
      templateData: {
        ...prevState.templateData,
        loops: this.isLoop()
          ? loopsCopy
          : [...prevState.templateData.loops, loop],
      },
    }));
    this.setState({ loopModalOpened: false });
    const recursivelyCreateBlocks = currentLoop => {
      this.editor.insertBlock({
        type: getLoopType(),
        nodes: [
          Block.create({
            type: values.isTable ? 'wf-loop-table-item' : 'wf-loop-item',
            data: {
              name: currentLoop.fields
                ? currentLoop.fields.map(({ field }) => field)
                : null,
            },
            nodes:
              currentLoop.fields &&
              currentLoop.fields.flatMap(({ field, id }) => [
                Text.create({ text: ' ' }),
                Inline.create({
                  type: values.isTable ? 'wf-field-table' : 'wf-field',
                  data: {
                    name: field,
                    id,
                  },
                }),
              ]),
          }),
        ],
        data: {
          name:
            currentLoop.fields &&
            currentLoop.fields.map(({ displayName }) => displayName),
          id: currentLoop.id,
        },
      });

      if (currentLoop.children) {
        return recursivelyCreateBlocks(currentLoop.children[0]);
      }
    };
    recursivelyCreateBlocks(this.isLoop() ? lastLoopToSet : loop);
  };

  handleReferenceSettings = values => {
    const format = values.format;
    const trimmedValues = pickBy(
      values,
      (value, key) => value !== '' && key !== 'format'
    );

    this.setState(prevState => {
      const cloneTemplateData = clone(prevState.templateData, true);
      const cloneSelectedRef = clone(prevState.selectedReference, true);
      const currentIndex = cloneTemplateData.references.findIndex(
        item => item.id === prevState.selectedReference.id
      );
      cloneSelectedRef.settings = {
        [format]: trimmedValues,
      };
      cloneTemplateData.references[currentIndex] = cloneSelectedRef;
      return {
        selectedReference: cloneSelectedRef,
        templateData: cloneTemplateData,
      };
    });
  };

  handleFieldSettings = values => {
    const format = isObject(values.format)
      ? values.format.format
      : values.format;
    const trimmedValues = pickBy(
      values,
      (value, key) => value !== '' && key !== 'format'
    );

    this.setState(prevState => {
      const cloneTemplateData = clone(prevState.templateData, true);
      const currentField = findField(
        cloneTemplateData.loops,
        prevState.selectedLoopField.id
      );
      currentField.settings = {
        [format]: trimmedValues,
      };

      return {
        templateData: cloneTemplateData,
      };
    });
  };

  handleSubmitFormModal = async values => {
    await this.setState(prevState => ({
      templateData: {
        ...prevState.templateData,
        forms: [...prevState.templateData.forms, values],
      },
    }));
    this.setState({ formModalOpened: false });
    if (values) {
      this.editor.insertBlock({
        type: 'wf-form',
        data: values,
      });
    }
  };

  handleLoopSettings = values => {
    const { templateData } = this.state;
    const { referencesConfig } = this.props;
    const trimmedValues = removeEmpty(values);
    let templatePath;
    templateData.loops.length
      ? (templatePath = templateData)
      : (templatePath = referencesConfig);

    this.setState(prevState => {
      const cloneTemplateData = clone(templatePath, true);
      const mutableLoop = findNestedLoop(
        cloneTemplateData.loops,
        prevState.selectedLoop
      );
      trimmedValues.filtering = prevState.selectedLoop.settings.filtering;
      mutableLoop.settings = trimmedValues;

      return {
        templateData: cloneTemplateData,
      };
    });
  };

  cleanUpTemplateData = () => {
    const UUID = /([a-z]+)-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
    const data = this.state.templateData;
    const ids = data.template.match(UUID) || [];
    this.setState({
      templateData: {
        ...data,
        references: data.references.filter(({ id }) => ids.includes(id)),
        loops: data.loops.filter(({ id }) => ids.includes(id)),
        forms: data.forms.filter(({ id }) => ids.includes(id)),
        conditions: data.conditions.filter(({ id }) => ids.includes(id)),
      },
    });
  };

  saveTemplate = async () => {
    const { saveTemplateData, storeTemplateData, selectedFuseId } = this.props;
    await this.cleanUpTemplateData();
    storeTemplateData(this.state.templateData);
    saveTemplateData(this.state.templateData, selectedFuseId);
  };

  handleChangeRaw = async ({ target: { value } }) => {
    await this.setState({ rawHtml: value });
    if (value.document !== this.state.value.document) {
      this.setState(prevState => ({
        templateData: {
          ...prevState.templateData,
          template: this.state.rawHtml,
          delta: JSON.stringify(html2.deserialize(this.state.rawHtml)),
        },
      }));
    }
  };

  handleClickShowRaw = async () => {
    await this.syncViews();
    await this.setState(prevState => ({ showRaw: !prevState.showRaw }));
  };

  syncViews = () => {
    if (this.state.showRaw) {
      this.setState({
        value: html2.deserialize(this.state.rawHtml),
      });
    } else {
      this.setState({
        rawHtml: html2.serialize(this.state.value),
      });
    }
  };

  openSettingsSidebar = ({
    openLoopSidebar,
    openReferenceSidebar,
    openFormSidebar,
    openFieldSidebar,
  }) => {
    const {
      selectedLoop,
      selectedReference,
      selectedForm,
      selectedLoopField,
    } = this.state;

    if (openLoopSidebar) {
      return (
        <LoopSettings
          handleLoopSettings={this.handleLoopSettings}
          handleCloseSidebar={this.handleCloseSidebar}
          selectedLoop={selectedLoop}
          openEditLoopModal={this.openEditLoopModal}
        />
      );
    } else if (openReferenceSidebar) {
      return (
        <ReferenceSettings
          selectedItem={selectedReference}
          setSettings={this.handleReferenceSettings}
          handleCloseSidebar={this.handleCloseSidebar}
          openEdit={this.openEditReferenceModal}
          title="Reference settings"
        />
      );
    } else if (openFormSidebar)
      return (
        <FormSettings
          handleCloseSidebar={this.handleCloseSidebar}
          handleFormSettings={this.handleFormSettings}
          selectedForm={selectedForm}
        />
      );
    else if (openFieldSidebar)
      return (
        <ReferenceSettings
          selectedItem={selectedLoopField}
          setSettings={this.handleFieldSettings}
          handleCloseSidebar={this.handleCloseSidebar}
          title="Field settings"
        />
      );
  };

  handleSubmitFilterModal = async valuesToSent => {
    this.toggleFilterModal();
    await this.editor.wrapBlock({
      type: 'wf-condition',
      data: {
        id: valuesToSent.id,
      },
    });
    await this.setState(prevState => ({
      templateData: {
        ...prevState.templateData,
        conditions: [...prevState.templateData.conditions, valuesToSent],
      },
    }));
  };

  handleFormSettings = values => {
    const trimmedValues = pickBy(values, value => value !== '');
    this.setState(prevState => {
      const cloneTemplateData = clone(prevState.templateData, true);
      const cloneSelectedForm = { ...prevState.selectedForm, ...trimmedValues };
      const currentIndex = cloneTemplateData.forms.findIndex(
        form => form.id === prevState.selectedForm.id
      );
      cloneTemplateData.forms[currentIndex] = cloneSelectedForm;

      // const node = this.state.selectedSlateNode;
      // node.data = Data.create({ ...node.data, submit: values.submit });
      return {
        selectedForm: cloneSelectedForm,
        templateData: cloneTemplateData,
      };
    });
  };

  openSnippetModal = () => {
    this.setState(
      {
        openSnippetModal: true,
      },
      () => this.props.fetchSnippet(this.props.selectedFuseId)
    );
  };

  closeSnippetModal = () => {
    this.setState({
      openSnippetModal: false,
    });
  };

  openEditReferenceModal = () => {
    this.setState({
      openEditingReferenceModal: true,
    });
  };

  closeEditReferenceModal = () => {
    this.setState({
      openEditingReferenceModal: false,
    });
  };

  openEditLoopModal = () => {
    this.setState({
      openEditingLoopModal: true,
    });
  };

  closeEditLoopModal = () => {
    this.setState({
      openEditingLoopModal: false,
    });
  };

  onDropOrPaste = (event, editor, next) => {
    const target = editor.findEventRange(event);
    if (!target && event.type === 'drop') return next();

    const transfer = getEventTransfer(event);
    const { type, text, files } = transfer;

    if (type === 'files') {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split('/');
        if (mime !== 'image') continue;

        reader.addEventListener('load', () => {
          editor.command(insertImage, reader.result, target);
        });

        reader.readAsDataURL(file);
      }
      return;
    }

    if (type === 'text') {
      if (!isUrl(text)) return next();
      if (!isImage(text)) return next();
      editor.command(insertImage, text, target);
      return;
    }

    return next();
  };

  render() {
    const {
      value,
      isOpened,
      sidebarOpen,
      loopModalOpened,
      filterModalOpened,
      previewModalOpened,
      formModalOpened,
      showRaw,
      rawHtml,
      openLoopSidebar,
      openReferenceSidebar,
      openFormSidebar,
      openFieldSidebar,
      openSnippetModal,
      selectedReference,
      openEditingReferenceModal,
      selectedLoop,
      openEditingLoopModal,
    } = this.state;

    const {
      selectedFuseId,
      resetNotifications,
      showNotification,
      notificationVariant,
      notificationMessage,
      snippet,
    } = this.props;

    return (
      <SettingsSidebar
        isOpened={sidebarOpen}
        handleSettingsOpen={this.handleOpenSideBar}
        content={this.openSettingsSidebar({
          openLoopSidebar,
          openReferenceSidebar,
          openFormSidebar,
          openFieldSidebar,
        })}
      >
        <section
          className={
            sidebarOpen === true ? 'editor-wrapper settings' : 'editor-wrapper'
          }
        >
          <div className="btn-container">
            <div className="save-btn-section">
              <StyledButton
                Class="btn-with-icon"
                action={this.handleOpenPreviewModal}
              >
                <i className="fas fa-eye"></i> Preview
              </StyledButton>
              <StyledButton Class="btn-with-icon" action={this.saveTemplate}>
                <i className="fas fa-save"></i> Save
              </StyledButton>
            </div>
            <div className="settings-btn-section">
              <StyledButton
                Class="btn-with-icon"
                action={this.openSnippetModal}
              >
                <i className="fas fa-cog"></i> Settings
              </StyledButton>
            </div>
          </div>
          <div className={showRaw ? 'showRaw' : ''}>
            <div ref="editor" className="text-editor">
              <CustomToolbar
                editor={this.editor}
                value={value}
                onChange={this.onChange}
                handleReferenceModalClick={this.handleReferenceModalClick}
                handleInsertLoop={this.handleInsertLoop}
                openLoopModal={this.openLoopModal}
                toggleFilterModal={this.toggleFilterModal}
                openFormModal={this.handleOpenFormModal}
                onClickRaw={this.handleClickShowRaw}
                showRaw={showRaw}
              />
              <Editor
                className="slate-editor"
                autoFocus
                value={value}
                onChange={this.onChange}
                renderMark={renderMark}
                renderBlock={renderBlock}
                renderInline={renderInline}
                ref={this.ref}
                plugins={plugins}
                schema={schema}
                onDrop={this.onDropOrPaste}
                onPaste={this.onDropOrPaste}
              />
              <textarea
                className="raw-editor"
                onChange={this.handleChangeRaw}
                value={rawHtml}
              />
            </div>
            <ReferenceModal
              isOpened={isOpened}
              handleOpenModal={this.handleOpenReferenceModal}
              handleSubmitReferenceModal={this.handleSubmitReferenceModal}
              selectedReference={selectedReference}
              openEditingReferenceModal={openEditingReferenceModal}
              closeEditReferenceModal={this.closeEditReferenceModal}
              setReferenceRecordId={this.setReferenceRecordId}
            />
            <FilterModal
              isOpened={filterModalOpened}
              toggleFilterModal={this.toggleFilterModal}
              handleSubmitFilterModal={this.handleSubmitFilterModal}
              setReferenceRecordId={this.setReferenceRecordId}
            />
            <LoopModal
              isOpened={loopModalOpened}
              openLoopModal={this.openLoopModal}
              handleSubmitLoopModal={this.handleSubmitLoopModal}
              value={value}
              selectedLoop={selectedLoop}
              openEditingLoopModal={openEditingLoopModal}
              closeEditLoopModal={this.closeEditLoopModal}
            />
            <FormModal
              isOpened={formModalOpened}
              handleOpenFormModal={this.handleOpenFormModal}
              handleSubmitFormModal={this.handleSubmitFormModal}
            />
            <FusePreviewModal
              isOpened={previewModalOpened}
              handleClosePreviewModal={this.handleClosePreviewModal}
            />
          </div>
          <Snackbar
            open={showNotification}
            variant={notificationVariant}
            message={notificationMessage}
            close={resetNotifications}
          />
          <SnippetModal
            showSnippetModal={openSnippetModal}
            closeSnippetModal={this.closeSnippetModal}
            snippet={snippet}
            fuseId={selectedFuseId}
          />
        </section>
      </SettingsSidebar>
    );
  }
}

Slate.propTypes = {
  placeholder: PropTypes.string,
  saveTemplateData: PropTypes.func,
  selectedFuseId: PropTypes.number,
};

const mapStateToProps = store => {
  return {
    entity_label: store.references.entity_label,
    account_data: store.references.account_data,
    selectedFuseId: store.fuses.selectedFuseId,
    selectedConnectorId: store.fuses.selectedConnectorId,
    templateData: store.fuses.templateData,
    editorDelta: store.fuses.editorDelta,
    referencesConfig: store.fuses.referencesConfig,
    fuses: store.fuses.fuses,
    loops_data: store.loops.loops_data,
    loop_records: store.loops.loop_records,
    templatePreview: store.fuses.templatePreview,
    error: store.fuses.error,
    connectorId: store.fuses.selectedConnectorId,
    loading: store.fuses.loading,
    showNotification: store.fuses.showNotification,
    notificationVariant: store.fuses.notificationVariant,
    notificationMessage: store.fuses.notificationMessage,
    snippet: store.fuses.snippet,
    account_data_for_edit: store.references.account_data_for_edit,
    entity_label_for_edit: store.references.entity_label_for_edit,
    loop_properties: store.loops.loop_properties,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFuses,
      saveTemplateData,
      storeTemplateData,
      fetchEditorDelta,
      fetchEntityData,
      addSelectedConnectorId,
      addSelectedFuseId,
      fetchParentLoopData,
      getTemplatePreview,
      fetchCurrentLabelInfo,
      clearTemplateData,
      resetNotifications,
      fetchSnippet,
      throwErrorInLoop,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Slate);
