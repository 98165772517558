import React from 'react';
import { Field } from 'formik';
import clsx from 'clsx';

import theme from './style.module.scss';

export const RedirectInput = ({
  name,
  label,
  placeholder,
  value,
  onClick,
  additionalClass,
  customClass,
  ...rest
}) => {
  return (
    <div className={`${theme.fieldContainer} ${customClass}`}>
      {label && <label>{label}</label>}
      <Field
        name={name}
        placeholder={placeholder}
        value={value}
        type="text"
        className={clsx(
          theme.inputRedirect,
          additionalClass && additionalClass
        )}
        {...rest}
      />
      <i className="fas fa-external-link-alt" onClick={onClick} />
    </div>
  );
};
