import React, { Component } from 'react';

import theme from './styles.module.scss';

class InputPair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
    this.myInp = React.createRef();
  }

  disableInput = () => {
    this.setState({
      isDisabled: true,
    });
  };

  enableInput = () => {
    this.setState(
      prevState => ({
        isDisabled: false,
      }),
      () => this.myInp.current.focus()
    );
  };

  render() {
    const { handleChange, values, nameOfField } = this.props;
    const { isDisabled } = this.state;

    return (
      <div className={theme.radioBox}>
        <div>
          <p className={theme.title}>{nameOfField}</p>
          <aside>
            <input
              id={`unchecked${nameOfField}`}
              className={theme.leaveInputField}
              name={nameOfField}
              type="radio"
              value=""
              onChange={handleChange}
              onClick={this.disableInput}
              defaultChecked
            />
            <label htmlFor={`unchecked${nameOfField}`}>Leave unchanged</label>
          </aside>
          <aside>
            <input
              id={`radio${nameOfField}`}
              name={nameOfField}
              type="radio"
              value=""
              onChange={handleChange}
              onClick={this.enableInput}
            />
            <label htmlFor={`radio${nameOfField}`}>Set {nameOfField}</label>
            <input
              name={nameOfField}
              min="0"
              type="number"
              value={values[nameOfField] || ''}
              onChange={handleChange}
              disabled={isDisabled}
              ref={this.myInp}
            />
          </aside>
        </div>
      </div>
    );
  }
}

export default InputPair;
