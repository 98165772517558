import React from 'react';
import { Field, FieldArray } from 'formik';

import AutoComplete from '../../Fuses/AutoComplete';
import Modal from '../../Modal';
import { StyledButton, RedirectInput } from '../../common';

import { AddConditionBtn } from './AddConditionBtn';

import theme from './styles.module.scss';

import {
  comparisonSuggestions,
  formatSuggestions,
  transformValue,
} from './helpers';

export const ConditionModal = props => (
  <FieldArray name="conditions">
    {({ push, remove }) => (
      <WrappedComponent {...props} push={push} remove={remove} />
    )}
  </FieldArray>
);

const WrappedComponent = ({
  isOpen,
  hideModalCondition,
  fieldToCompareOnClick,
  value1OnClick,
  value2OnClick,
  values,
  setFieldValue,
  push,
  remove,
  showModalCondition,
  fieldToCompareName,
  fieldToCompareValue,
  isDisabledCondition,
  initialCurrentCondition,
}) => {
  const {
    conditionType,
    format,
    type,
    value1,
    value2,
  } = values.currentCondition;

  const onCancel = () => {
    hideModalCondition();
  };

  const onAdd = () => {
    hideModalCondition();
    push(values.currentCondition);
  };

  const setComparison = () => {
    initialCurrentCondition &&
      setFieldValue('currentCondition', initialCurrentCondition);
    const value = type === 12 ? '' : 12;
    setFieldValue('currentCondition.type', value);
  };

  const editCondition = index => {
    setFieldValue('currentCondition', values.conditions[index]);
    showModalCondition();
  };

  const isDate = format === '3' && value1.type !== 2;

  return (
    <>
      <div className={theme.addConditionBlock}>
        <p>Conditions</p>
        {values.conditions.length > 0 &&
          values.conditions.map(
            ({ field, type, value1, value2, conditionType }, index) => (
              <div
                className={theme.arrayOfConditions}
                key={`${field.field || field} ${index}`}
              >
                <button type="button" onClick={() => {}}>
                  <i className="fas fa-plus"></i>
                </button>
                <button type="button" onClick={() => remove(index)}>
                  <i className="fas fa-minus"></i>
                </button>
                <RedirectInput
                  value={transformValue(
                    conditionType,
                    field.field || field,
                    type,
                    value1,
                    value2
                  )}
                  onClick={() => editCondition(index)}
                  readOnly
                />
              </div>
            )
          )}
        <div className={theme.nestedBlock}>
          <AddConditionBtn onClick={showModalCondition} />
        </div>
      </div>
      <Modal title="Condition" isOpen={isOpen}>
        <section className={theme.conditionModal}>
          <header>
            <h1>Add a Condition</h1>
            <p>
              Add a Condition to your Filter to display or hide selected
              content. You can compare with a value or check whether a field
              occurs. This also supports Dynamics 365 data.
            </p>
          </header>
          <div className={theme.container}>
            <p className={theme.title}>Condition type</p>
            <label>
              <Field
                name="currentCondition.conditionType"
                type="radio"
                value="compare"
                checked={conditionType === 'compare'}
                onClick={setComparison}
              />
              Compare with a value
            </label>
            <label>
              <Field
                name="currentCondition.conditionType"
                type="radio"
                value="check"
                checked={conditionType === 'check'}
                onClick={setComparison}
              />
              Check whether a field occurs
            </label>
          </div>
          <RedirectInput
            name={fieldToCompareName}
            label="Field to compare"
            placeholder="Select..."
            value={fieldToCompareValue}
            readOnly
            onClick={fieldToCompareOnClick}
          />
          {conditionType === 'compare' && (
            <>
              <AutoComplete
                label="Field formatting"
                name="currentCondition.format"
                setFieldFormik={setFieldValue}
                suggestions={formatSuggestions}
                valueFormik={format}
              />
              <AutoComplete
                label="Comparison"
                name="currentCondition.type"
                setFieldFormik={setFieldValue}
                suggestions={comparisonSuggestions}
                valueFormik={type}
              />

              <div className={theme.comparisonOptions}>
                <RedirectInput
                  name="currentCondition.value1.value"
                  label="Value 1"
                  placeholder="Select..."
                  additionalClass={isDate && theme.dateInput}
                  type={isDate ? 'date' : 'text'}
                  value={
                    value1.type === 2
                      ? `${value1.reference.entity}/${value1.reference.field}`
                      : value1.value
                  }
                  onClick={value1OnClick}
                  readOnly={value1.type === 2 ? true : false}
                />
                {type === '10' && (
                  <RedirectInput
                    name="currentCondition.value2.value"
                    label="Value 2"
                    placeholder="Select..."
                    additionalClass={isDate && theme.dateInput}
                    type={isDate ? 'date' : 'text'}
                    value={
                      value2.type === 2
                        ? `${value2.reference.entity}/${value2.reference.field}`
                        : value2.value
                    }
                    onClick={value2OnClick}
                    readOnly={value2.type === 2 ? true : false}
                  />
                )}
              </div>
            </>
          )}

          <footer>
            <StyledButton type="button" action={onCancel} variant="cancel">
              Cancel
            </StyledButton>
            <StyledButton
              type="button"
              action={onAdd}
              disabled={isDisabledCondition}
            >
              Add
            </StyledButton>
          </footer>
        </section>
      </Modal>
    </>
  );
};
