import React, { Component } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';

import {
  fetchUserData,
  setAccountSettings,
  resetNotifications,
  fetchAccountSettings,
} from '../../store/profile';
import { fetchCountries } from '../../store/references';

import { DashboardLayout } from '../../components/Dashboard/DashboardLayout';
import {
  StyledButton,
  StyledInput,
  StyledSelect,
} from '../../components/common';
import SnackBar from '../../components/Snackbar';
import { trimmedTimezones } from '../../components/Fuses/ReferenceSettings/formats-mock';

import 'react-phone-input-2/dist/style.css';
import theme from './styles.module.scss';

const accountSchema = Yup.object().shape({
  street: Yup.string()
    .min(5, 'Please enter your street + number')
    .required('Required'),
  postalCode: Yup.string()
    .min(2, 'Please enter your postal code')
    .required('Required'),
  city: Yup.string()
    .min(2, 'Please enter your city')
    .required('Required'),
  timeZone: Yup.number()
    .min(1, 'Please choose your time zone')
    .required('Please choose your time zone'),
  telephone: Yup.string()
    .min(6, 'Please enter your phone number')
    .required('Please enter your phone number'),
  country: Yup.string()
    .min(2, 'Please enter your country')
    .required('Please enter your country'),
  bill: Yup.string()
    .min(2, 'Please enter your billing contact')
    .required('Please enter your billing contact'),
});

class Account extends Component {
  state = {
    phoneNumber: '',
    companyName: '',
  };

  componentDidMount() {
    const { fetchUserData, fetchCountries, fetchAccountSettings } = this.props;
    fetchUserData();
    fetchCountries();
    fetchAccountSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.props;
    if (this.props.companyName) {
      if (
        prevProps.userData.companyName !== (userData && userData.companyName)
      ) {
        this.setState({ companyName: userData.companyName });
      }
    }
  }

  phoneHandleChange = value => {
    this.setState({ phoneNumber: value });
  };

  render() {
    const {
      accountData,
      countries,
      history,
      setAccountSettings,
      showNotification,
      notificationVariant,
      notificationMessage,
      resetNotifications,
      expiresDate,
    } = this.props;
    const initialValues = {};
    Object.keys(accountData).forEach(key => {
      if (accountData[key] === null || accountData[key] === 0) {
        initialValues[key] = '';
      } else {
        initialValues[key] = accountData[key];
      }
    });
    const expiresDateFormat = new Date(expiresDate + 'Z').toDateString();
    return (
      <DashboardLayout>
        <section className={theme.accountWrapper}>
          <Formik
            initialValues={accountData && initialValues}
            validationSchema={accountSchema}
            onSubmit={values => setAccountSettings(values)}
            enableReinitialize
          >
            {({ handleSubmit, setFieldValue, values }) => {
              return (
                <form onSubmit={handleSubmit} className={theme.accountForm}>
                  <SnackBar
                    open={showNotification}
                    close={resetNotifications}
                    message={notificationMessage}
                    variant={notificationVariant}
                  />

                  <section
                    className={clsx(theme.generalSettings, theme.billingInfo)}
                  >
                    <header>
                      <h3 className={theme.titleText}>
                        General account settings
                      </h3>
                    </header>
                    <div className={theme.container}>
                      <div className={theme.leftSide}>
                        <StyledInput
                          type="text"
                          name="company"
                          label="Company"
                          value={values.company || ''}
                          disabled
                        />
                      </div>
                      <div className={theme.rightSide}>
                        <StyledSelect
                          options={trimmedTimezones(35).map((locale, index) => {
                            return {
                              label: locale.trimmedValue,
                              value: locale.label,
                              id: `${index}`,
                            };
                          })}
                          label="Time Zone"
                          required
                          name="timeZone"
                        />
                      </div>
                    </div>
                  </section>
                  <section className={theme.generalSettings}>
                    <header>
                      <h3 className={theme.titleText}>Billing info</h3>
                    </header>
                    <div className={theme.container}>
                      <div className={theme.leftSide}>
                        <StyledInput
                          type="text"
                          name="street"
                          label="Street + Number"
                          value={values.street || ''}
                          required
                        />
                        <StyledInput
                          type="text"
                          name="postalCode"
                          label="Postal Code"
                          value={values.postalCode || ''}
                          required
                        />
                        <StyledInput
                          type="text"
                          name="city"
                          label="City"
                          value={values.city || ''}
                          required
                        />
                        <StyledInput
                          type="text"
                          name="bill"
                          label="Billing Contact"
                          value={values.bill || ''}
                          required
                        />
                      </div>
                      <div className={theme.rightSide}>
                        <StyledInput
                          type="text"
                          label="VAT"
                          name="vat"
                          value={values.vat || ''}
                          required={false}
                        />
                        <div className={theme.phoneWrapper}>
                          <label className={theme.phone}>Telephone</label>
                          <ReactPhoneInput
                            inputExtraProps={{
                              name: 'telephone',
                            }}
                            enableSearchField
                            inputStyle={{
                              backgroundColor: '#eef0f2',
                              width: '100%',
                            }}
                            containerStyle={{
                              textAlign: 'left',
                            }}
                            value={values.telephone || this.state.phoneNumber}
                            onChange={value => {
                              setFieldValue('telephone', value);
                              this.phoneHandleChange(value);
                            }}
                          />
                          <ErrorMessage
                            name="telephone"
                            component="div"
                            className={theme.warningMsg}
                          />
                        </div>

                        <StyledSelect
                          options={countries.map(locale => {
                            return {
                              label: locale.trimmedValue,
                              value: locale.value,
                              id: locale.value,
                            };
                          })}
                          label="Country"
                          required
                          name="country"
                        />
                      </div>
                    </div>
                  </section>
                  <section className={theme.generalSettings}>
                    <header>
                      <h3 className={theme.titleText}>Licensing</h3>
                    </header>
                    <div className={theme.container}>
                      <div className={theme.leftSide}>
                        <div className={theme.container}>
                          <div className={theme.leftSide}>
                            <p className={theme.licensing}>Valid until</p>
                          </div>
                          <div className={theme.rightSide}>
                            <p className={theme.licensing}>{expiresDateFormat}</p>
                          </div>
                        </div>
                      </div>
                      <div className={theme.updateButton}>
                        <StyledButton
                          type="button"
                          customStyle={{ width: '200px' }}
                          action={() => history.push('/admin/order')}
                        >
                          Update subscription
                        </StyledButton>
                        <StyledButton
                          customStyle={{
                            background: '#3d4655',
                            position: 'absolute',
                            bottom: 30,
                            right: 30,
                          }}
                        >
                          <i className="fas fa-save"></i> Save changes
                        </StyledButton>
                      </div>
                    </div>
                  </section>
                </form>
              );
            }}
          </Formik>
        </section>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = store => {
  return {
    countries: store.references.countries,
    showNotification: store.profile.showNotification,
    notificationVariant: store.profile.notificationVariant,
    notificationMessage: store.profile.notificationMessage,
    accountData: store.profile.accountData,
    expiresDate: store.profile.userData.expiriesDate,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserData,
      fetchCountries,
      fetchAccountSettings,
      setAccountSettings,
      resetNotifications,
    },
    dispatch
  );

Account.propTypes = {
  countries: PropTypes.array,
  showNotification: PropTypes.bool,
  notificationVariant: PropTypes.string,
  notificationMessage: PropTypes.string,
  accountData: PropTypes.object,
  expiresDate: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Account));
