import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const AuthorizedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('token') &&
      (props.location.pathname === '/login' ||
        props.location.pathname === '/registration') ? (
        <Redirect to="/dashboard" />
      ) : (
        <Component {...props} />
      )
    }
  />
);
