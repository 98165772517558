import React from 'react';
import PropTypes from 'prop-types';

import Page from './Page';
import Container from './Container';
import Wrapper from './Wrapper';

export const Layout = ({ children }) => (
  <Page>
    <Container width={1280}>
      <Wrapper>{children}</Wrapper>
    </Container>
  </Page>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
