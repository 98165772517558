export const formatSuggestions = [
  {
    label: 'Text',
    value: '1',
  },
  {
    label: 'Number',
    value: '2',
  },
  {
    label: 'Date',
    value: '3',
  },
];

export const comparisonSuggestions = [
  {
    label: 'Equal',
    value: '1',
  },
  {
    label: 'Not Equal',
    value: '2',
  },
  {
    label: 'Greater Than',
    value: '3',
  },
  {
    label: 'Greater Than Or Equal',
    value: '4',
  },
  {
    label: 'Less Than',
    value: '5',
  },
  {
    label: 'Less Than Or Equal',
    value: '6',
  },
  {
    label: 'Contains',
    value: '7',
  },
  {
    label: 'Starts With',
    value: '8',
  },
  {
    label: 'Ends With',
    value: '9',
  },
  {
    label: 'Between',
    value: '10',
  },
  {
    label: 'Starts With Between',
    value: '11',
  },
];

const transformType = {
  1: 'EQUAL',
  2: 'NOT EQUAL',
  3: 'GREATER THAN',
  4: 'GREATER THAN OR EQUAL',
  5: 'LESS THAN',
  6: 'LESS THAN OR EQUAL',
  7: 'CONTAINS',
  8: 'STARTS WITH',
  9: 'ENDS WITH',
  10: 'BETWEEN',
  11: 'STARTS WITH BETWEEN',
};

export const transformValue = (conditionType, field, type, value1, value2) => {
  if (conditionType === 'compare') {
    return `IF "${field}" ${transformType[type]} "${
      value1.value
        ? value1.value
        : value1.reference.entity + '/' + value1.reference.field
    }" ${value2.value && `AND "${value2.value}"`} ${
      value2.reference.entity
        ? `AND "${value2.reference.entity + '/' + value2.reference.field}"`
        : ''
    }`;
  }
  return `IF "${field}" exist`;
};
