import React from 'react';
import clsx from 'clsx';

import { FontFamilyList } from './FontList';
import { hasMark, getMark, onClickChangeFont } from './RenderComponents';

const TYPE = 'font-family';

export const FontFamilySelect = ({ editor, value, className }) => {
  let fontFamilyDefault = 0;
  if (hasMark({ value, markType: TYPE })) {
    fontFamilyDefault = getMark({ value, markType: TYPE }).data.get(
      'fontFamilyIndex'
    );
  }
  return (
    <select
      className={clsx(className)}
      onChange={event => {
        onClickChangeFont({ editor, value, event, markType: TYPE });
      }}
      value={fontFamilyDefault}
    >
      {FontFamilyList.map((font, index) => (
        <option key={`slate-font-family-${index}`} value={index}>
          {font.display_name || font.name}
        </option>
      ))}
    </select>
  );
};
