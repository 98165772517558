import {
  Toolbar,
  TablePagination,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '94%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflow: 'auto',
  },
  sortLabel: {
    fontWeight: 'bold',
    color: '#6A6F7C',
    fontSize: '14px',
  },
}));

export const StyledToolbar = withStyles(() => ({
  root: {
    backgroundColor: '#EDEFF2',
    borderRadius: '10px 10px 0 0',
    paddingLeft: 0,
  },
}))(Toolbar);

export const StyledTablePagination = withStyles(() => ({
  root: {
    backgroundColor: '#EDEFF2',
    borderRadius: '0 0 10px 10px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  menuItem: {
    minHeight: 'auto',
  },
  caption: {
    fontSize: '0.875rem',
  },
  select: {
    fontSize: '0.875rem',
  },
}))(TablePagination);

export const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
    fontFamily: 'Ubuntu, sans-serif',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: '#F2F5F9',
    fontFamily: 'Ubuntu, sans-serif',
    cursor: 'pointer',
  },
  selected: { backgroundColor: '#DCE9F6 !important' },
}))(TableRow);
