import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import SnackBar from '../../components/Snackbar';
import { validateEmail } from '../../helpers/validations';
import { forgotPassword, resetNotifications } from '../../store/profile';
import { Layout } from '../../components/Layout';
import { StyledInput, StyledButton, Logo } from '../../components/common';

import theme from './styles.module.scss';

class ResetPassword extends Component {
  render() {
    const {
      history,
      showNotification,
      notificationVariant,
      notificationMessage,
      resetNotifications,
      forgotPassword,
    } = this.props;

    return (
      <Layout>
        <div className={clsx(theme.formBox, theme.setPasswordWrapper)}>
          <Logo />
          <p>Set your email</p>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email()
                .required('Required')
                .test(
                  'validateEmail',
                  'E-mail is not allowed, please use a registered company domain',
                  value => validateEmail(value)
                ),
            })}
            onSubmit={values => {
              forgotPassword(values);
            }}
          >
            {({ handleSubmit, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <StyledInput type="text" name="email" label="E-mail" />

                  <Link className={theme.forgotLink} to="/login">
                    <span className={theme.goBack}>
                      <i className="fas fa-angle-double-left"></i>Go back
                    </span>
                  </Link>
                  <StyledButton customStyle={{ width: '100%' }} type="submit">
                    Submit
                  </StyledButton>
                </form>
              );
            }}
          </Formik>

          <SnackBar
            open={showNotification}
            variant={notificationVariant}
            message={notificationMessage}
            close={() => {
              resetNotifications();
              history.push('/');
            }}
          />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = store => ({
  showNotification: store.profile.showNotification,
  notificationVariant: store.profile.notificationVariant,
  notificationMessage: store.profile.notificationMessage,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ forgotPassword, resetNotifications }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
