import React from 'react';
import PropTypes from 'prop-types';
import { Images, greenTriangle } from './Data';

import './style.scss';

const Wrapper = ({ children }) => (
  <section className="sign-up">
    <img className="green-tr" src={greenTriangle} alt="icon" />
    {children}
    <div className="sign-up__img-box">
      {Images &&
        Images.map((item, index) => (
          <img key={index} className={item.class} src={item.img} alt="img" />
        ))}
    </div>
  </section>
);

export default Wrapper;

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
