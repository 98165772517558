// const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const allowedEmail = /^[^@]+@(yahoo|ymail|rocketmail|gmail|hotmail)\.com$/i;

// const passwordRegex = /^{8,}$/;
const passwordRegexNumbers = /[0-9]/;
const passwordRegexSmallLetters = /[a-z]/;
const passwordRegexBigLetters = /[A-Z]/;
const passwordRegexSpecialSymbols = /[!#@$%^&*)(+=._-]/;
const spacesRegex = /\s/;

export const validate = value => {
  return !value ? 'This is a required field, please complete' : undefined;
};

export const fieldRequired = (value, fieldName) => {
  if (fieldName) {
    return !value || value.length < 2
      ? `Please enter the ${fieldName}`
      : undefined;
  } else {
    return !value || value.length < 2 ? 'Invalid field' : undefined;
  }
};

export const validateEmail = value => {
  if (allowedEmail.test(value)) {
    return false;
  }

  return true;
};

export const validateNameforSpaces = name => {
  if (spacesRegex.test(name)) {
    return false;
  }
  return true;
};

export const basicPassValidation = value => {
  return !value || value.length < 6 ? 'Please use a minimum of 6 characters' : undefined;
};

export const matchValidation = (value, values) => {
  return values.password !== values.confirm_password
    ? 'Passwords must match'
    : undefined;
};

export const passwordValidation = (value, values) => {
  return basicPassValidation(value) || matchValidation(value, values);
};

export const validateInputUIState = (formState, field) => {
  if (formState.errors[field]) {
    return 'input-container isInvalid';
  } else if (
    formState.values[field] &&
    formState.touched[field] &&
    !formState.errors.hasOwnProperty(field)
  ) {
    return 'input-container isValid';
  } else {
    return 'input-container';
  }
};

export const validateInputForSuccess = (formState, field) => {
  return (
    formState.values[field] &&
    formState.touched[field] &&
    !formState.errors.hasOwnProperty(field)
  );
};

export const passwordStrength = value => {
  let strengths = 0;

  if (passwordRegexNumbers.test(value)) {
    strengths++;
  }

  if (
    passwordRegexSmallLetters.test(value) &&
    passwordRegexBigLetters.test(value)
  ) {
    strengths++;
  }

  if (passwordRegexSpecialSymbols.test(value)) {
    strengths++;
  }

  switch (strengths) {
    case 1:
      return {
        title: 'Easy',
        class: 'redMsg',
        tooltip: 'Your password is not safe, think about your security. Please add extra characters.',
      };
    case 2:
      return {
        title: 'Medium',
        class: 'orangeMsg',
        tooltip:
          'Your password could still be a little bit better. Consider adding extra characters.',
      };
    case 3:
      return {
        title: 'Strong',
        class: 'greenMsg',
        tooltip: 'Your password is well protected, good job!',
      };
    default:
      return {
        title: 'Easy',
        class: 'redMsg',
        tooltip: 'Your password is not safe, think about your security. Please add extra characters.',
      };
  }
};

export const validateConnectorName = (connectors, name) => {
  return connectors.find(connector => connector.name === name) ? false : true;
};

export const validateConnectorUrl = (connectors, resource) => {
  return connectors.find(connector => connector.resource.includes(resource))
    ? false
    : true;
};
