import icons from '../../assets/svgs/sidebar/index';

export const Data = {
  main: [
    {
      to: '/dashboard',
      text: 'Dashboard',
      icon: icons.dashboard,
    },
    {
      to: '/fuses',
      text: 'Fuses',
      icon: icons.fuses,
    },
    // {
    //   to: '/activity',
    //   text: 'Activity',
    //   icon: icons.activity,
    // },
    {
      to: '/admin',
      text: 'Administration',
      icon: icons.administration,
      customClass: 'disabled',
    },
  ],
  admin: [
    {
      to: '/admin/connectors',
      text: 'Connectors',
      breadCrumbs: 'Administration / Connectors',
      icon: icons.connectors,
    },
    {
      to: '/admin/users',
      text: 'Users',
      breadCrumbs: 'Administration / Users',
      icon: icons.users,
    },
    {
      to: '/admin/account',
      text: 'Account',
      breadCrumbs: 'Administration / Account',
      icon: icons.account,
    },
    {
      to: '/admin/order',
      text: 'Order',
      breadCrumbs: 'Administration / Order',
      icon: icons.dollar,
    },
  ],
  breadCrumbs: [
    {
      to: '/profile',
      text: 'Profile',
    },
    {
      to: '/fuses/edit',
      text: 'Fuses',
    },
  ],
};
