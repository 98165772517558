import React from 'react';
import { connect } from 'react-redux';

import Modal from '../../Modal';
import { StyledButton } from '../../common';
import AutoComplete from '../AutoComplete';
import {
  fetchEntityData,
  fetchLabelData,
  fetchCurrentLabelInfo,
} from '../../../store/references';

const SelectFieldToCondition = ({
  connectors_entity,
  entity_label,
  account_data,
  fetchLabelData,
  fetchCurrentLabelInfo,
  connectorId,
  showReferenceModal,
  hideReferenceModal,
  setFieldValue,
  values,
  setReferenceRecordId,
}) => {
  const onClose = () => {
    hideReferenceModal();
    setFieldValue('currentCondition.field', {
      field: '',
      entity: '',
      recordId: '',
    });
  };
  const { field, entity, recordId } = values.currentCondition.field;
  const isDisabled = !(field && entity && recordId);

  return (
    <Modal title="Field to compare" isOpen={showReferenceModal}>
      <div className="add-reference">
        <header>
          <h1>Select field</h1>
          <p>Select a field from your Dynamics 365 or Dataverse environment to use in your Condition.</p>
        </header>
        <AutoComplete
          label="Dynamics 365 entity"
          name="currentCondition.field.entity"
          suggestions={connectors_entity.map(({ entity, label }) => ({
            label,
            value: entity,
            entity,
          }))}
          connectorId={connectorId}
          onChangeAutoComplete={fetchLabelData}
          setFieldFormik={setFieldValue}
          valueFormik={entity}
        />
        <AutoComplete
          label="Record"
          name="currentCondition.field.recordId"
          suggestions={entity_label.map(({ label, id }) => ({
            value: label,
            label,
            entity,
            id: id,
          }))}
          connectorId={connectorId}
          onChangeAutoComplete={fetchCurrentLabelInfo}
          setFieldFormik={setFieldValue}
          valueFormik={
            entity_label.find(item => item.id === recordId) &&
            entity_label.find(item => item.id === recordId).label
          }
          setReferenceRecordId={setReferenceRecordId}
        />
        <AutoComplete
          label="Field"
          name="currentCondition.field.field"
          suggestions={account_data.map(({ label, field, type, value }) => ({
            label: label,
            value: field,
          }))}
          setFieldFormik={setFieldValue}
          valueFormik={field}
        />
        <footer>
          <StyledButton action={onClose} width="auto" variant="cancel">
            Cancel
          </StyledButton>
          <StyledButton
            type="submit"
            width="auto"
            disabled={isDisabled}
            action={hideReferenceModal}
          >
            Add
          </StyledButton>
        </footer>
      </div>
    </Modal>
  );
};

const mapStateToProps = store => {
  return {
    connectors_entity: store.references.connectors_entity,
    entity_label: store.references.entity_label,
    account_data: store.references.account_data,
    connectorId: store.fuses.selectedConnectorId,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchEntityData: connectorId => dispatch(fetchEntityData(connectorId)),
  fetchLabelData: (connectorId, entity) =>
    dispatch(fetchLabelData(connectorId, entity)),
  fetchCurrentLabelInfo: (connectorId, entity, id) =>
    dispatch(fetchCurrentLabelInfo(connectorId, entity, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectFieldToCondition);
