import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Field, ErrorMessage } from 'formik';

import theme from './styles.module.scss';

const CustomSelect = ({
  id,
  field: { name, value, onChange },
  options,
  form: { errors, values, touched },
  Class,
}) => {
  return (
    <div
      className={`${clsx(
        theme.selectWrapper,
        touched[name] && errors[name] && theme.error,
        touched[name] && !errors[name] && theme.success,
        values[id] !== '' && theme.success
      )} ${Class}`}
    >
      <select
        className={clsx(theme.select)}
        id={id}
        onChange={onChange}
        name={name}
        value={value}
      >
        <option defaultValue hidden>
          Choose item
        </option>
        {Object.values(options).map(option => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className={theme.selectArrow}></div>
      <div className={theme.selectArrow}></div>
    </div>
  );
};

export const StyledSelect = ({ options, label, name, required, Class }) => {
  return (
    <div className={theme.selectBox}>
      <label htmlFor={label} className={theme.label}>
        {label}
      </label>
      <Field
        name={name}
        id={name}
        component={CustomSelect}
        options={options}
        Class={Class}
      />
      <div className={theme.errorWrapper}>
        <ErrorMessage name={name} component="p" className={theme.warningMsg} />
      </div>
    </div>
  );
};

StyledSelect.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
