export function trimOptions(options, key, maxLength) {
  return options.map(option => {
    if (option[key].length > maxLength) {
      const trimmed = option[key].substr(0, maxLength) + ' ...';
      return {
        ...option,
        trimmedValue: trimmed,
        fullValue: option[key],
      };
    } else {
      return {
        ...option,
        trimmedValue: option[key],
        fullValue: option[key],
      };
    }
  });
}
