import Immutable from 'seamless-immutable';

import { baseRequest } from '../helpers/base-request';
import { trimOptions } from '../components/Fuses/HtmlEditor/helpers/trimOptions';
import { isString } from '../helpers/isString';

const types = {
  FETCH_ENTITY_DATA_REQUEST: 'FETCH_ENTITY_DATA_REQUEST',
  FETCH_ENTITY_DATA_SUCCESS: 'FETCH_ENTITY_DATA_SUCCESS',
  FETCH_ENTITY_DATA_ERROR: 'FETCH_ENTITY_DATA_ERROR',

  FETCH_LABEL_DATA_REQUEST: 'FETCH_LABEL_DATA_REQUEST',
  FETCH_LABEL_DATA_SUCCESS: 'FETCH_LABEL_DATA_SUCCESS',
  FETCH_LABEL_DATA_ERROR: 'FETCH_LABEL_DATA_ERROR',

  FETCH_LABEL_DATA_FOR_EDIT_REQUEST: 'FETCH_LABEL_DATA_FOR_EDIT_REQUEST',
  FETCH_LABEL_DATA_FOR_EDIT_SUCCESS: 'FETCH_LABEL_DATA_FOR_EDIT_SUCCESS',
  FETCH_LABEL_DATA_FOR_EDIT_ERROR: 'FETCH_LABEL_DATA_FOR_EDIT_ERROR',

  FETCH_CURRENT_LABEL_INFO_REQUEST: 'FETCH_CURRENT_LABEL_INFO_REQUEST',
  FETCH_CURRENT_LABEL_INFO_SUCCESS: 'FETCH_CURRENT_LABEL_INFO_SUCCESS',
  FETCH_CURRENT_LABEL_INFO_ERROR: 'FETCH_CURRENT_LABEL_INFO_ERROR',

  FETCH_CURRENT_LABEL_INFO_FOR_EDIT: 'FETCH_CURRENT_LABEL_INFO_FOR_EDIT',
  FETCH_CURRENT_LABEL_INFO_FOR_EDIT_SUCCESS:
    'FETCH_CURRENT_LABEL_INFO_FOR_EDIT_SUCCESS',
  FETCH_CURRENT_LABEL_INFO_FOR_EDIT_ERROR:
    'FETCH_CURRENT_LABEL_INFO_FOR_EDIT_ERROR',

  FETCH_SETTINGS_LOCALES_REQUEST: 'FETCH_SETTINGS_LOCALES_REQUEST',
  FETCH_SETTINGS_LOCALES_SUCCESS: 'FETCH_SETTINGS_LOCALES_SUCCESS',
  FETCH_SETTINGS_LOCALES_ERROR: 'FETCH_SETTINGS_LOCALES_ERROR',

  FETCH_COUNTRIES_REQUEST: 'FETCH_COUNTRIES_REQUEST',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_ERROR: 'FETCH_COUNTRIES_ERROR',

  FETCH_SETTINGS_CURRENCIES_REQUEST: 'FETCH_SETTINGS_CURRENCIES_REQUEST',
  FETCH_SETTINGS_CURRENCIES_SUCCESS: 'FETCH_SETTINGS_CURRENCIES_SUCCESS',
  FETCH_SETTINGS_CURRENCIES_ERROR: 'FETCH_SETTINGS_CURRENCIES_ERROR',

  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
};

const initialState = Immutable({
  isEdit: false,
  connectors_entity: [],
  entity_label: [],
  entity_label_for_edit: [],
  account_data: [],
  account_data_for_edit: [],
  countries: [],
  settings_locales: [],
  settings_currencies: [],
  loading: false,
  showNotification: false,
  notificationVariant: '',
  notificationMessage: '',
});

const fetchCurrenciesRequest = () => ({
  type: types.FETCH_SETTINGS_CURRENCIES_REQUEST,
});

const fetchLocalesRequest = () => ({
  type: types.FETCH_SETTINGS_LOCALES_REQUEST,
});

const fetchCurrentLabelInfoRequest = () => ({
  type: types.FETCH_CURRENT_LABEL_INFO_REQUEST,
});

const fetchLabelDataRequest = () => ({
  type: types.FETCH_LABEL_DATA_REQUEST,
});

const fetchEntityDataRequest = () => ({
  type: types.FETCH_ENTITY_DATA_REQUEST,
});

export const resetNotifications = () => ({
  type: types.RESET_NOTIFICATIONS,
});

export const fetchEntityData = connectorId => {
  const success = response => ({
    type: types.FETCH_ENTITY_DATA_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.FETCH_ENTITY_DATA_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchEntityDataRequest());
    return baseRequest('GET', `/api/Connectors/${connectorId}/entities`)
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchLabelData = (connectorId, entity) => {
  const success = response => ({
    type: types.FETCH_LABEL_DATA_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.FETCH_LABEL_DATA_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchLabelDataRequest());
    return baseRequest(
      'GET',
      `/api/Connectors/${connectorId}/entities/${entity}/records`
    )
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchLabelDataForEdit = (connectorId, entity) => {
  const success = response => ({
    type: types.FETCH_LABEL_DATA_FOR_EDIT_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.FETCH_LABEL_DATA_FOR_EDIT_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchLabelDataRequest());
    return baseRequest(
      'GET',
      `/api/Connectors/${connectorId}/entities/${entity}/records`
    )
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchCurrentLabelInfo = (connectorId, entity, recordId) => {
  const success = data => ({
    type: types.FETCH_CURRENT_LABEL_INFO_SUCCESS,
    payload: data,
  });

  const failure = error => ({
    type: types.FETCH_CURRENT_LABEL_INFO_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchCurrentLabelInfoRequest());
    return baseRequest(
      'GET',
      `/api/Connectors/test/${connectorId}/entities/${entity}/records/${recordId}`
    )
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchCurrentLabelInfoForEdit = (connectorId, entity, id) => {
  const success = data => ({
    type: types.FETCH_CURRENT_LABEL_INFO_FOR_EDIT_SUCCESS,
    payload: data,
  });

  const failure = error => ({
    type: types.FETCH_CURRENT_LABEL_INFO_FOR_EDIT_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchCurrentLabelInfoRequest());
    return baseRequest(
      'GET',
      `/api/Connectors/test/${connectorId}/entities/${entity}/records/${id}`
    )
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchSettingsLocales = () => {
  const success = response => ({
    type: types.FETCH_SETTINGS_LOCALES_SUCCESS,
    payload: trimOptions(response.data, 'label', 20),
  });

  const failure = error => ({
    type: types.FETCH_SETTINGS_LOCALES_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchLocalesRequest());
    return baseRequest('GET', '/api/Settings/locales')
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchCountries = () => {
  const success = response => ({
    type: types.FETCH_COUNTRIES_SUCCESS,
    payload: trimOptions(response.data, 'label', 30),
  });

  const failure = error => ({
    type: types.FETCH_COUNTRIES_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchLocalesRequest());
    return baseRequest('GET', '/api/Settings/countries')
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchSettingsCurrencies = () => {
  const success = response => ({
    type: types.FETCH_SETTINGS_CURRENCIES_SUCCESS,
    payload: trimOptions(response.data, 'label', 20),
  });

  const failure = error => ({
    type: types.FETCH_SETTINGS_CURRENCIES_ERROR,
    payload: isString(error),
  });
  return dispatch => {
    dispatch(fetchCurrenciesRequest());
    return baseRequest('GET', '/api/Settings/currencies')
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ENTITY_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_ENTITY_DATA_SUCCESS:
      return {
        ...state,
        connectors_entity: action.payload.data,
        loading: false,
      };

    case types.FETCH_ENTITY_DATA_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_LABEL_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_LABEL_DATA_SUCCESS:
      return {
        ...state,
        entity_label: action.payload.data,
        loading: false,
      };

    case types.FETCH_LABEL_DATA_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_LABEL_DATA_FOR_EDIT_SUCCESS:
      return {
        ...state,
        entity_label_for_edit: action.payload.data,
        // entity_label: [],
        // account_data: {},
        loading: false,
      };

    case types.FETCH_LABEL_DATA_FOR_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_CURRENT_LABEL_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_CURRENT_LABEL_INFO_SUCCESS:
      return {
        ...state,
        account_data: action.payload.data,
        loading: false,
      };

    case types.FETCH_CURRENT_LABEL_INFO_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_CURRENT_LABEL_INFO_FOR_EDIT_SUCCESS:
      return {
        ...state,
        account_data_for_edit: action.payload.data,
        loading: false,
      };

    case types.FETCH_CURRENT_LABEL_INFO_FOR_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_SETTINGS_LOCALES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_SETTINGS_LOCALES_SUCCESS:
      return {
        ...state,
        settings_locales: action.payload,
        loading: false,
      };

    case types.FETCH_SETTINGS_LOCALES_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loading: false,
      };

    case types.FETCH_COUNTRIES_ERROR:
      return {
        ...state,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_SETTINGS_CURRENCIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_SETTINGS_CURRENCIES_SUCCESS:
      return {
        ...state,
        settings_currencies: action.payload,
        loading: false,
      };

    case types.FETCH_SETTINGS_CURRENCIES_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.RESET_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        showNotification: false,
        notificationVariant: '',
        notificationMessage: '',
      };

    default:
      return state;
  }
}
