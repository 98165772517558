import Immutable from 'seamless-immutable';

import { baseRequest } from '../helpers/base-request';
import { isString } from '../helpers/isString';

const types = {
  CREATE_CONNECTOR_REQUEST: 'CREATE_CONNECTOR_REQUEST',
  CREATE_CONNECTOR_SUCCESS: 'CREATE_CONNECTOR_SUCCESS',
  CREATE_CONNECTOR_ERROR: 'CREATE_CONNECTOR_ERROR',

  FETCH_CONNECTORS_REQUEST: 'FETCH_CONNECTORS_REQUEST',
  FETCH_CONNECTORS_SUCCESS: 'FETCH_CONNECTORS_SUCCESS',
  FETCH_CONNECTORS_ERROR: 'FETCH_CONNECTORS_ERROR',

  DELETE_CONNECTOR_REQUEST: 'DELETE_CONNECTOR_REQUEST',
  DELETE_CONNECTOR_SUCCESS: 'DELETE_CONNECTOR_SUCCESS',
  DELETE_CONNECTOR_ERROR: 'DELETE_CONNECTOR_ERROR',

  TEST_CONNECTOR_REQUEST: 'TEST_CONNECTOR_REQUEST',
  TEST_CONNECTOR_SUCCESS: 'TEST_CONNECTOR_SUCCESS',
  TEST_CONNECTOR_ERROR: 'TEST_CONNECTOR_ERROR',

  EDIT_CONNECTOR_REQUEST: 'EDIT_CONNECTOR_REQUEST',
  EDIT_CONNECTOR_SUCCESS: 'EDIT_CONNECTOR_SUCCESS',
  EDIT_CONNECTOR_ERROR: 'EDIT_CONNECTOR_ERROR',

  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
};

export const resetNotifications = () => ({
  type: types.RESET_NOTIFICATIONS,
});

const createConnectorRequest = () => ({
  type: types.CREATE_CONNECTOR_REQUEST,
});

const fetchConnectorsRequest = () => ({
  type: types.FETCH_CONNECTORS_REQUEST,
});

const removeConnectorRequest = () => ({
  type: types.DELETE_CONNECTOR_REQUEST,
});

const testConnectorRequest = () => ({
  type: types.TEST_CONNECTOR_REQUEST,
});

const editConnectorRequest = () => ({
  type: types.EDIT_CONNECTOR_REQUEST,
});

const initialState = Immutable({
  openModal: false,
  error: null,
  connectors: [],
  connector_id: null,
  loading: false,
  showNotification: false,
  notificationVariant: '',
  notificationMessage: '',
});

export const createConnector = (name, url) => {
  const success = response => ({
    type: types.CREATE_CONNECTOR_SUCCESS,
    payload: response.data,
  });

  const failure = error => ({
    type: types.CREATE_CONNECTOR_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(createConnectorRequest());
    return baseRequest('POST', '/api/connectors/connect', {
      name: name,
      resource: url,
    })
      .then(response => {
        dispatch(success(response));
        window.location.assign(response.data);
      })
      .catch(error => {
        dispatch(failure(error));
        window.location.assign('connectors?verifyConnector=false');
      });
  };
};

export const fetchConnectors = () => {
  const success = response => ({
    type: types.FETCH_CONNECTORS_SUCCESS,
    payload: response,
  });

  const failure = error => ({
    type: types.FETCH_CONNECTORS_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchConnectorsRequest());
    return baseRequest('GET', '/api/Connectors')
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const removeConnectors = connectors => {
  const success = id => {
    return {
      type: types.DELETE_CONNECTOR_SUCCESS,
      connector_id: id,
    };
  };
  const failure = error => ({
    type: types.DELETE_CONNECTOR_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(removeConnectorRequest());
    return connectors.forEach(id =>
      baseRequest('DELETE', `/api/Connectors/${id}`)
        .then(response => dispatch(success(id)))
        .catch(error => dispatch(failure(error)))
    );
  };
};

export const testConnector = id => {
  const success = response => {
    return {
      type: types.TEST_CONNECTOR_SUCCESS,
      payload: response.data,
    };
  };

  const failure = error => {
    return {
      type: types.TEST_CONNECTOR_ERROR,
      payload: isString(error),
    };
  };
  return dispatch => {
    dispatch(testConnectorRequest());
    return baseRequest('GET', `/api/Connectors/${id}/test`)
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const editConnector = (id, name) => {
  const success = response => {
    return {
      type: types.EDIT_CONNECTOR_SUCCESS,
      payload: response.data,
    };
  };

  const failure = error => {
    return {
      type: types.EDIT_CONNECTOR_ERROR,
      payload: isString(error),
    };
  };

  return dispatch => {
    dispatch(editConnectorRequest());
    return baseRequest('PUT', `/api/Connectors/${id}`, { name })
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_CONNECTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_CONNECTOR_SUCCESS:
      return {
        ...state,
        connectors: action.payload.data
          ? [...state.connectors, action.payload.data]
          : [...state.connectors],
        openModal: true,
        loading: false,
      };

    case types.CREATE_CONNECTOR_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_CONNECTORS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_CONNECTORS_SUCCESS:
      return {
        ...state,
        connectors: action.payload.data,
        loading: false,
      };

    case types.FETCH_CONNECTORS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.DELETE_CONNECTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.DELETE_CONNECTOR_SUCCESS:
      const filterConnectors = state.connectors.filter(
        connector => connector.id !== action.connector_id
      );
      return {
        ...state,
        connectors: filterConnectors,
        connector_id: null,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Connector was successfully removed',
      };

    case types.DELETE_CONNECTOR_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.TEST_CONNECTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.TEST_CONNECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'We successfully connected to your Connector',
      };

    case types.TEST_CONNECTOR_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: 'Connector testing was not passed, please check the URL or rights of your user',
      };

    case types.EDIT_CONNECTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.EDIT_CONNECTOR_SUCCESS:
      const position = state.connectors.findIndex(
        connector => connector.id === action.payload.id
      );
      const copyConnectors = [...state.connectors];
      copyConnectors[position] = action.payload;
      return {
        ...state,
        connectors: copyConnectors,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'Connector details are successfully updated',
      };

    case types.EDIT_CONNECTOR_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.RESET_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        showNotification: false,
        notificationVariant: '',
        notificationMessage: '',
      };

    default:
      return state;
  }
}
