import React from 'react';
import clsx from 'clsx';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import checkmarkIcon from '../../../assets/svgs/checkmark.svg';
import theme from './styles.module.scss';

const CustomInput = ({
  field,
  value,
  form: { touched, errors, setTouched, values, isValid },
  handleChange,
  disabled,
  required,
  ...props
}) => {
  const changeHandler = event => {
    if (handleChange) {
      handleChange(event);
      field.onChange(event);
    } else {
      field.onChange(event);
    }
  };
  return (
    <div
      className={clsx(
        theme.inputContainer,
        values[field.name] && errors[field.name] && theme.error,
        touched[field.name] && required && theme.error,
        values[field.name] && !errors[field.name] && theme.success
      )}
    >
      <input
        type="text"
        {...props}
        name={field.name}
        label={field.label}
        disabled={disabled}
        onChange={changeHandler}
        onBlur={() => setTouched({ ...touched, [field.name]: true })}
        value={value}
      />
      <img src={checkmarkIcon} className={theme.checkmark} alt="checkmark" />
    </div>
  );
};

export const StyledInput = ({
  name,
  type,
  label,
  required = true,
  value,
  disabled,
  handleChange,
  Class = '',
}) => {
  return (
    <div className={`${clsx(theme.inputBox)} ${Class}`}>
      <label
        htmlFor={name}
        className={required ? theme.inputLabelRequired : theme.inputLabel}
      >
        {label}
      </label>
      <Field
        type={type}
        name={name}
        id={name}
        disabled={disabled}
        handleChange={handleChange}
        value={value}
        component={CustomInput}
        required={required}
      />
      <ErrorMessage name={name} component="div" className={theme.warningMsg} />
    </div>
  );
};

StyledInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};
