import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from '../../Modal';
import { Formik, FieldArray } from 'formik';
import { StyledButton, StyledCheckbox } from '../../../components/common';
import StyledInput from '../../common/StyledInput';
import AutoComplete from '../AutoComplete';
import SelectInputModal from './SelectInputModal';
import RedirectInput from '../../common/RedirectInput';
import uuidv4 from 'uuid/v4';

import { fetchLoopProperties } from '../../../store/loops';
import { fetchFormRelations } from '../../../store/form';

import {
  fetchEntityData,
  fetchLabelData,
  fetchCurrentLabelInfo,
} from '../../../store/references';

import theme from './styles.module.scss';

class FormModal extends Component {
  state = {
    isOpenedInputModal: false,
    useCapcha: false,
    values: {},
    treePath: '',
    currentInput: null,
    currentInputIndex: '',
    fields: [],
  };

  toggleInputModal = entity => {
    this.setState(prevState => ({
      isOpenedInputModal: !prevState.isOpenedInputModal,
    }));
  };

  openInputModalWithRequest = entity => {
    const { fetchLoopProperties, connectorId, fetchFormRelations } = this.props;
    this.setState(prevState => ({
      isOpenedInputModal: !prevState.isOpenedInputModal,
    }));
    if (entity) {
      fetchLoopProperties(connectorId, entity);
      fetchFormRelations(connectorId, entity);
    }
  };

  openInputModalForEdit = (index, values) => {
    this.setState(prevState => ({
      isOpenedInputModal: !prevState.isOpenedInputModal,
      currentInput: values.inputs[index],
      currentInputIndex: index,
    }));
  };

  resetInitialValues = () => {
    this.setState({
      currentInput: null,
      currentInputIndex: '',
    });
  };

  setInputValue = (e, selectedLoopPath) => {
    const targetEl = e.target.innerHTML;
    this.setState({ treePath: targetEl, selectedLoopPath: selectedLoopPath });
  };

  handleSubmitInputModal = values => {
    this.setState({ values, isOpenedInputModal: false });
  };

  handleChangeCapchaCheckbox = () => {
    this.setState(prevState => ({
      useCapcha: !prevState.useCapcha,
    }));
  };

  removeRelatedInput = ({ id, values, setFieldValue }) => {
    const copyRelatedForms = values.relatedForms;
    const filteredForms = copyRelatedForms.filter(({ inputs }) =>
      inputs.some(input => input.id !== id)
    );
    setFieldValue('relatedForms', filteredForms);
  };

  render() {
    const {
      isOpened,
      handleOpenFormModal,
      handleSubmitFormModal,
      connectors_entity,
      entity_label,
      fetchLabelData,
      fetchCurrentLabelInfo,
      fetchLoopProperties,
      connectorId,
      loop_properties,
      account_data,
      relations,
    } = this.props;
    const { isOpenedInputModal, useCapcha, currentInputIndex } = this.state;
    return (
      <Modal isOpen={isOpened} title="formModal">
        <section className={theme.formModal}>
          <header>
            <h1>Add Form</h1>
            <p>
              Add a Form to your Fuse. Select the primary entity where you want
              to write the inputted data back to. You can update an existing
              record or add a new record in your Dynamics 365- or Dataverse-instance. Add as
              many input fields as you like. Use the pane on the right side in
              the HTML editor to finalize your settings.
            </p>
          </header>
          <Formik
            initialValues={{
              id: `form-${uuidv4()}`,
              entity: '',
              recordId: '',
              name: '',
              submit: '',
              requestMethod: 1,
              reCaptchaKey: '',
              inputs: [],
              relatedForms: [],
            }}
            onSubmit={values => {
              handleSubmitFormModal(values, this.state.values);
            }}
            render={({ setFieldValue, values, handleSubmit, setValues }) => {
              const {
                name,
                entity,
                reCaptchaKey,
                submit,
                inputs,
                recordId,
                requestMethod,
                relatedForms,
              } = values;
              const isDisabled = !(
                name &&
                entity &&
                (useCapcha ? reCaptchaKey : true) &&
                submit &&
                (inputs.length || relatedForms.length) &&
                (requestMethod === 2 ? recordId : true)
              );
              return (
                <form onSubmit={handleSubmit}>
                  <StyledInput
                    type="text"
                    label="Name"
                    name="name"
                    onChange={value => setFieldValue('name', value)}
                  />
                  <AutoComplete
                    label="Primary entity"
                    name="entity"
                    isDisabled={false}
                    suggestions={connectors_entity.map(({ entity, label }) => ({
                      label,
                      value: entity,
                      entity,
                    }))}
                    connectorId={connectorId}
                    onChangeAutoComplete={fetchLabelData}
                    setFieldFormik={setFieldValue}
                    valueFormik={entity}
                    setValues={setValues}
                    valuesToReset={{ ...values, recordId: '' }}
                  />
                  <div className={theme.recordsCheckboxContainer}>
                    <label>
                      <input
                        type="radio"
                        value="newRecord"
                        name="requestMethod"
                        onChange={() => setFieldValue('requestMethod', 1)}
                        defaultChecked
                      />
                      Add new record
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="existingRecord"
                        name="requestMethod"
                        onChange={() => setFieldValue('requestMethod', 2)}
                      />
                      Update existing record
                    </label>
                    {requestMethod === 2 ? (
                      <>
                        <AutoComplete
                          label="Record to update"
                          Class="record-update"
                          name="recordId"
                          suggestions={entity_label.map(({ label, id }) => ({
                            value: id,
                            label,
                            entity,
                            id: id,
                          }))}
                          connectorId={connectorId}
                          onChangeAutoComplete={fetchCurrentLabelInfo}
                          setFieldFormik={setFieldValue}
                          valueFormik={recordId}
                        />
                      </>
                    ) : null}
                    <FieldArray name="inputs">
                      {({ push, remove }) => (
                        <>
                          <div className={theme.fieldsContainer}>
                            <p>Fields</p>
                            <div className={theme.addConditionBlock}>
                              {[
                                ...inputs,
                                ...relatedForms.map(item => item.inputs).flat(),
                              ].map(
                                (
                                  { field, label, id, relatedProperty },
                                  index
                                ) => (
                                  <div
                                    className={theme.arrayOfFields}
                                    key={`${label} ${index}`}
                                  >
                                    <button
                                      type="button"
                                      onClick={() => push(values.inputs[index])}
                                    >
                                      <i className="fas fa-plus"></i>
                                    </button>
                                    <button
                                      onClick={() =>
                                        relatedProperty
                                          ? this.removeRelatedInput({
                                              id,
                                              values,
                                              setFieldValue,
                                            })
                                          : remove(index)
                                      }
                                      type="button"
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                    <RedirectInput
                                      value={field}
                                      onClick={() =>
                                        this.openInputModalForEdit(
                                          index,
                                          values
                                        )
                                      }
                                      readOnly
                                    />
                                  </div>
                                )
                              )}
                            </div>
                            <button
                              type="button"
                              className={theme.addFieldBtn}
                              onClick={() =>
                                this.openInputModalWithRequest(entity)
                              }
                              disabled={!entity}
                            >
                              <i className="fas fa-plus" />
                              Add field
                            </button>
                          </div>
                          <SelectInputModal
                            toggleInputModal={this.toggleInputModal}
                            isOpened={isOpenedInputModal}
                            setInputValue={this.setInputValue}
                            handleSubmitInputModal={this.handleSubmitInputModal}
                            fetchLoopProperties={fetchLoopProperties}
                            loop_properties={loop_properties}
                            account_data={account_data}
                            currentValues={this.state.currentInput}
                            parentValues={values}
                            push={push}
                            setFieldFormik={values => {
                              setFieldValue(
                                `inputs[${currentInputIndex}]`,
                                values
                              );
                              this.resetInitialValues();
                            }}
                            resetInitialValues={this.resetInitialValues}
                            setFieldValueParent={setFieldValue}
                            valuesParent={values}
                            connectorId={connectorId}
                            relations={relations}
                            parentEntity={entity}
                          />
                        </>
                      )}
                    </FieldArray>
                  </div>
                  <StyledCheckbox
                    name="capcha"
                    text="Add Captcha"
                    passSettings={this.handleChangeCapchaCheckbox}
                    style={{ padding: 0, height: 30 }}
                    checked={this.state.useCapcha}
                  />
                  {useCapcha === true ? (
                    <StyledInput
                      type="text"
                      label="Add your Captcha API key"
                      name="reCaptchaKey"
                      onChange={value => setFieldValue('reCaptchaKey', value)}
                      value={reCaptchaKey}
                    />
                  ) : null}
                  <StyledInput
                    type="text"
                    label="Submit button label"
                    name="submit"
                    onChange={value => setFieldValue('submit', value)}
                  />
                  <footer>
                    <StyledButton
                      action={handleOpenFormModal}
                      width="auto"
                      variant="cancel"
                    >
                      Cancel
                    </StyledButton>
                    <StyledButton
                      type="submit"
                      width="auto"
                      disabled={isDisabled}
                    >
                      Add
                    </StyledButton>
                  </footer>
                </form>
              );
            }}
          />
        </section>
      </Modal>
    );
  }
}

const mapStateToProps = store => {
  return {
    connectors_entity: store.references.connectors_entity,
    entity_label: store.references.entity_label,
    connectorId: store.fuses.selectedConnectorId,
    loop_properties: store.loops.loop_properties,
    account_data: store.references.account_data,
    relations: store.form.relations,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEntityData,
      fetchLabelData,
      fetchCurrentLabelInfo,
      fetchLoopProperties,
      fetchFormRelations,
    },
    dispatch
  );

FormModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleOpenFormModal: PropTypes.func.isRequired,
  connectors_entity: PropTypes.array,
  entity_label: PropTypes.array,
  connectorId: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormModal);
