import view from '../../assets/svgs/view.svg';
import edit from '../../assets/svgs/edit.svg';
import cross from '../../assets/svgs/cross.svg';

export const icons = {
  view,
  edit,
  cross,
};

export const headRows = [
  {
    id: 'name',
    label: 'Connector Name',
  },
  {
    id: 'resource',
    label: 'URL',
  },
];
