import imageExtensions from 'image-extensions';

export const convertToItems = fields => {
  return fields.map((field, index) => {
    if (index === 0) {
      return { label: 'item', value: field };
    } else return { label: 'item ' + index, value: field };
  });
};

export function removeEmpty(obj) {
  Object.keys(obj).forEach(function(key) {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (obj[key] === '') delete obj[key];
  });
  return obj;
}

export const findNestedLoop = (loops, loopNode) => {
  let loop = {};
  return (function recursion(loops, loopNode) {
    loops &&
      loops.forEach(loopItem => {
        if (loopItem.id === loopNode.id) {
          loop = loopItem;
        } else {
          if (!loopItem.children) {
            return;
          }
          return recursion(loopItem.children, loopNode);
        }
      });
    return loop;
  })(loops, loopNode);
};

export const getLastField = obj => {
  if (obj.fields) {
    return obj.fields;
  } else {
    return getLastField(obj.children[0]);
  }
};

export const setFieldToLastChild = (obj, fields) => {
  if (obj.children) {
    return setFieldToLastChild(obj.children[0], fields);
  } else {
    obj.fields = fields;
    return;
  }
};

export const findLastLoopToSet = loop => {
  if (loop.children) {
    return findLastLoopToSet(loop.children[0]);
  } else {
    return loop;
  }
};

export const findField = (loops, itemId) => {
  let currentField = {};
  return (function recursion(loops, itemId) {
    loops &&
      loops.forEach(loopItem => {
        loopItem.fields &&
          loopItem.fields.forEach(field => {
            if (field.id === itemId) {
              currentField = field;
              return;
            }
          });
        if (loopItem.children && !Object.entries(currentField).length) {
          return recursion(loopItem.children, itemId);
        }
      });
    return currentField;
  })(loops, itemId);
};

export const insertImage = (editor, src, target, width, height) => {
  if (target) {
    editor.select(target);
  }

  editor.insertBlock({
    type: 'img',
    data: { src, width, height },
  });
};

const getExtension = url => {
  return new URL(url).pathname.split('.').pop();
};

export const isImage = url => {
  return imageExtensions.includes(getExtension(url));
};
