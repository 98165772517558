import React, { useState } from 'react';

import { Button, Paper } from '@material-ui/core';
import { StyledTablePagination, StyledToolbar, useStyles } from './style';
import clsx from 'clsx';
import edit from '../../assets/svgs/edit.svg';
import cross from '../../assets/svgs/cross.svg';
import theme from './styles.module.scss';
import testConnectorImg from '../../assets/svgs/testFuse.svg';

export const EmptyTable = ({
  title,
  backGroundImg,
  variant = 'connectors',
}) => {
  const [pageRows, setRowsPerPage] = useState(5);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {variant === 'fuses' ? (
          <StyledToolbar>
            <div>
              <Button className="head_nav" disabled>
                <i
                  className={clsx('fas fa-cog', 'head_nav_icon')}
                  aria-hidden="true"
                ></i>
                Settings
              </Button>
              <Button className="head_nav" disabled>
                <img src={edit} alt="nav" className="head_nav_icon" />
                Edit
              </Button>
              <Button className="head_nav" disabled>
                <img src={cross} alt="nav" className="head_nav_icon" />
                Remove
              </Button>
            </div>
          </StyledToolbar>
        ) : (
          <StyledToolbar>
            <div>
              <Button className="head_nav" disabled>
                <img src={edit} alt="nav" className="head_nav_icon" />
                Edit
              </Button>
              <Button className="head_nav" disabled>
                <img src={cross} alt="nav" className="head_nav_icon" />
                Remove
              </Button>
              <Button className="head_nav" disabled>
                <img
                  className={clsx('fas fa-vials', 'head_nav_icon')}
                  src={testConnectorImg}
                  alt="test"
                />
                Test
              </Button>
            </div>
          </StyledToolbar>
        )}

        <div style={backGroundImg} className={theme.noFusesWrapper}>
          <div>
            <span>{title}</span>
          </div>
        </div>

        <StyledTablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={pageRows}
          count={0}
          page={0}
          onChangePage={() => {}}
          onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
        />
      </Paper>
    </div>
  );
};
