import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import {
  setPasswordForInvitedUser,
  resetNotifications,
} from '../../store/profile';
import { Layout } from '../../components/Layout';
import { StyledInput, StyledButton, Logo } from '../../components/common';
import SnackBar from '../../components/Snackbar/';

import theme from './styles.module.scss';
import { PasswordStrength } from '../../components';
import { validateNameforSpaces } from '../../helpers/validations';

class ForgotPassword extends Component {
  state = {
    passwordToken: '',
    userId: '',
  };

  componentDidMount() {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);

      const passwordToken = urlParams.get('passwordToken');
      const userId = urlParams.get('userId');

      return this.setState({ passwordToken, userId });
    }
  }
  render() {
    const { passwordToken, userId } = this.state;
    const {
      showNotification,
      notificationMessage,
      notificationVariant,
      resetNotifications,
      setPasswordForInvitedUser,
    } = this.props;
    return (
      <Layout>
        <div className={clsx(theme.formBox, theme.setPasswordWrapper)}>
          <Logo />
          <p>Set your password</p>
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(6, 'Please use a minimum of 6 characters')
                .max(20, 'Please use a maximum of 20 characters')
                .test(
                  'Warning, password can`t contain spaces',
                  'Warning, password can`t contain spaces',
                  value => validateNameforSpaces(value)
                )
                .required('Required'),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password')], 'Passwords do not match')
                .min(6, 'Please use a minimum of 6 characters')
                .max(20, 'Please use a maximum of 20 characters')
                .test(
                  'Warning, password can`t contain spaces',
                  'Warning, password can`t contain spaces',
                  value => validateNameforSpaces(value)
                )
                .required('Required'),
            })}
            onSubmit={values => {
              const { password } = values;
              setPasswordForInvitedUser({
                passwordToken,
                userId,
                password,
              });
            }}
          >
            {({ handleSubmit, values, isValid }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <StyledInput
                    type="password"
                    name="password"
                    label="Password"
                  />
                  {isValid && <PasswordStrength password={values.password} />}
                  <StyledInput
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                  />
                  <StyledButton customStyle={{ width: '100%' }} type="submit">
                    Set Password
                  </StyledButton>
                </form>
              );
            }}
          </Formik>
          <SnackBar
            open={showNotification}
            message={notificationMessage}
            variant={notificationVariant}
            close={resetNotifications}
          />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = store => ({
  showNotification: store.profile.showNotification,
  notificationMessage: store.profile.notificationMessage,
  notificationVariant: store.profile.notificationVariant,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { setPasswordForInvitedUser, resetNotifications },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
