import { format } from 'date-fns';

import { trimOptions } from '../../../components/Fuses/HtmlEditor/helpers/trimOptions';

const barcodeTypes = {
  UNSPECIFIED: 0,
  UPCA: 1,
  UPCE: 2,
  UPC_SUPPLEMENTAL_2DIGIT: 3,
  UPC_SUPPLEMENTAL_5DIGIT: 4,
  EAN13: 5,
  EAN8: 6,
  Interleaved2of5: 7,
  Interleaved2of5_Mod10: 8,
  Standard2of5: 9,
  Standard2of5_Mod10: 10,
  Industrial2of5: 11,
  Industrial2of5_Mod10: 12,
  CODE39: 13,
  CODE39Extended: 14,
  CODE39_Mod43: 15,
  Codabar: 16,
  PostNet: 17,
  BOOKLAND: 18,
  ISBN: 19,
  JAN13: 20,
  MSI_Mod10: 21,
  MSI_2Mod10: 22,
  MSI_Mod11: 23,
  MSI_Mod11_Mod10: 24,
  Modified_Plessey: 25,
  CODE11: 26,
  USD8: 27,
  UCC12: 28,
  UCC13: 29,
  LOGMARS: 30,
  CODE128: 31,
  CODE128A: 32,
  CODE128B: 33,
  CODE128C: 34,
  ITF14: 35,
  CODE93: 36,
  TELEPEN: 37,
  FIM: 38,
  PHARMACODE: 39,
};

export const timezones = {
  // '(GMT+00:00) Casablanca': '0',
  // '(GMT+00:00) Dublin': '0',
  // '(GMT+00:00) Lisbon': '0',
  '(GMT+00:00) London': '0',
  // '(GMT+00:00) Monrovia': '0',
  // '(GMT+01:00) Algiers': '1',
  // '(GMT+01:00) Amsterdam': '1',
  '(GMT+01:00) Berlin': '1',
  // '(GMT+01:00) Brussels': '1',
  // '(GMT+01:00) Budapest': '1',
  // '(GMT+01:00) Central European Time - Belgrade': '1',
  // '(GMT+01:00) Central European Time - Prague': '1',
  // '(GMT+01:00) Copenhagen': '1',
  // '(GMT+01:00) Madrid': '1',
  // '(GMT+01:00) Paris': '1',
  // '(GMT+01:00) Rome': '1',
  // '(GMT+01:00) Stockholm': '1',
  // '(GMT+01:00) Vienna': '1',
  // '(GMT+01:00) Warsaw': '1',
  // '(GMT+02:00) Athens': '2',
  // '(GMT+02:00) Bucharest': '2',
  // '(GMT+02:00) Cairo': '2',
  // '(GMT+02:00) Jerusalem': '2',
  // '(GMT+02:00) Johannesburg': '2',
  // '(GMT+02:00) Helsinki': '2',
  '(GMT+02:00) Kiev': '2',
  // '(GMT+02:00) Moscow-01 - Kaliningrad': '2',
  // '(GMT+02:00) Riga': '2',
  // '(GMT+02:00) Sofia': '2',
  // '(GMT+02:00) Tallinn': '2',
  // '(GMT+02:00) Vilnius': '2',
  // '(GMT+03:00) Istanbul': '3',
  // '(GMT+03:00) Baghdad': '3',
  // '(GMT+03:00) Nairobi': '3',
  '(GMT+03:00) Minsk': '3',
  // '(GMT+03:00) Riyadh': '3',
  // '(GMT+03:00) Moscow+00 - Moscow': '3',
  // '(GMT+04:00) Baku': '4',
  // '(GMT+04:00) Moscow+01 - Samara': '4',
  '(GMT+04:00) Tbilisi': '4',
  // '(GMT+04:00) Yerevan': '4',
  // '(GMT+05:00) Karachi': '5',
  '(GMT+05:00) Moscow+02 - Yekaterinburg': '5',
  // '(GMT+05:00) Tashkent': '5',
  '(GMT+06:00) Almaty': '6',
  // '(GMT+06:00) Dhaka': '6',
  '(GMT+07:00) Bangkok': '7',
  // '(GMT+07:00) Jakarta': '7',
  // '(GMT+07:00) Moscow+04 - Krasnoyarsk': '7',
  '(GMT+08:00) China Time - Beijing': '8',
  // '(GMT+08:00) Hong Kong': '8',
  // '(GMT+08:00) Kuala Lumpur': '8',
  // '(GMT+08:00) Moscow+05 - Irkutsk': '8',
  // '(GMT+08:00) Singapore': '8',
  // '(GMT+08:00) Ulaanbaatar': '8',
  // '(GMT+08:00) Western Time - Perth': '8',
  // '(GMT+09:00) Moscow+06 - Yakutsk': '9',
  // '(GMT+09:00) Seoul': '9',
  '(GMT+09:00) Tokyo': '9',
  '(GMT+10:00) Eastern Time - Brisbane': '10',
  // '(GMT+10:00) Guam': '10',
  // '(GMT+10:00) Moscow+07 - Magadan': '10',
  // '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk': '10',
  // '(GMT+10:00) Port Moresby': '10',
  // '(GMT+11:00) Eastern Time - Hobart': '11',
  '(GMT+11:00) Eastern Time - Melbourne, Sydney': '11',
  // '(GMT+11:00) Guadalcanal': '11',
  // '(GMT+11:00) Noumea': '11',
  // '(GMT+12:00) Majuro': '12',
  '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy': '12',
  // '(GMT+13:00) Auckland': '13',
  // '(GMT+13:00) Fakaofo': '13',
  '(GMT+13:00) Fiji': '13',
  // '(GMT+13:00) Tongatapu': '13',
  '(GMT+14:00) Apia': '14',
  '(GMT-11:00) Pago Pago': "-11'",
  '(GMT-10:00) Hawaii Time': '-10',
  '(GMT-08:00) Pacific Time': '-8',
  // '(GMT-08:00) Pacific Time - Tijuana': '-8',
  // '(GMT-07:00) Mountain Time': '-7',
  '(GMT-07:00) Mountain Time - Arizona': '-7',
  // '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan': '-7',
  '(GMT-06:00) Central Time': '-6',
  // '(GMT-06:00) Central Time - Mexico City': '-6',
  // '(GMT-06:00) Central Time - Regina': '-6',
  // '(GMT-06:00) Guatemala': '-6',
  // '(GMT-05:00) Bogota': '-5',
  '(GMT-05:00) Eastern Time': '-5',
  // '(GMT-05:00) Lima': '-5',
  '(GMT-04:00) Atlantic Time - Halifax': '-4',
  // '(GMT-04:00) Guyana': '-4',
  // '(GMT-04:00) La Paz': '-4',
  '(GMT-03:00) Buenos Aires': '-3',
  // '(GMT-03:00) Godthab': '-3',
  // '(GMT-03:00) Montevideo': '-3',
  // '(GMT-03:00) Santiago': '-3',
  '(GMT-02:00) Sao Paulo': '-2',
  // '(GMT-02:00) South Georgia': '-2',
  // '(GMT-01:00) Azores': '-1',
  '(GMT-01:00) Cape Verde': '-1',
};

export const trimmedTimezones = number =>
  trimOptions(
    Object.keys(timezones).map(key => {
      return {
        value: key,
        label: timezones[key],
      };
    }),
    'value',
    number
  );

const renderTypes = types => {
  const keys = Object.keys(types);
  return keys.map(key => ({
    label: key,
    value: types[key],
  }));
};

export const settingsFormats = {
  Text: {
    name: 'text',
  },
  Currency: {
    name: 'currency',
    symbols: [
      'EURO (€)',
      'DOLLAR ($)',
      'UAH (₴)',
      'RUB (₽)',
      'GBP (£)',
      'PLN (zł)',
    ],
    symbolPositions: [
      { label: 'in front', value: '0' },
      { label: 'after the number', value: '1' },
    ],
    decimalPlaces: ['1', '2', '3', '4', '5'],
  },
  Number: {
    name: 'number',
    decimalPlaces: ['1', '2', '3', '4', '5'],
  },
  Date: {
    name: 'date',
    types: [
      { date: format(new Date(), 'dd/MM'), format: 'dd/MM' },
      { date: format(new Date(), 'd/MM'), format: 'd/MM' },
      { date: format(new Date(), 'd-MM'), format: 'd-MM' },
      { date: format(new Date(), 'dd-MMM'), format: 'dd-MMM' },
      { date: format(new Date(), 'MM/dd/yy'), format: 'MM/dd/yy' },
      { date: format(new Date(), 'dd/MM/yy'), format: 'dd/MM/yy' },
      { date: format(new Date(), 'MM-dd-yy'), format: 'MM-dd-yy' },
      { date: format(new Date(), 'dd-MM-yy'), format: 'dd-MM-yy' },
      { date: format(new Date(), 'dd-MMM-yy'), format: 'dd-MMM-yy' },
      { date: format(new Date(), 'd-MM-yy'), format: 'd-MM-yy' },
    ],
    timezones: renderTypes(timezones),
  },
  Time: {
    name: 'time',
    types: [
      { time: format(new Date(), 'h:mm a'), format: 'h:mm a' },
      { time: format(new Date(), 'hh:mm'), format: 'hh:mm' },
      { time: format(new Date(), 'h:mm a'), format: 'h:mm a' },
      { time: format(new Date(), 'hh:mm:ss'), format: 'hh:mm:ss' },
      { time: format(new Date(), 'h:mm:ss a'), format: 'h:mm:ss a' },
      { time: format(new Date(), 'mm:ss:sss'), format: 'mm:ss:sss' },
      {
        time: format(new Date(), 'hh:mm:ss:sss'),
        format: 'hh:mm:ss:sss',
      },
      {
        time: format(new Date(), 'h:mm:ss:sss a'),
        format: 'h:mm:ss:sss a',
      },
    ],

    timezones: renderTypes(timezones),
  },
  Percentage: {
    name: 'percentage',
    decimalPlaces: ['1', '2', '3', '4', '5'],
  },
  Barcode: {
    name: 'barcode',
    'Bar height': [],
    Type: renderTypes(barcodeTypes),
    Resolution: [],
    'Scale factor': [],
    'Quiet zone (H)': [],
  },
  Image: {
    name: 'image',
  },
};
