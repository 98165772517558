import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchStatistics } from '../../store/statistics';
import { DashboardLayout } from '../../components/Dashboard/DashboardLayout';
import requests from '../../assets/svgs/dashboard/requests.svg';
import total from '../../assets/svgs/dashboard/total.svg';
import midgets from '../../assets/svgs/dashboard/midgets.svg';

import './style.scss';

class Dashboard extends Component {
  componentDidMount() {
    this.props.fetchStatistics();
  }
  render() {
    const { userData, statistics_data } = this.props;
    const Data = [
      {
        title: 'Requests last month',
        total: statistics_data && statistics_data.requests,
        color: 'green',
        img: midgets,
      },
      {
        title: 'Total Fuses',
        total: statistics_data && statistics_data.fuses,
        color: 'orange',
        img: total,
      },
      {
        title: 'Total Users',
        total: statistics_data && statistics_data.users,
        color: 'blue',
        img: requests,
      },
    ];
    return (
      <DashboardLayout>
        <section className="dashboard-container">
          <h1 className="title">
            Welcome to Webfuse, {userData && userData.firstName}
          </h1>
          <div className="box-wrapper">
            {Data.map((item, index) => {
              return (
                <div key={index} className={`content-box ${item.color}`}>
                  <div className="img-box">
                    <img src={item.img} alt="icons" />
                    <p className="total-number">{item.total}</p>
                  </div>
                  <p className="total-title">{item.title}</p>
                </div>
              );
            })}
          </div>
          <div className="bottom-text">
            <p>
              Want to know more about Webfuse? Visit our{' '}
              <a
                href="https://www.webfuse.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                website
              </a>
              .
            </p>
            <p>
              For all your questions, check out our{' '}
              <a
                href="https://webfuse.freshdesk.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                support site
              </a>
              .
            </p>
          </div>
        </section>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = store => {
  return {
    userData: store.profile.userData,
    statistics_data: store.statistics.statistics_data,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchStatistics: () => dispatch(fetchStatistics()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
