import React from 'react';

import theme from './styles.module.scss';

export const AddConditionBtn = ({ onClick }) => (
  <button className={theme.button} type="button" onClick={onClick}>
    <i className="fas fa-plus" />
    Add condition
  </button>
);
