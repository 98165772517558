import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import StyledButton from '../../common/StyledButton';
import {
  fetchEntityData,
  fetchLabelData,
  fetchCurrentLabelInfo,
} from '../../../store/references';
import AutoComplete from '../AutoComplete';

import './style.scss';

class SelectValueField extends Component {
  render() {
    const {
      isOpened,
      closeModal,
      setFieldValue,
      connectors_entity,
      entity_label,
      account_data,
      fetchLabelData,
      fetchCurrentLabelInfo,
      connectorId,
      name,
    } = this.props;

    return (
      <Modal isOpen={isOpened} title="Add reference">
        <div className="add-reference">
          <header>
            <h1>Add a Reference</h1>
            <p>
              Add a Reference to your Fuse using Dynamics 365 data. Select the
              primary entity, the record and the fields that correspond to the
              values of the record you wish to display. Use the pane on the
              right side in the HTML editor to finalize your settings.
            </p>
          </header>
          <Formik
            initialValues={{
              entity: '',
              recordId: '',
              field: '',
            }}
            enableReinitialize={true}
            onSubmit={values => {
              setFieldValue(name + '.reference', values);
              setFieldValue(name + '.type', 2);
              setFieldValue(name + '.value', '');
              closeModal();
            }}
            render={({ handleSubmit, setFieldValue, values }) => {
              return (
                <form>
                  <AutoComplete
                    label="Dynamics 365 entity"
                    name="entity"
                    suggestions={connectors_entity.map(({ entity, label }) => ({
                      label,
                      value: entity,
                      entity,
                    }))}
                    connectorId={connectorId}
                    onChangeAutoComplete={fetchLabelData}
                    setFieldFormik={setFieldValue}
                    valueFormik={values.entity}
                  />
                  <AutoComplete
                    label="Record"
                    name="recordId"
                    suggestions={entity_label.map(({ label, id }) => ({
                      value: id,
                      label,
                      entity: values.entity,
                      id: id,
                    }))}
                    connectorId={connectorId}
                    onChangeAutoComplete={fetchCurrentLabelInfo}
                    setFieldFormik={setFieldValue}
                    valueFormik={values.recordId}
                  />
                  <AutoComplete
                    label="Field"
                    name="field"
                    suggestions={account_data.map(
                      ({ label, field, type, value }) => ({
                        label,
                        value: field,
                        entity: field,
                      })
                    )}
                    setFieldFormik={setFieldValue}
                    valueFormik={values.field}
                  />
                  <footer>
                    <StyledButton
                      type="button"
                      action={closeModal}
                      width="auto"
                      variant="cancel"
                    >
                      Cancel
                    </StyledButton>
                    <StyledButton
                      type="button"
                      action={handleSubmit}
                      width="auto"
                      disabled={Object.values(values).some(
                        value => value === ''
                      )}
                    >
                      Add
                    </StyledButton>
                  </footer>
                </form>
              );
            }}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = store => {
  return {
    connectors_entity: store.references.connectors_entity,
    entity_label: store.references.entity_label,
    account_data: store.references.account_data,
    connectorId: store.fuses.selectedConnectorId,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchEntityData: connectorId => dispatch(fetchEntityData(connectorId)),
  fetchLabelData: (connectorId, entity) =>
    dispatch(fetchLabelData(connectorId, entity)),
  fetchCurrentLabelInfo: (connectorId, entity, id) =>
    dispatch(fetchCurrentLabelInfo(connectorId, entity, id)),
});

SelectValueField.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  connectorId: PropTypes.number,
  fetchCurrentLabelInfo: PropTypes.func.isRequired,
  entity_label: PropTypes.array,
  connectors_entity: PropTypes.array,
  fetchLabelData: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectValueField);
