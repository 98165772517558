import Immutable from 'seamless-immutable';

import { baseRequest } from '../helpers/base-request';
import { isString } from '../helpers/isString';

const types = {
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',

  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR: 'FETCH_USERS_ERROR',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',

  SEND_INVITE_REQUEST: 'SEND_INVITE_REQUEST',
  SEND_INVITE_SUCCESS: 'SEND_INVITE_SUCCESS',
  SEND_INVITE_ERROR: 'SEND_INVITE_ERROR',

  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
};

const initialState = Immutable({
  error: null,
  users: [],
  user_id: null,
  loading: false,
  showNotification: false,
  notificationVariant: '',
  notificationMessage: '',
});

const sendInviteLinkRequest = () => ({
  type: types.SEND_INVITE_REQUEST,
});

const createUserRequest = () => ({
  type: types.CREATE_USER_REQUEST,
});

const fetchUsersRequest = () => ({
  type: types.FETCH_USERS_REQUEST,
});

const deleteUserRequest = () => ({
  type: types.DELETE_USER_REQUEST,
});

export const sendInviteLink = selectedUsersId => {
  const success = response => {
    return { type: types.SEND_INVITE_SUCCESS, payload: response.data };
  };

  const failure = error => ({
    type: types.SEND_INVITE_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(sendInviteLinkRequest());
    return selectedUsersId.forEach(userId => {
      baseRequest('GET', `/api/Users/${userId}/invite`)
        .then(response => dispatch(success(response)))
        .catch(error => dispatch(failure(error)));
    });
  };
};

export const createUser = (data, link = false) => {
  const success = response => ({
    type: types.CREATE_USER_SUCCESS,
    payload: response,
    link,
  });

  const failure = error => ({
    type: types.CREATE_USER_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(createUserRequest());
    return baseRequest('POST', '/api/Users', data)
      .then(response => {
        link && navigator.clipboard.writeText(response.data.invitationLink);
        return dispatch(success(response));
      })
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchUsers = () => {
  const success = data => ({
    type: types.FETCH_USERS_SUCCESS,
    payload: data,
  });

  const failure = error => ({
    type: types.FETCH_USERS_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchUsersRequest());
    return baseRequest('GET', '/api/Users')
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const removeUsers = users => {
  const success = (response, id) => {
    return {
      type: types.DELETE_USER_SUCCESS,
      user_id: id,
    };
  };

  const failure = error => ({
    type: types.DELETE_USER_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(deleteUserRequest());
    return users.forEach(id =>
      baseRequest('DELETE', `/api/Users/${id}`)
        .then(response => dispatch(success(response, id)))
        .catch(error => dispatch(failure(error)))
    );
  };
};

export const resetNotifications = () => ({
  type: types.RESET_NOTIFICATIONS,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.data],
        success_create_user: true,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: action.link
          ? 'User was successfully created. Please send the copied invite link to your colleague. '
          : 'Invite was sent, your colleague will receive a verification mail soon to activate his account.',
      };

    case types.CREATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        loading: false,
      };

    case types.FETCH_USERS_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.DELETE_USER_SUCCESS:
      const filteredUsers = state.users.filter(
        user => user.id !== action.user_id
      );
      return {
        ...state,
        users: filteredUsers,
        user_id: null,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage: 'User was successfully deleted',
      };

    case types.DELETE_USER_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.SEND_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SEND_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'success',
        notificationMessage:
          'Invite was sent, please check your e-mail address to activate.',
      };

    case types.SEND_INVITE_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.RESET_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        showNotification: false,
        notificationVariant: '',
        notificationMessage: '',
      };

    default:
      return state;
  }
}
