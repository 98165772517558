import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import authorization from './authorization';
import connectors from './connectors';
import users from './users';
import profile from './profile';
import errors from './commonReducers/errors';
import references from './references';
import fuses from './fuses';
import loops from './loops';
import form from './form';
import statistics from './statistics';

const reducers = combineReducers({
  authorization,
  users,
  connectors,
  profile,
  errors,
  references,
  fuses,
  loops,
  form,
  statistics,
});

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);
