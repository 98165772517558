import React from 'react';
import { Button, Icon } from './ToolbarControls';

const hasLinks = value => {
  return value.inlines.some(inline => inline.type === 'link');
};

export const LinkButton = ({ editor, value, className, icon }) => {
  const isActive = hasLinks(value);

  return (
    <Button
      active={isActive}
      onMouseDown={event => onClickLink({ event, editor, value, className })}
    >
      <Icon>link</Icon>
    </Button>
  );
};

const wrapLink = (editor, href) => {
  editor.wrapInline({
    type: 'a',
    data: { href },
  });

  editor.moveToEnd();
};

const unwrapLink = editor => {
  editor.unwrapInline('a');
};

const onClickLink = ({ event, editor, value, className }) => {
  event.preventDefault();

  const isActive = hasLinks(value);

  if (isActive) {
    editor.command(unwrapLink);
  } else if (value.selection.isExpanded) {
    const href = window.prompt('Enter the URL of the link:');

    if (href == null) {
      return;
    }

    editor.command(wrapLink, href);
  } else {
    const href = window.prompt('Enter the URL of the link:');

    if (href == null) {
      return;
    }

    const text = window.prompt('Enter the text for the link:');

    if (text == null) {
      return;
    }

    editor
      .insertText(text)
      .moveFocusBackward(text.length)
      .command(wrapLink, href);
  }
};
