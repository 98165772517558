import DeepTable from 'slate-deep-table';
import TrailingBlock from '@wikifactory/slate-trailing-block';

const opts = {
  typeRow: 'tr',
  typeTable: 'table',
  typeCell: 'td',
  typeContent: 'p',
};

export const plugins = [DeepTable(opts), TrailingBlock({ type: 'p' })];
