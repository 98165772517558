import React, { Component } from 'react';
import { SlateEditor } from '../../components/Fuses';
import { DashboardLayout } from '../../components/Dashboard/DashboardLayout';

class EditFuse extends Component {
  render() {
    return (
      <DashboardLayout>
        <section className="edit-fuses-container">
          <div className="editor-container">
            <SlateEditor />
          </div>
        </section>
      </DashboardLayout>
    );
  }
}

export default EditFuse;
