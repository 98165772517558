import React from 'react';

import { DashboardLayout } from '../components/Dashboard/DashboardLayout';

export const Admin = () => (
  <DashboardLayout>
    <div>
      <p>ADMIN TAB</p>
    </div>
  </DashboardLayout>
);
