import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { resetNotifications } from '../../store/fuses';
import { DashboardLayout } from '../../components/Dashboard/DashboardLayout';
import FuseTable from '../../components/Fuses/FuseTable';
import SnackBar from '../../components/Snackbar/';
import theme from './styles.module.scss';

class Fuses extends Component {
  render() {
    const {
      resetNotifications,
      showNotification,
      notificationVariant,
      notificationMessage,
    } = this.props;
    return (
      <>
        <DashboardLayout>
          <section className={theme.fusesContainer}>
            <FuseTable />
          </section>
        </DashboardLayout>
        <SnackBar
          open={showNotification}
          variant={notificationVariant}
          message={notificationMessage}
          close={resetNotifications}
        />
      </>
    );
  }
}

const mapStateToProps = store => ({
  showNotification: store.fuses.showNotification,
  notificationVariant: store.fuses.notificationVariant,
  notificationMessage: store.fuses.notificationMessage,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ resetNotifications }, dispatch);

Fuses.propTypes = {
  loading: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fuses);
