import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { history } from './history';

const getErrorMessage = error => {
  if (error.response.data.title) {
    return error.response.data.title;
  } else if (
    error.response.data.error.length &&
    error.response.data.error.length <= 200
  ) {
    return error.response.data.error;
  } else
    return 'Oops, something went wrong. Please try again later or contact Webfuse support at support@webfuse.io.';
};

function authHeader() {
  const token = JSON.parse(localStorage.getItem('token'));

  if (token) {
    return `Bearer ${token.token}`;
  } else {
    return '';
  }
}

const request = axios.create({
  timeout: 1000000,
});

export const baseRequest = (method, api, data, params) => {
  return request({
    method: method,
    url: `${process.env.REACT_APP_API_URL}${api}`,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: authHeader(),
    },
    params: params,
  });
};

const refreshAuthLogic = failedRequest => {
  const token = JSON.parse(localStorage.getItem('token')).refresh_token;

  return baseRequest('POST', '/api/Account/token/refresh', {
    refreshToken: token,
  })
    .then(tokenRefreshResponse => {
      localStorage.setItem(
        'token',
        JSON.stringify({
          token: tokenRefreshResponse.data.access_token,
          refresh_token: tokenRefreshResponse.data.refresh_token,
        })
      );
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + tokenRefreshResponse.data.access_token;
      return Promise.resolve();
    })
    .catch(() => {
      localStorage.removeItem('token');
      history.push('/login');
      return Promise.reject();
    });
};

createAuthRefreshInterceptor(request, refreshAuthLogic);

request.interceptors.request.use(
  config => {
    // console.log('request: ', config);
    return config;
  },
  error => Promise.reject(error)
);

request.interceptors.response.use(
  response => {
    // console.log('response: ', response);
    return response;
  },
  error => {
    // console.log('error ', error.response);
    if (!error.response) {
      // console.log('Network Error!');
    }

    // if (error && error.response && error.response.status === 401) {
    //   console.log('Unauthorized');
    // }

    // if (error && error.response && error.response.status >= 500) {
    //   Promise.reject('Internal Server Error');
    // }

    return Promise.reject(getErrorMessage(error));
  }
);
