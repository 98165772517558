import dashboard from './dashboard.svg';
import fuses from './fuses.svg';
import activity from './activity.svg';
import administration from './administration.svg';
import connectors from './connectors.svg';
import users from './users.svg';
import account from './account.svg';
import dollar from './dollar.svg';

export default {
  users,
  activity,
  dashboard,
  account,
  fuses,
  administration,
  connectors,
  dollar,
};
