import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: 50,
    width: '90%',
    margin: '0 auto',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    borderLeft: '5px solid #d5dbdc',
    backgroundColor: '#eef0f2',
    borderRadius: 5,
    padding: 0,
    height: 35,
    '& :before': {
      display: 'none',
    },
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

export const customStylesSuccess = {
  container: () => ({
    margin: '10px 0 15px 0',
  }),
  menu: () => ({
    position: 'relative',
    backgroundColor: '#eef0f2',
    height: 'auto',
    borderLeft: '0',
    marginTop: '5px',
    borderRadius: '5px',
  }),
  indicatorSeparator: () => ({
    width: '0',
  }),
  control: () => ({
    boxSizing: 'border-box',
    color: '#4d565a',
    minHeight: '35px',
    borderRadius: '5px',
    width: '100%',
    height: '35px',
    outline: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Ubuntu, sans-serif',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    borderLeft: '5px solid #22b9ad',
    backgroundColor: '#eef0f2',
    marginTop: '5px',
  }),
};

export const customStyles = {
  container: () => ({
    margin: '10px 0 15px 0',
  }),
  menu: () => ({
    position: 'relative',
    backgroundColor: '#eef0f2',
    height: 'auto',
    borderLeft: '0',
    marginTop: '5px',
    borderRadius: '5px',
  }),
  indicatorSeparator: () => ({
    width: '0',
  }),
  control: () => ({
    boxSizing: 'border-box',
    color: '#4d565a',
    minHeight: '35px',
    borderRadius: '5px',
    height: '35px',
    outline: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Ubuntu, sans-serif',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    borderLeft: '5px solid #d5dbdc',
    backgroundColor: '#eef0f2',
    marginTop: '5px',
  }),
};
