export const getDeletedItems = (data, selectedItems, selector) => {
  return data
    .filter((connector, index) => {
      const selected = selectedItems.find(selected => {
        if (selected === connector.id) {
          return connector;
        }
        return false;
      });
      if (selected === connector.id) {
        return connector;
      }
      return false;
    })
    .map(selected => selected[selector]);
};
