import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Checkbox, Button } from '@material-ui/core';
import clsx from 'clsx';

import { StyledButton, ConfirmModal } from '../../common/';
import TableComponent from '../../Table';
import { EmptyTable } from '../../Table/EmptyTable';
import { StyledTableRow, StyledTableCell } from '../../Table/style';
import CreateFuseModal from '../CreateFuseModal';
import { headRows, icons } from './Data';
import { getDeletedItems } from '../../../helpers/getDeletedItems';
import SnippetModal from '../SnippetModal/';
import {
  fetchFuses,
  removeFuses,
  addSelectedConnectorId,
  addSelectedFuseId,
  fetchSnippet,
} from '../../../store/fuses';

import '../../../pages/Connectors/style.scss';
import noFuses from '../../../assets/svgs/noFuses.svg';
import theme from './styles.module.scss';

import orderBy from 'lodash/orderBy';

import { invertDirection } from '../../../helpers/invertDirection';

class FuseTable extends Component {
  state = {
    isOpened: false,
    page: 0,
    rowsPerPage: 5,
    selectedFuses: [],
    showConfirmModal: false,
    columnToSort: '',
    sortDirection: '',
    showSnippetModal: false,
  };

  componentDidMount() {
    this.props.fetchFuses();
  }

  handleOpenModal = () => {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened,
    }));
  };

  handleConfirmModal = () => {
    this.setState(({ showConfirmModal }) => ({
      showConfirmModal: !showConfirmModal,
    }));
  };

  openSnippetModal = async () => {
    await this.props.fetchSnippet(this.state.selectedFuses[0]);
    await this.setState({
      showSnippetModal: true,
    });
  };

  closeSnippetModal = async () => {
    await this.setState({
      showSnippetModal: false,
    });
  };

  handleSelectFuse = (event, id) => {
    const { selectedFuses } = this.state;
    const selectedIndex = selectedFuses.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedFuses, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedFuses.slice(1));
    } else if (selectedIndex === selectedFuses.length - 1) {
      newSelected = newSelected.concat(selectedFuses.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedFuses.slice(0, selectedIndex),
        selectedFuses.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedFuses: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  handleRemoveFuses = () => {
    const { selectedFuses } = this.state;
    this.props.removeFuses(selectedFuses);
    this.handleConfirmModal();
    this.setState({ selectedFuses: [] });
  };

  handleSelectAllClick = () => {
    const { fuses } = this.props;
    const { selectedFuses } = this.state;
    if (selectedFuses.length === 0) {
      const selectAll = fuses.map(fuse => fuse.id);
      return this.setState({ selectedFuses: selectAll });
    }
    this.setState({ selectedFuses: [] });
  };

  handleDisableRemoveButton = () => {
    return this.state.selectedFuses.length > 0 ? false : true;
  };

  handleDisableEditButton = () => {
    return this.state.selectedFuses.length === 1 ? false : true;
  };

  handleClickEdit = () => {
    const { selectedFuses } = this.state;
    const {
      addSelectedConnectorId,
      addSelectedFuseId,
      fuses,
      history,
    } = this.props;
    addSelectedConnectorId(
      fuses.find(fuse => fuse.id === selectedFuses[0]).connectorId
    );
    addSelectedFuseId(fuses.find(fuse => fuse.id === selectedFuses[0]).id);
    history.push(`/fuses/${selectedFuses[0]}/edit`);
  };

  handleSort = columnName => {
    this.setState(prevState => ({
      columnToSort: columnName,
      sortDirection:
        prevState.columnToSort === columnName
          ? invertDirection[prevState.sortDirection]
          : 'asc',
    }));
  };

  render() {
    const {
      isOpened,
      selectedFuses,
      rowsPerPage,
      page,
      showConfirmModal,
      columnToSort,
      sortDirection,
      showSnippetModal,
    } = this.state;

    const { fuses, snippet, loading } = this.props;

    const isSelected = id => selectedFuses.indexOf(id) !== -1;
    return (
      <section className="table-wrapper">
        <p className="title-text">
          Fuses give you the power to capture and embed data from and into
          Dynamics 365.
          <br />
          Combine that data with your website`s content, using the HTML editor.
        </p>
        <div>
          <StyledButton action={this.handleOpenModal} width="auto">
            + Create new Fuse
          </StyledButton>
        </div>
        {fuses.length > 0 ? (
          <TableComponent
            headRows={headRows}
            selectedLength={selectedFuses.length}
            totalLength={fuses ? fuses.length : 0}
            handleSelectAllClick={this.handleSelectAllClick}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            handleSort={this.handleSort}
            columnToSort={columnToSort}
            sortDirection={sortDirection}
            headNav={
              <div className={theme.navTabs}>
                <Button
                  className="head_nav"
                  onClick={this.openSnippetModal}
                  disabled={this.handleDisableEditButton()}
                >
                  <i
                    className={clsx('fas fa-cog', 'head_nav_icon')}
                    aria-hidden="true"
                  ></i>
                  Settings
                </Button>
                <Button
                  className="head_nav"
                  onClick={this.handleClickEdit}
                  disabled={this.handleDisableEditButton()}
                >
                  <img src={icons.edit} alt="nav" className="head_nav_icon" />
                  Edit
                </Button>
                <Button
                  className="head_nav"
                  onClick={() => this.setState({ showConfirmModal: true })}
                  disabled={this.handleDisableRemoveButton()}
                >
                  <img src={icons.cross} alt="nav" className="head_nav_icon" />
                  Remove
                </Button>
              </div>
            }
          >
            {fuses &&
              orderBy(fuses, columnToSort, sortDirection)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(fuses => {
                  const isFuseSelected = isSelected(fuses.id);
                  return (
                    <StyledTableRow
                      hover
                      key={fuses.id}
                      onClick={async event => {
                        if (isFuseSelected) {
                          await this.handleClickEdit();
                        } else {
                          await this.handleSelectFuse(event, fuses.id);
                          await this.handleClickEdit();
                        }
                      }}
                      role="checkbox"
                      selected={isFuseSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          name="fuseCheckbox"
                          color="default"
                          checked={isFuseSelected}
                          onClick={event => {
                            event.stopPropagation();
                            this.handleSelectFuse(event, fuses.id);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {fuses.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {fuses.connectorName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {fuses.userName}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
          </TableComponent>
        ) : !loading ? (
          <EmptyTable
            backGroundImg={{
              backgroundImage: `url(${noFuses})`,
            }}
            title={'You don`t have any Fuses yet!'}
            variant={'fuses'}
          />
        ) : null}
        <CreateFuseModal
          isOpened={isOpened}
          handleOpenModal={this.handleOpenModal}
        />
        <ConfirmModal
          open={showConfirmModal}
          header={
            selectedFuses &&
            getDeletedItems(fuses, selectedFuses, 'name').length > 1
              ? 'Remove fuses'
              : 'Remove fuse'
          }
          text={
            selectedFuses &&
            getDeletedItems(fuses, selectedFuses, 'name').length > 1
              ? 'Are you sure, you want to remove fuses '
              : 'Are you sure, you want to remove fuse '
          }
          deletedItems={getDeletedItems(fuses, selectedFuses, 'name')}
          action={this.handleRemoveFuses}
          handleClose={this.handleConfirmModal}
        />
        <SnippetModal
          showSnippetModal={showSnippetModal}
          closeSnippetModal={this.closeSnippetModal}
          snippet={snippet}
          fuseId={selectedFuses[0]}
        />
      </section>
    );
  }
}

const mapStateToProps = store => {
  return {
    fuses: store.fuses.fuses,
    snippet: store.fuses.snippet,
    loading: store.fuses.loading,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFuses,
      removeFuses,
      addSelectedConnectorId,
      addSelectedFuseId,
      fetchSnippet,
    },
    dispatch
  );

FuseTable.propTypes = {
  fuses: PropTypes.array.isRequired,
  snippet: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FuseTable));
