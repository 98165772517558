import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import uuidv4 from 'uuid/v4';

import Modal from '../../Modal';
import { StyledButton } from '../../common';
import { ConditionModal } from '../../common/ConditionModal/ConditionModal';
import SelectFieldToCondition from './SelectFieldToCondition';
import SelectValueField from '../LoopModal/SelectValueField';
import theme from './styles.module.scss';

const initialCurrentCondition = {
  conditionType: 'compare',
  field: {
    field: '',
    entity: '',
    recordId: '',
  },
  format: '1',
  type: '',
  value1: { type: 1, value: '', reference: {} },
  value2: { type: 1, value: '', reference: {} },
};

class FilterModal extends Component {
  state = {
    isShowModalCondition: false,
    isShowModalField: false,
    showReferenceModal: false,
    isValue1Open: false,
    isValue2Open: false,
  };

  showModalCondition = () => {
    this.setState({
      isShowModalCondition: true,
    });
  };

  hideModalCondition = setFieldValue => {
    this.setState({
      isShowModalCondition: false,
    });
    setFieldValue('currentCondition', initialCurrentCondition);
  };

  showModalField = () => {
    this.setState({
      isShowModalField: true,
    });
  };

  hideModalField = () => {
    this.setState({
      isShowModalField: false,
    });
  };

  setSelectedFieldFilter = (e, setFieldValue) => {
    const targetEl = e.target.innerHTML;
    setFieldValue('currentCondition.dynamicsEntity', targetEl);
    this.setState({ selectedField: targetEl });
  };

  hideReferenceModal = () => {
    this.setState({ showReferenceModal: false });
  };

  showReferenceModal = () => {
    this.setState({ showReferenceModal: true });
  };

  openSelectValue1 = () => {
    this.setState({
      isValue1Open: true,
    });
  };

  closeSelectValue1 = () => {
    this.setState({
      isValue1Open: false,
    });
  };

  openSelectValue2 = () => {
    this.setState({
      isValue2Open: true,
    });
  };

  closeSelectValue2 = () => {
    this.setState({
      isValue2Open: false,
    });
  };

  render() {
    const {
      isOpened,
      toggleFilterModal,
      handleSubmitFilterModal,
      setReferenceRecordId,
    } = this.props;

    const { isShowModalCondition, isValue1Open, isValue2Open } = this.state;

    return (
      <Modal title="Loop modal" isOpen={isOpened}>
        <section className={theme.modal}>
          <header>
            <h1>Add a Condition</h1>
            <p>
              Add a Condition to your Filter to display or hide selected
              content. You can compare with a value or check whether a field
              occurs. This also supports Dynamics 365 or Dataverse data.
            </p>
          </header>
          <main>
            <Formik
              initialValues={{
                id: `condition-${uuidv4()}`,
                name: '',
                currentCondition: initialCurrentCondition,
                conditions: [],
              }}
              onSubmit={values => {
                const valuesToSent = { ...values };
                delete valuesToSent.currentCondition;
                handleSubmitFilterModal(valuesToSent);
              }}
              render={({ setFieldValue, values, handleChange }) => {
                const {
                  conditionType,
                  field,
                  format,
                  type,
                  value1,
                  value2,
                } = values.currentCondition;
                const isDisabledCondition = !(conditionType === 'compare'
                  ? field.entity &&
                    field.field &&
                    field.recordId &&
                    format &&
                    type &&
                    (value1.value || Object.keys(value1.reference).length) &&
                    (type === 10
                      ? value2.value || Object.keys(value2.reference).length
                      : true)
                  : field.entity && field.field && field.recordId);
                return (
                  <Form>
                    <label htmlFor="filter-name" className={theme.label}>
                      Name
                    </label>
                    <Field
                      value={values.name}
                      type="text"
                      name="name"
                      className={theme.input}
                      placeholder="Enter name"
                    />

                    <ConditionModal
                      isOpen={isShowModalCondition}
                      fieldToCompareOnClick={this.showReferenceModal}
                      value1OnClick={this.openSelectValue1}
                      value2OnClick={this.openSelectValue2}
                      values={values}
                      setFieldValue={setFieldValue}
                      hideModalCondition={() =>
                        this.hideModalCondition(setFieldValue)
                      }
                      showModalCondition={this.showModalCondition}
                      fieldToCompareName="currentCondition.field.field"
                      fieldToCompareValue={field.field}
                      isDisabledCondition={isDisabledCondition}
                      initialCurrentCondition={initialCurrentCondition}
                    />

                    <SelectFieldToCondition
                      showReferenceModal={this.state.showReferenceModal}
                      hideReferenceModal={this.hideReferenceModal}
                      values={values}
                      setFieldValue={setFieldValue}
                      setReferenceRecordId={setReferenceRecordId}
                    />

                    <SelectValueField
                      isOpened={isValue1Open}
                      closeModal={this.closeSelectValue1}
                      setFieldValue={setFieldValue}
                      name="currentCondition.value1"
                    />
                    <SelectValueField
                      isOpened={isValue2Open}
                      closeModal={this.closeSelectValue2}
                      setFieldValue={setFieldValue}
                      name="currentCondition.value2"
                    />
                    <footer>
                      <StyledButton
                        type="button"
                        action={toggleFilterModal}
                        variant="cancel"
                      >
                        Cancel
                      </StyledButton>
                      <StyledButton
                        type="submit"
                        disabled={!(values.name && values.conditions.length)}
                      >
                        Add
                      </StyledButton>
                    </footer>
                  </Form>
                );
              }}
            />
          </main>
        </section>
      </Modal>
    );
  }
}

FilterModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  toggleFilterModal: PropTypes.func.isRequired,
  handleSubmitFilterModal: PropTypes.func.isRequired,
};

export default FilterModal;
