import React from 'react';
import { Switch, Router, Route } from 'react-router-dom';

import { history } from './app/helpers/history';
import { PrivateRoute, AuthorizedRoute } from './app/components';

import SignIn from './app/pages/SignIn';
import SignUp from './app/pages/SignUp';
import Dashboard from './app/pages/Dashboard';
import { Activity } from './app/pages/Activity';
import Profile from './app/pages/Profile';
import { Admin } from './app/pages/Admin';
import Connectors from './app/pages/Connectors';
import Users from './app/pages/Users';
import Order from './app/pages/Order';
import Account from './app/pages/Account/';
import Fuses from './app/pages/Fuses';
import SetPassword from './app/pages/SetPassword';
import EditFuse from './app/pages/EditFuse';
import ResetPassword from './app/pages/ResetPassword';
import ForgotPassword from './app/pages/ForgotPassword';

export const App = () => (
  <>
    <Router history={history}>
      <Switch>
        <AuthorizedRoute path="/login" component={SignIn} />
        <AuthorizedRoute path="/registration" component={SignUp} />
        <AuthorizedRoute path="/reset-password" component={ResetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/setpassword" component={SetPassword} />

        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/activity" component={Activity} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute exact path="/admin" component={Admin} />
        <PrivateRoute path="/admin/connectors" component={Connectors} />
        <PrivateRoute path="/admin/users" component={Users} />
        <PrivateRoute path="/admin/order" component={Order} />
        <PrivateRoute path="/admin/account" component={Account} />
        <PrivateRoute exact path="/fuses" component={Fuses} />
        <PrivateRoute path="/fuses/:id/edit" component={EditFuse} />

        <PrivateRoute path="/" component={Dashboard} />
      </Switch>
    </Router>
  </>
);
