import Immutable from 'seamless-immutable';
import { baseRequest } from '../helpers/base-request';
import { history } from '../helpers/history';

import { isString } from '../helpers/isString';

const types = {
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
};

export const resetNotifications = () => ({
  type: types.RESET_NOTIFICATIONS,
});

const registerRequest = () => ({
  type: types.REGISTER_REQUEST,
});

const loginRequest = () => ({
  type: types.LOGIN_REQUEST,
});

const initialState = Immutable({
  user: null,
  isLoggedIn: false,
  isLoaded: false,
  token: null,
  loading: false,
  showNotification: false,
  notificationVariant: '',
  notificationMessage: '',
});

export const authUser = ({ firstName, lastName, company, email, password }) => {
  const success = () => {
    history.push('/login');
    return {
      type: types.REGISTER_SUCCESS,
    };
  };

  const failure = error => {
    return {
      type: types.REGISTER_ERROR,
      payload: isString(error),
    };
  };

  return dispatch => {
    dispatch(registerRequest());
    return setTimeout(() => {
      baseRequest('POST', '/api/Account/register', {
        firstName: firstName,
        lastName: lastName,
        company: company,
        email: email,
        password: password,
      })
        .then(() => dispatch(success()))
        .catch(error => dispatch(failure(error)));
    }, 1000);
  };
};

export const loginUser = ({ email, password }) => {
  const success = token => {
    localStorage.setItem(
      'token',
      JSON.stringify({
        token: token.data.access_token,
        refresh_token: token.data.refresh_token,
      })
    );
    history.push('/dashboard');

    return {
      type: types.LOGIN_SUCCESS,
    };
  };

  const failure = error => {
    return {
      type: types.LOGIN_ERROR,
      payload: isString(error),
    };
  };

  return dispatch => {
    dispatch(loginRequest());
    return setTimeout(() => {
      return baseRequest('POST', '/api/Account/token', { email, password })
        .then(response => {
          dispatch(success(response));
        })
        .catch(error => {
          dispatch(failure(error));
        });
    }, 1000);
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.REGISTER_SUCCESS: {
      return Immutable.merge(state, {
        ...action.authData,
        isLoggedIn: true,
        loading: false,
        showNotification: true,
        notificationVariant: 'warning',
        notificationMessage: 'Please check your e-mail to finalize registration',
      });
    }

    case types.REGISTER_ERROR: {
      return Immutable.merge(state, {
        isLoggedIn: false,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      });
    }

    case types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.LOGIN_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.RESET_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        showNotification: false,
        notificationVariant: '',
        notificationMessage: '',
      };

    default:
      return state;
  }
}
