import React from 'react';
import clsx from 'clsx';

import { FontFamilyList } from './RenderComponents/FontList';

export const rules = [{ deserialize, serialize }];

export const rawRules = [{ deserialize, serialize: serialize2 }];

const getAllInputsInForm = obj => [
  ...obj.data.get('inputs'),
  ...(obj.data.get('relatedForms')
    ? obj.data
        .get('relatedForms')
        .map(item => item.inputs)
        .flat()
    : []),
];

function deserialize(el, next) {
  const type = el.tagName.toLowerCase();
  switch (type) {
    case 'div':
      return {
        object: 'block',
        type: type,
        data: {
          className: el.getAttribute('class'),
        },
        nodes: next(el.childNodes),
      };

    case 'p':
    case 'ul':
    case 'ol':
    case 'li':
    case 'blockquote':
    case 'h1':
    case 'h2':
      return {
        object: 'block',
        type: type,
        nodes: next(el.childNodes),
      };

    case 'wf-condition':
    case 'wf-loop':
    case 'wf-loop-item':
    case 'wf-loop-table':
    case 'wf-loop-table-headers':
    case 'wf-loop-table-nested':
    case 'wf-loop-table-item':
      return {
        object: 'block',
        type: type,
        nodes: next(el.childNodes),
        data: {
          id: el.id,
          name: (el.dataset.name || '').split(','),
        },
      };

    case 'wf-form':
      return {
        object: 'block',
        type: type,
        nodes: next(el.childNodes),
        data: {
          id: el.id,
          name: (el.dataset.name || '').split(','),
          inputs: Array.from(el.querySelectorAll(':scope > wf-input')).map(
            child => ({
              label: child.dataset.label,
              customClass: child.dataset.customClass,
              field: child.dataset.field,
              length: Number(child.dataset.length),
              requiredField: child.dataset.requiredField === 'true',
              options: JSON.parse(child.dataset.options),
              type: child.dataset.type,
              relatedProperty: child.dataset.relatedProperty,
              value: child.dataset.value,
            })
          ),
          submit: el.dataset.submit,
        },
      };
    case 'wf-input':
      return;

    case 'wf-reference':
    case 'wf-image-reference':
    case 'wf-field':
    case 'wf-field-table':
      return {
        object: 'inline',
        type: type,
        nodes: next(el.childNodes),
        data: {
          id: el.id,
          name: el.textContent,
          format: el.dataset.format,
        },
      };

    case 'span':
    case 'b':
    case 'i':
    case 'u':
    case 'code':
      return {
        object: 'mark',
        type: type,
        nodes: next(el.childNodes),
      };
    case 'table':
      const data = { headless: true };

      if (
        el.firstElementChild &&
        el.firstElementChild.tagName.toLowerCase() === 'thead'
      ) {
        data.headless = false;
      }
      return {
        object: 'block',
        type: 'table',
        data: data,
        nodes: next(el.childNodes),
      };

    case 'tr':
    case 'th':
    case 'td':
      return {
        object: 'block',
        type: type,
        data: {},
        nodes: next(el.childNodes),
      };
    case 'a':
      return {
        object: 'inline',
        type: type,
        data: { href: el.getAttribute('href') },
        nodes: next(el.childNodes),
      };
    case 'img': {
      const src = el.getAttribute('src');
      const width = el.getAttribute('width');
      const height = el.getAttribute('height');
      return {
        object: 'block',
        type,
        data: { src, width, height },
        nodes: next(el.childNodes),
      };
    }
    default:
      return;
  }
}

function serialize(obj, children, next) {
  switch (obj.object) {
    case 'mark':
      switch (obj.type) {
        case 'b':
          return <b>{children}</b>;
        case 'i':
          return <i>{children}</i>;
        case 'u':
          return <u>{children}</u>;
        case 'code':
          return <code>{children}</code>;
        case 'font-family':
          return (
            <span
              style={{
                fontFamily:
                  FontFamilyList[obj.data.get('fontFamilyIndex')].name,
              }}
            >
              {children}
            </span>
          );
        case 'font-size':
          return (
            <span
              style={{
                fontSize: parseInt(obj.data.get('fontSize'), 10),
              }}
            >
              {children}
            </span>
          );
        default:
          return null;
      }

    case 'block':
      switch (obj.type) {
        case 'div':
          return <div className={obj.data.get('className')}>{children}</div>;
        case 'table':
          const headers = !obj.data.get('headless');
          const rows = children;
          const split =
            !headers || !rows || !rows.size || rows.size === 1
              ? { header: null, rows: rows }
              : {
                  header: rows.get(0),
                  rows: rows.slice(1),
                };

          return (
            <table>
              {headers && <thead>{split.header}</thead>}
              <tbody>{split.rows}</tbody>
            </table>
          );
        case 'tr':
          return <tr>{children}</tr>;
        case 'th':
          return <th>{children}</th>;
        case 'td':
          return <td>{children}</td>;
        case 'p':
          return <p>{children}</p>;
        case 'h1':
          return <h1>{children}</h1>;
        case 'h2':
          return <h2>{children}</h2>;
        case 'ul':
          return <ul>{children}</ul>;
        case 'ol':
          return <ol>{children}</ol>;
        case 'li':
          return <li>{children}</li>;
        case 'wf-condition':
          return (
            <div>
              {'{{#if '}
              {obj.data.get('id')}
              {'}}'}
              {children}
              {'{{/if}}'}
            </div>
          );
        case 'wf-loop':
          return (
            <ul>
              {'{{#each '}
              {obj.data.get('id')}
              {'}}'}
              {children}
              {'{{/each}}'}
            </ul>
          );
        case 'wf-loop-item':
          return <li>{children}</li>;
        case 'wf-loop-table':
          return (
            <div className="table-wrapper-border">
              <table className="loop-table">
                <thead>
                  <tr>
                    {obj.data.get('name') &&
                      obj.data
                        .get('name')
                        .map((field, index) => (
                          <th key={field + index}>{field}</th>
                        ))}
                  </tr>
                </thead>
                <tbody>
                  {'{{#each '}
                  {obj.data.get('id')}
                  {'}}'}
                  <tr>{children}</tr>
                  {'{{/each}}'}
                </tbody>
              </table>
            </div>
          );
        case 'wf-loop-table-headers':
          return (
            <div className="table-wrapper-border">
              <table className="loop-table">
                {'{{#each '}
                {obj.data.get('id')}
                {'}}'}
                <thead>
                  <tr>
                    {obj.data.get('name') &&
                      obj.data
                        .get('name')
                        .map((field, index) => (
                          <th key={field + index}>{field}</th>
                        ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>{children}</tr>
                </tbody>
                {'{{/each}}'}
              </table>
            </div>
          );
        case 'wf-loop-table-nested':
          return (
            <tr>
              <td colSpan="100">
                <table className="loop-table">
                  {'{{#each '}
                  {obj.data.get('id')}
                  {'}}'}
                  <thead>
                    <tr>
                      {obj.data.get('name') &&
                        obj.data
                          .get('name')
                          .map((field, index) => (
                            <th key={field + index}>{field}</th>
                          ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>{children}</tr>
                  </tbody>
                  {'{{/each}}'}
                </table>
              </td>
            </tr>
          );
        case 'wf-loop-table-item':
          const getText = index => index && children.get(index - 1);
          return (
            <>
              {children.map((item, index) => {
                return (
                  item.type && (
                    <td key={'children' + index}>
                      {getText(index)} {item}
                    </td>
                  )
                );
              })}
            </>
          );
        case 'wf-form':
          return (
            <form
              className="form-preview"
              id={obj.data.get('id')}
              action={`{{${obj.data.get('id')}}}`}
              method="POST"
              // enctype="multipart/form-data"
            >
              <div className="myrecaptcha" />
              {getAllInputsInForm(obj).map(input => (
                <React.Fragment key={input.label + input.name}>
                  <label className={clsx(input.requiredField && 'required')}>
                    {input.label}
                  </label>
                  {input.type === 'select' ? (
                    <select name={input.field} id="select">
                      {input.options.map(option => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  ) : (
                    <input
                      className={input.customClass}
                      type={input.type}
                      name={
                        input.relatedProperty
                          ? `${input.relatedProperty}.${input.field}`
                          : input.field
                      }
                      maxLength={input.length}
                      required={input.requiredField}
                      defaultValue={input.value}
                    />
                  )}
                </React.Fragment>
              ))}
              {/* <input type="file" id="file" name="file" multiple></input> */}
              <button type="submit">{obj.data.get('submit')}</button>
            </form>
          );
        case 'blockquote':
          return <blockquote>{children}</blockquote>;
        case 'img': {
          const src = obj.data.get('src');
          const width = obj.data.get('src');
          const height = obj.data.get('height');
          return (
            <img
              src={src}
              alt={src}
              width={width}
              height={height}
              className="image"
            />
          );
        }
        default:
          return null;
      }

    case 'inline':
      switch (obj.type) {
        case 'wf-reference':
          if (obj.data.get('format') === 'image') {
            return (
              <img
                src={`{{${obj.data.get('id')}}`}
                width={obj.data.get('width')}
                height={obj.data.get('height')}
                alt="ref-img"
              />
            );
          } else {
            return (
              <>
                {'{{'}
                {obj.data.get('id')}
                {'}}'}
              </>
            );
          }
        case 'wf-field':
          return (
            <>
              {'{{'}
              {obj.data.get('name')}
              {'}}'}
            </>
          );
        // can delete field for table if it will be useless
        case 'wf-field-table':
          return (
            <>
              {'{{'}
              {obj.data.get('name')}
              {'}}'}
            </>
          );
        case 'a':
          const href = obj.data.get('href');
          return <a href={href}>{children}</a>;
        default:
          return null;
      }
    default:
      return null;
  }
}

// ---------------

function serialize2(obj, children, next) {
  switch (obj.type) {
    case 'wf-condition':
      return (
        <wf-condition id={obj.data.get('id')} class="condition">
          {children}
        </wf-condition>
      );
    case 'wf-loop':
      return (
        <wf-loop id={obj.data.get('id')} class="loop">
          {children}
        </wf-loop>
      );
    case 'wf-loop-item':
      return (
        <wf-loop-item class="loop-item" data-name={obj.data.get('name')}>
          {children}
        </wf-loop-item>
      );
    case 'wf-loop-table':
      return (
        <wf-loop-table
          id={obj.data.get('id')}
          data-name={obj.data.get('name')}
          class="loop loop-table"
        >
          {children}
        </wf-loop-table>
      );
    case 'wf-loop-table-headers':
      return (
        <wf-loop-table-headers
          id={obj.data.get('id')}
          data-name={obj.data.get('name')}
          class="loop loop-table"
        >
          {children}
        </wf-loop-table-headers>
      );
    case 'wf-loop-table-nested':
      return (
        <wf-loop-table-nested
          id={obj.data.get('id')}
          data-name={obj.data.get('name')}
          class="loop loop-table"
        >
          {children}
        </wf-loop-table-nested>
      );
    case 'wf-loop-table-item':
      return (
        <wf-loop-table-item
          class="loop-item loop-table-item"
          data-name={obj.data.get('name')}
        >
          {children}
        </wf-loop-table-item>
      );
    case 'wf-reference':
      if (obj.data.get('format') === 'image') {
        return (
          <img
            src={`{{${obj.data.get('id')}}`}
            width={obj.data.get('width')}
            height={obj.data.get('height')}
            alt="ref-img"
          />
        );
      } else {
        return (
          <wf-reference
            data-format={obj.data.get('format')}
            id={obj.data.get('id')}
            class="reference"
          >
            {obj.data.get('name')}
          </wf-reference>
        );
      }
    case 'wf-form':
      return (
        <wf-form
          id={obj.data.get('id')}
          class="form"
          data-name={obj.data.get('name')}
          data-submit={obj.data.get('submit')}
        >
          {getAllInputsInForm(obj).map((input, index) => (
            <React.Fragment key={index}>
              <wf-input
                data-type={input.type}
                data-label={input.label}
                data-customClass={input.customClass}
                data-field={input.field}
                data-length={input.length}
                data-requiredField={input.requiredField}
                data-options={JSON.stringify(input.options)}
                data-relatedProperty={input.relatedProperty}
                data-value={input.value}
              />
            </React.Fragment>
          ))}
        </wf-form>
      );
    case 'wf-field':
      return (
        <wf-field
          class="field"
          data-name={obj.data.get('name')}
          id={obj.data.get('id')}
        >
          {obj.data.get('name')}
        </wf-field>
      );
    case 'wf-field-table':
      return (
        <wf-field-table
          class="field"
          data-name={obj.data.get('name')}
          id={obj.data.get('id')}
        >
          {obj.data.get('name')}
        </wf-field-table>
      );
    case 'img': {
      const src = obj.data.get('src');
      const width = obj.data.get('src');
      const height = obj.data.get('height');
      return (
        <img
          src={src}
          alt={src}
          width={width}
          height={height}
          className="image"
        />
      );
    }
    default:
      return serialize(obj, children, next);
  }
}
