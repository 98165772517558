import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';

import AutoComplete from '../AutoComplete';
import Modal from '../../Modal';
import { StyledButton, StyledCheckbox } from '../../common';
import TreeSelect from './TreeComponent';
import { fetchParentLoopData } from '../../../store/loops';

import { SelectCheckbox } from '../../common/';

import SelectFieldToLoop from './SelectFieldToLoop';
import { ConditionModal } from '../../common/ConditionModal/ConditionModal';
import SelectValueField from './SelectValueField';

import './style.scss';

const initialCurrentCondition = {
  conditionType: 'compare',
  field: '',
  format: '1',
  type: '',
  dynamicsEntity: '',
  value1: {
    type: 1,
    value: '',
    reference: {},
  },
  value2: {
    type: 1,
    value: '',
    reference: {},
  },
};

const findLastChildren = obj => {
  if (obj.deep === 0) {
    return obj.name;
  } else {
    obj.children.forEach(child => findLastChildren(child));
  }
};

class LoopModal extends Component {
  state = {
    treePath: '',
    modalWasMounted: false,
    isShowFilter: false,
    isShowModalCondition: false,
    isShowModalField: false,
    isValue1Open: false,
    isValue2Open: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedConnectorId && !this.state.modalWasMounted) {
      this.props.fetchParentLoopData(nextProps.selectedConnectorId);
    }
    this.setState(() => ({
      modalWasMounted: true,
    }));
  }

  setInputValue = (event, { selectedLoopPath }) => {
    const targetEl = event.target.innerHTML;
    this.setState({
      treePath: targetEl,
      selectedLoopPath: selectedLoopPath,
    });
  };

  toggleFilter = setFieldValue => {
    this.setState(
      prevState => ({
        isShowFilter: !prevState.isShowFilter,
      }),
      () => {
        if (!this.state.isShowFilter) {
          setFieldValue('currentCondition', initialCurrentCondition);
          setFieldValue('conditions', []);
        }
      }
    );
  };

  hideFilter = () => {
    this.setState({
      isShowFilter: false,
    });
  };

  showModalCondition = () => {
    this.setState({
      isShowModalCondition: true,
    });
  };

  hideModalCondition = setFieldValue => {
    this.setState({
      isShowModalCondition: false,
    });
    setFieldValue('currentCondition', initialCurrentCondition);
  };

  showModalField = () => {
    this.setState({
      isShowModalField: true,
    });
  };

  hideModalField = () => {
    this.setState({
      isShowModalField: false,
    });
  };

  closeLoopModal = () => {
    const { openLoopModal } = this.props;
    openLoopModal();
    this.hideFilter();
  };

  setSelectedFieldLoop = (event, { setFieldValue, filteringDeep }) => {
    const targetEl = event.target.innerHTML;
    setFieldValue('filteringDeep', filteringDeep);
    setFieldValue('currentCondition.dynamicsEntity', targetEl);
  };

  openSelectValue1 = () => {
    this.setState({
      isValue1Open: true,
    });
  };

  closeSelectValue1 = () => {
    this.setState({
      isValue1Open: false,
    });
  };

  openSelectValue2 = () => {
    this.setState({
      isValue2Open: true,
    });
  };

  closeSelectValue2 = () => {
    this.setState({
      isValue2Open: false,
    });
  };

  render() {
    const {
      isOpened,
      loop_records,
      loop_properties,
      handleSubmitLoopModal,
      selectedConnectorId,
      value,
      selectedLoop,
      openEditingLoopModal,
      closeEditLoopModal,
    } = this.props;

    const {
      isShowFilter,
      isShowModalCondition,
      isShowModalField,
      treePath,
      selectedLoopPath,
      isValue1Open,
      isValue2Open,
    } = this.state;

    const isLoopTable = value.blocks.some(
      node => node.type === 'wf-loop-table-item'
    );
    const isLoop = value.blocks.some(
      node => node.type === 'wf-loop-item' || node.type === 'wf-loop-table-item'
    );
    const record =
      loop_records &&
      loop_records.find(record => record.id === selectedLoop.recordId);

    return (
      <Modal title="Loop modal" isOpen={isOpened || openEditingLoopModal}>
        <section className="add-loop">
          <header>
            <h1>Add a Loop</h1>
            <p>
              Add a Loop to your Fuse if you want to display recurring items
              from your Dynamics 365 environment. Related entities are
              supported, as is filtering, sorting and grouping of the loop. Use
              the pane on the right side in the HTML editor to finalize your
              settings.
            </p>
          </header>
          <main>
            <Formik
              initialValues={
                openEditingLoopModal
                  ? {
                      record: record && record.label,
                      fields: selectedLoop.fields.map(value => ({
                        label: value,
                        value,
                      })),
                      filteringDeep: 0, // 0 parent, 1 - first children
                      loopDeep: 0, // 0 parent, 1 - first children
                      allRecords: true,
                      isTable: isLoopTable || false,
                      currentCondition: initialCurrentCondition,
                      conditions: [],
                      // selectedLoopPath: selectedLoop.selectedLoopPath,
                      name: findLastChildren(selectedLoop),
                      // (selectedLoop.children &&
                      //   selectedLoop.children.property) ||
                      // selectedLoop.entity,
                      openEditingLoopModal: openEditingLoopModal,
                    }
                  : {
                      name: '',
                      record: '',
                      fields: [],
                      filteringDeep: 0, // 0 parent, 1 - first children
                      loopDeep: 0, // 0 parent, 1 - first children
                      allRecords: true,
                      isTable: isLoopTable || false,
                      currentCondition: initialCurrentCondition,
                      conditions: [],
                      selectedLoopPath: selectedLoopPath || {},
                      isHeaders: false,
                    }
              }
              onSubmit={values => {
                const { treePath } = this.state;
                this.hideFilter();
                const trimFields = values.fields.map(({ value, label }) => ({
                  id: `field-${uuidv4()}`,
                  field: value,
                  displayName: label,
                  settings: {
                    text: {},
                  },
                }));
                handleSubmitLoopModal({
                  values: { ...values, fields: trimFields },
                  allRecords: values.allRecords ? true : false,
                  treePath,
                  selectedLoopPath,
                });
              }}
              render={({ handleSubmit, setFieldValue, values, setValues }) => {
                const setInputLoopWithFormik = (
                  event,
                  { selectedLoopPath, loopDeep }
                ) => {
                  this.setInputValue(event, { selectedLoopPath });
                  setFieldValue('loopDeep', loopDeep);
                };

                const toggleRecords = () => {
                  setFieldValue('record', '');
                  setFieldValue('allRecords', !values.allRecords);
                };

                const isDisabledLoop = !(
                  values.fields.length &&
                  treePath &&
                  (values.allRecords || values.record)
                );

                const {
                  conditionType,
                  dynamicsEntity,
                  field,
                  format,
                  type,
                  value1,
                  value2,
                } = values.currentCondition;
                const isDisabledCondition = !(conditionType === 'compare'
                  ? dynamicsEntity &&
                    field &&
                    format &&
                    type &&
                    (value1.value || Object.keys(value1.reference).length) &&
                    (type === 10
                      ? value2.value || Object.keys(value2.reference).length
                      : true)
                  : dynamicsEntity && field);

                const clearRecordOnChange = loopDeep => {
                  setFieldValue('record', '');
                  setFieldValue(
                    'allRecords',
                    loopDeep === 0 ? true : values.allRecords
                  );
                };

                return (
                  <form onSubmit={handleSubmit}>
                    <label className="head-txt">Dynamics 365 entity</label>
                    <TreeSelect
                      setInputValue={setInputLoopWithFormik}
                      setValues={setValues}
                      values={values}
                      additionalFunction={clearRecordOnChange}
                    />
                    <div className="autocomplete-section">
                      <AutoComplete
                        label="Record"
                        name="record"
                        isDisabled={values.loopDeep === 0 || values.allRecords}
                        connectorId={selectedConnectorId}
                        suggestions={loop_records.map(({ label, id }) => ({
                          label,
                          value: label,
                          entity: selectedLoopPath && selectedLoopPath.entity,
                          id: id,
                        }))}
                        setFieldFormik={setFieldValue}
                        valueFormik={values.record}
                      />
                      <StyledCheckbox
                        name="records"
                        text="Use all records"
                        passSettings={toggleRecords}
                        checked={values.loopDeep === 0 || values.allRecords}
                        disabled={values.loopDeep === 0}
                        style={{
                          height: 'auto',
                          margin: '10px 0',
                          padding: 0,
                        }}
                      />
                    </div>
                    <div>
                      <SelectCheckbox
                        options={loop_properties.map(
                          ({ label, field, type, value }) => ({
                            label,
                            value: field,
                            option: value,
                          })
                        )}
                        changeHandler={setFieldValue}
                        valueFormik={values.fields}
                      />
                    </div>
                    <StyledCheckbox
                      name="insertAsTable"
                      text="Insert as table"
                      checked={values.isTable}
                      passSettings={() =>
                        setFieldValue('isTable', !values.isTable)
                      }
                      style={{ height: 'auto' }}
                      disabled={isLoopTable}
                    />
                    <StyledCheckbox
                      name="addFilter"
                      text="Filter loop"
                      checked={isShowFilter}
                      passSettings={() => this.toggleFilter(setFieldValue)}
                      style={{ height: 'auto' }}
                      disabled={isDisabledLoop}
                    />
                    <StyledCheckbox
                      name="isHeaders"
                      text="Insert each loop item with header"
                      checked={values.isHeaders}
                      passSettings={() =>
                        setFieldValue('isHeaders', !values.isHeaders)
                      }
                      style={{ height: 'auto' }}
                      disabled={isLoop || !values.isTable}
                    />
                    {isShowFilter && (
                      <>
                        <ConditionModal
                          isOpen={isShowModalCondition}
                          fieldToCompareOnClick={this.showModalField}
                          value1OnClick={this.openSelectValue1}
                          value2OnClick={this.openSelectValue2}
                          hideModalCondition={() =>
                            this.hideModalCondition(setFieldValue)
                          }
                          values={values}
                          setFieldValue={setFieldValue}
                          showModalCondition={this.showModalCondition}
                          fieldToCompareName="currentCondition.field"
                          fieldToCompareValue={values.currentCondition.field}
                          isDisabledCondition={isDisabledCondition}
                          initialCurrentCondition={initialCurrentCondition}
                        />
                        <SelectFieldToLoop
                          isOpen={isShowModalField}
                          values={values}
                          showModalField={this.showModalField}
                          hideModalField={this.hideModalField}
                          setSelectedFieldLoop={this.setSelectedFieldLoop}
                          setFieldValue={setFieldValue}
                          loop_properties={loop_properties}
                          parentLoopTree={selectedLoopPath}
                        />
                        <SelectValueField
                          isOpened={isValue1Open}
                          closeModal={this.closeSelectValue1}
                          setFieldValue={setFieldValue}
                          name="currentCondition.value1"
                        />
                        <SelectValueField
                          isOpened={isValue2Open}
                          closeModal={this.closeSelectValue2}
                          setFieldValue={setFieldValue}
                          name="currentCondition.value2"
                        />
                      </>
                    )}
                    <footer>
                      <StyledButton
                        type="button"
                        action={() => {
                          openEditingLoopModal
                            ? closeEditLoopModal()
                            : this.closeLoopModal();
                        }}
                        variant="cancel"
                      >
                        Cancel
                      </StyledButton>
                      <StyledButton type="submit" disabled={isDisabledLoop}>
                        {openEditingLoopModal ? 'Change' : 'Add'}
                      </StyledButton>
                    </footer>
                  </form>
                );
              }}
            />
          </main>
        </section>
      </Modal>
    );
  }
}

LoopModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  openLoopModal: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
  return {
    loops_data: store.loops.loops_data,
    selectedConnectorId: store.fuses.selectedConnectorId,
    loop_records: store.loops.loop_records,
    loop_properties: store.loops.loop_properties,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchParentLoopData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoopModal);
