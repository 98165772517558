import React from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import PropTypes from 'prop-types';

import theme from './styles.module.scss';

const customStylesSuccess = {
  option: provided => ({
    ...provided,
    color: '#4d565a',
    'text-indent': '10px',
    'min-height': '35px',
    'border-radius': '5px',
    width: '100%',
  }),

  control: provided => ({
    ...provided,
    width: '100%',
    display: 'none',
  }),

  dropdownButton: provided => ({
    ...provided,
    color: '#4d565a',
    'text-indent': '10px',
    'min-height': '35px',
    'border-radius': '5px',
    width: '100%',
    height: '35px',
    outline: 'none',
    border: 'none',
    'flex-direction': 'row',
    'font-family': 'Ubuntu, sans-serif',
    'font-size': '14px',
    'font-weight': 'normal',
    'font-style': ' normal',
    'font-stretch': 'normal',
    'line-height': ' normal',
    'letter-spacing': 'normal',
    'border-left': '5px solid #22b9ad',
    'background-color': '#eef0f2',
    'margin-top': '5px',
    '& span svg': {
      color: 'hsl(0,0%,80%)',
      height: '38px',
      width: '35px',
      margin: '-10px -13px -10px 0px !important',
      'stroke-width': '0 !important',
      '&:hover': {
        color: 'hsl(0,0%,60%)',
      },
    },
  }),
};

const customStyles = {
  option: provided => ({
    ...provided,
    color: '#4d565a',
    'text-indent': '10px',
    'min-height': '35px',
    'border-radius': '5px',
    width: '100%',
  }),

  control: provided => ({
    ...provided,
    width: '100%',
    display: 'none',
  }),

  dropdownButton: provided => ({
    ...provided,
    color: '#4d565a',
    'text-indent': '10px',
    'min-height': '35px',
    'border-radius': '5px',
    width: '100%',
    height: '35px',
    outline: 'none',
    border: 'none',
    'flex-direction': 'row',
    'font-family': 'Ubuntu, sans-serif',
    'font-size': '14px',
    'font-weight': 'normal',
    'font-style': ' normal',
    'font-stretch': 'normal',
    'line-height': ' normal',
    'letter-spacing': 'normal',
    'border-left': '5px solid #d5dbdc',
    'background-color': '#eef0f2',
    'margin-top': '5px',
    '& span svg': {
      color: 'hsl(0,0%,80%)',
      height: '38px',
      width: '35px',
      margin: '-10px -13px -10px 0px !important',
      'stroke-width': '0 !important',
      '&:hover': {
        color: 'hsl(0,0%,60%)',
      },
    },
  }),
};

const CustomOption = ({ innerProps, innerRef, data, ...props }) => {
  return (
    <div className={theme.customOption} ref={innerRef} {...innerProps}>
      <div className={theme.customOptionWrapper}>
        <input
          id={data.label}
          type="checkbox"
          checked={props.isSelected}
          readOnly
          className={theme.customOptionCheckbox}
        />
        <label className={theme.customOptionTextNode}>{data.label}</label>
        <label className={theme.customOptionTextNode}>{data.option}</label>
      </div>
    </div>
  );
};

export const SelectCheckbox = ({ options, changeHandler, valueFormik }) => {
  return (
    <div className={theme.selectCheckbox}>
      <label className="select-label">Fields</label>
      <ReactMultiSelectCheckboxes
        label="Fields"
        styles={valueFormik.length ? customStylesSuccess : customStyles}
        onChange={values => {
          changeHandler('fields', values);
        }}
        components={{
          Option: CustomOption,
        }}
        options={options}
        value={valueFormik || null}
      />
    </div>
  );
};

SelectCheckbox.propTypes = {
  options: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired,
};
