import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { fetchConnectors } from '../../../store/connectors';

import Modal from '../../Modal';
import { createFuse } from '../../../store/fuses';
import { StyledInput, StyledButton } from '../../common';
import AutoComplete from '../AutoComplete';
import { validateConnectorName } from '../../../helpers/validations';

import theme from '../../Connectors/ConnectorModal/styles.module.scss';

class CreateFuseModal extends Component {
  componentDidMount() {
    const { fetchConnectors } = this.props;
    fetchConnectors();
  }

  getConnectorId = name =>
    this.props.connectors.find(connector => connector.name === name).id;

  render() {
    const { isOpened, handleOpenModal, createFuse, connectors } = this.props;
    return (
      <Modal
        className={theme.createConnectorWrapper}
        isOpen={isOpened}
        title="Create fuse"
      >
        <div className={theme.createConnector}>
          <header className={theme.createConnectorHeader}>
            <h1>Create Fuse</h1>
            <p>
              Give your new Fuse a name and choose which Connector to link to
              it.
            </p>
          </header>
          <Formik
            initialValues={{ fuseName: '', connectorName: '' }}
            validationSchema={Yup.object().shape({
              fuseName: Yup.string()
                .min(2, 'Please enter the name of the Fuse')
                .required('Required')
                .test(
                  'This name is already in use, please choose another one',
                  'This name is already in use, please choose another one',
                  value => validateConnectorName(this.props.fuses, value)
                ),
              connectorName: Yup.string()
                .min(1, 'Please select the Connector you wish to use')
                .required('Required'),
            })}
            onSubmit={values => {
              const { fuseName, connectorName } = values;
              createFuse(fuseName, this.getConnectorId(connectorName));
            }}
          >
            {({ handleSubmit, setFieldValue, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <StyledInput type="text" name="fuseName" label="Name" />
                  <AutoComplete
                    label="Connector"
                    name="connectorName"
                    suggestions={connectors.map(({ name }) => ({
                      label: name,
                      value: name,
                    }))}
                    setFieldFormik={setFieldValue}
                    valueFormik={values.connectorName}
                  />
                  <footer>
                    <StyledButton
                      width="auto"
                      action={handleOpenModal}
                      variant="cancel"
                    >
                      Cancel
                    </StyledButton>
                    <StyledButton
                      type="submit"
                      width="auto"
                      disabled={Object.values(values).some(
                        value => value === ''
                      )}
                    >
                      Add
                    </StyledButton>
                  </footer>
                </form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = store => {
  return {
    connectors: store.connectors.connectors,
    fuses: store.fuses.fuses,
  };
};

const mapDispatchToProps = dispatch => ({
  createFuse: (name, id) => dispatch(createFuse(name, id)),
  fetchConnectors: () => dispatch(fetchConnectors()),
});

CreateFuseModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  createFuse: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFuseModal);
