import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Checkbox, Button } from '@material-ui/core';
import clsx from 'clsx';

import ConnectorModal from '../../components/Connectors/ConnectorModal';
import ConnectorNotificationModal from '../../components/Connectors/ConnectorNotificationModal';
import TableComponent from '../../components/Table';
import { EmptyTable } from '../../components/Table/EmptyTable';
import {
  fetchConnectors,
  removeConnectors,
  testConnector,
  resetNotifications,
} from '../../store/connectors';
import { headRows, icons } from '../../components/mocks/Connectors';
import { StyledTableRow, StyledTableCell } from '../../components/Table/style';
import { DashboardLayout } from '../../components/Dashboard/DashboardLayout';
import { StyledButton } from '../../components/common';
import { ConfirmModal } from '../../components/common/ConfirmModal/ConfirmModal';
import { SnackBar } from '../../components';
import { getDeletedItems } from '../../helpers/getDeletedItems';
import './style.scss';

import orderBy from 'lodash/orderBy';
import noConnectors from '../../assets/svgs/noConnectors.svg';
import testConnectorImg from '../../assets/svgs/testFuse.svg';

import { invertDirection } from '../../helpers/invertDirection';

class Connectors extends Component {
  state = {
    isOpened: false,
    openEditModal: false,
    openSuccessModal: false,
    openErrorModal: false,
    page: 0,
    rowsPerPage: 5,
    selectedConnectors: [],
    currentConnectorId: null,
    showConfirmModal: false,
    columnToSort: '',
    sortDirection: '',
  };

  componentDidMount() {
    const { fetchConnectors } = this.props;
    fetchConnectors();

    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('verifyConnector') === 'true') {
        this.setState({ openSuccessModal: true });
      } else this.setState({ openErrorModal: true });
      return;
    }
  }

  handleDisableEditButton = () => {
    return this.state.selectedConnectors.length === 1 ? false : true;
  };
  handleDisableRemoveButton = () => {
    return this.state.selectedConnectors.length > 0 ? false : true;
  };

  handleEdit = () => {
    const currentConnectorId = this.props.connectors.find(
      connector => connector.id === this.state.selectedConnectors[0]
    );
    this.setState({
      openEditModal: true,
      currentConnectorId: currentConnectorId,
    });
  };

  handleRemoveConnectorsClick = () => {
    const { selectedConnectors } = this.state;
    this.props.removeConnectors(selectedConnectors);
    this.setState({ selectedConnectors: [], showConfirmModal: false });
  };

  handleTestConnector = () => {
    if (this.state.selectedConnectors.length) {
      this.props.testConnector(this.state.selectedConnectors[0]);
      this.setState({ showTestNotification: true });
    }
  };

  handleOpenModal = () => {
    this.setState(prevState => ({
      isOpened: false,
      openEditModal: false,
      selectedConnectors: [],
    }));
  };

  handleNotificationModal = () => {
    this.setState(prevState => ({
      openSuccessModal: false,
      openErrorModal: false,
      showConfirmModal: false,
    }));
    this.props.history.push('/admin/connectors');
  };
  handleConfirmModal = () => {
    this.setState({ showConfirmModal: true });
  };
  handleSelectAllClick = () => {
    const { connectors } = this.props;
    const { selectedConnectors } = this.state;
    if (selectedConnectors.length === 0) {
      const selectAll = connectors.map(user => user.id);
      return this.setState({ selectedConnectors: selectAll });
    }
    this.setState({ selectedConnectors: [] });
  };

  handleClick = (event, id) => {
    const { selectedConnectors } = this.state;
    const selectedIndex = selectedConnectors.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedConnectors, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedConnectors.slice(1));
    } else if (selectedIndex === selectedConnectors.length - 1) {
      newSelected = newSelected.concat(selectedConnectors.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedConnectors.slice(0, selectedIndex),
        selectedConnectors.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedConnectors: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  handleSort = columnName => {
    this.setState(prevState => ({
      columnToSort: columnName,
      sortDirection:
        prevState.columnToSort === columnName
          ? invertDirection[prevState.sortDirection]
          : 'asc',
    }));
  };

  render() {
    const {
      isOpened,
      openSuccessModal,
      openErrorModal,
      selectedConnectors,
      rowsPerPage,
      page,
      currentConnectorId,
      openEditModal,
      showConfirmModal,
      columnToSort,
      sortDirection,
    } = this.state;

    const {
      connectors,
      showNotification,
      notificationVariant,
      notificationMessage,
      resetNotifications,
      loading,
    } = this.props;

    const isSelected = id => selectedConnectors.indexOf(id) !== -1;
    return (
      <>
        <DashboardLayout>
          <section className="table-wrapper">
            <p className="title-text">
              Connectors reflect your Dynamics 365 or Dataverse environments tied to
              Webfuse. Just add your URL and grant permission to Webfuse and
              start building awesome Fuses.
            </p>
            <StyledButton
              action={() => {
                this.setState({ isOpened: true });
              }}
              width="auto"
            >
              + Create new Connector
            </StyledButton>
            {connectors.length > 0 ? (
              <TableComponent
                headRows={headRows}
                selectedLength={selectedConnectors.length}
                totalLength={connectors ? connectors.length : 0}
                handleSelectAllClick={this.handleSelectAllClick}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                handleSort={this.handleSort}
                columnToSort={columnToSort}
                sortDirection={sortDirection}
                headNav={
                  <div className="nav-tabs">
                    <Button
                      className="head_nav"
                      onClick={this.handleEdit}
                      disabled={this.handleDisableEditButton()}
                    >
                      <img
                        src={icons.edit}
                        alt="nav"
                        className="head_nav_icon"
                      />
                      Edit
                    </Button>
                    <Button
                      className="head_nav"
                      onClick={this.handleConfirmModal}
                      disabled={this.handleDisableRemoveButton()}
                    >
                      <img
                        src={icons.cross}
                        alt="nav"
                        className="head_nav_icon"
                      />
                      Remove
                    </Button>
                    <Button
                      className="head_nav"
                      disabled={this.handleDisableEditButton()}
                      onClick={this.handleTestConnector}
                    >
                      <img
                        className={clsx('fas fa-vials', 'head_nav_icon')}
                        src={testConnectorImg}
                        alt="test"
                      />
                      Test
                    </Button>
                  </div>
                }
              >
                {connectors &&
                  orderBy(connectors, columnToSort, sortDirection)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => {
                      const isConnectorSelected = isSelected(row.id);
                      return (
                        <StyledTableRow
                          hover
                          key={row.id}
                          onClick={async event => {
                            if (isConnectorSelected) {
                              await this.handleEdit();
                            } else {
                              await this.handleClick(event, row.id);
                              await this.handleEdit();
                            }
                          }}
                          role="checkbox"
                          selected={isConnectorSelected}
                        >
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              color="default"
                              checked={isConnectorSelected}
                              onClick={event => {
                                event.stopPropagation();
                                this.handleClick(event, row.id);
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.resource}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableComponent>
            ) : !loading ? (
              <EmptyTable
                backGroundImg={{
                  backgroundImage: `url(${noConnectors})`,
                }}
                title={'You don`t have any Connectors yet!'}
              />
            ) : null}
            <ConnectorModal
              isOpened={isOpened}
              handleOpenModal={this.handleOpenModal}
            />
            <ConnectorModal
              isOpened={openEditModal}
              handleOpenModal={this.handleOpenModal}
              edit
              connectorData={currentConnectorId}
            />
            <ConnectorNotificationModal
              isOpened={openSuccessModal}
              handleNotificationModal={this.handleNotificationModal}
            />
            <ConnectorNotificationModal
              isOpened={openErrorModal}
              handleNotificationModal={this.handleNotificationModal}
              errorMessage
            />
            <ConfirmModal
              open={showConfirmModal}
              header={
                selectedConnectors.length &&
                getDeletedItems(connectors, selectedConnectors, 'name').length >
                  1
                  ? 'Remove Connectors'
                  : 'Remove Connector'
              }
              text={
                selectedConnectors.length &&
                getDeletedItems(connectors, selectedConnectors, 'name').length >
                  1
                  ? 'Please make sure that these Connectors are not linked to any Fuses anymore. Are you sure you want to remove these Connectors? '
                  : 'Please make sure that this Connector is not linked to any Fuses anymore. Are you sure you want to remove this Connector? '
              }
              deletedItems={getDeletedItems(
                connectors,
                selectedConnectors,
                'name'
              )}
              action={this.handleRemoveConnectorsClick}
              handleClose={this.handleNotificationModal}
            />
          </section>
          <SnackBar
            open={showNotification}
            message={notificationMessage}
            variant={notificationVariant}
            close={resetNotifications}
          />
        </DashboardLayout>
      </>
    );
  }
}

const mapStateToProps = store => {
  return {
    error: store.connectors.error,
    connectors: store.connectors.connectors,
    showNotification: store.connectors.showNotification,
    notificationVariant: store.connectors.notificationVariant,
    notificationMessage: store.connectors.notificationMessage,
    loading: store.connectors.loading,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchConnectors, removeConnectors, testConnector, resetNotifications },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Connectors);
