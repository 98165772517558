import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from '../../Modal';
import { StyledButton, StyledCheckbox } from '../../../components/common';
import StyledInput from '../../common/StyledInput';
import AutoComplete from '../AutoComplete';
import { Formik } from 'formik';
import uuidv4 from 'uuid/v4';

import { fetchSelectOptions } from '../../../store/fuses';

import theme from './styles.module.scss';

const inputTypes = [
  {
    label: 'text',
    value: 'text',
  },
  {
    label: 'date',
    value: 'date',
  },
  {
    label: 'checkbox',
    value: 'checkbox',
  },
  {
    label: 'number',
    value: 'number',
  },
  {
    label: 'radio',
    value: 'radio',
  },
  {
    label: 'datetime-local',
    value: 'datetime-local',
  },
  {
    label: 'select',
    value: 'select',
  },
];

class SelectInputModal extends Component {
  render() {
    const {
      toggleInputModal,
      isOpened,
      handleSubmitInputModal,
      account_data,
      push,
      loop_properties,
      fetchSelectOptions,
      selectOptions,
      connectorId,
      currentValues,
      setFieldFormik,
      parentValues,
      resetInitialValues,
      setFieldValueParent,
      valuesParent,
      relations,
      fetchLoopProperties,
      parentEntity,
    } = this.props;
    return (
      <Modal isOpen={isOpened} title="select-input-modal">
        <section className={theme.formModal}>
          <header>
            <h1>Select input field</h1>
            <p>Add an input field to your Form.</p>
          </header>
          <Formik
            initialValues={
              currentValues || {
                id: `related-input-${uuidv4()}`,
                label: '',
                requiredField: false,
                length: '120',
                customClass: '',
                field: '',
                type: 'text',
                relatedProperty: '',
                options: [],
                value: '',
                isRelated: false,
              }
            }
            onSubmit={values => {
              if (parentValues.requestMethod === 2) {
                const fieldValue = account_data.find(
                  item => item.field === values.field
                );
                values.value = (fieldValue && fieldValue.value) || '';
              }

              handleSubmitInputModal({
                ...values,
                options: selectOptions,
              });

              if (currentValues) {
                setFieldFormik(values);
              } else if (values.relatedProperty) {
                const formIndex = valuesParent.relatedForms.findIndex(
                  item => item.property === values.relatedProperty
                );

                if (formIndex === -1) {
                  const currentRelation = relations.find(
                    item => item.property === values.relatedProperty
                  );

                  setFieldValueParent(
                    `relatedForms[${valuesParent.relatedForms.length}]`,
                    {
                      entity: currentRelation.entity,
                      property: values.relatedProperty,
                      inputs: [{ ...values, options: selectOptions }],
                      relationType: currentRelation.relationType,
                    }
                  );
                } else {
                  setFieldValueParent(`relatedForms[${formIndex}].inputs`, [
                    ...valuesParent.relatedForms[formIndex].inputs,
                    {
                      ...values,
                      options: selectOptions,
                    },
                  ]);
                }
              } else {
                push({ ...values, options: selectOptions });
              }
            }}
            render={({ values, setFieldValue, handleSubmit, setValues }) => {
              const {
                label,
                field,
                length,
                type,
                relatedProperty,
                isRelated,
              } = values;

              const isDisabled = !(
                label &&
                field &&
                length &&
                (isRelated ? relatedProperty : true)
              );

              const checkboxOnchange = () => {
                setValues({ ...values, relatedProperty: '', field: '' });
                fetchLoopProperties(connectorId, parentEntity);
                setFieldValue('isRelated', !isRelated);
              };

              return (
                <form>
                  <StyledCheckbox
                    name="isRelated"
                    text="Add related entity"
                    checked={isRelated}
                    style={{ height: 'auto', marginBottom: '5px' }}
                    passSettings={checkboxOnchange}
                  />
                  <StyledInput
                    type="text"
                    label="Label"
                    name="label"
                    onChange={value => setFieldValue('label', value)}
                    value={values.label}
                  />
                  <AutoComplete
                    label=" Related entity"
                    name="relatedProperty"
                    isDisabled={!isRelated}
                    required
                    setFieldFormik={setFieldValue}
                    suggestions={relations.map(
                      ({ entity, label, property, relationType }) => ({
                        label,
                        value: property,
                        entity,
                      })
                    )}
                    valueFormik={relatedProperty}
                    connectorId={connectorId}
                    onChangeAutoComplete={fetchLoopProperties}
                    setValues={setValues}
                    valuesToReset={{ ...values, field: '' }}
                  />
                  <AutoComplete
                    label="Field"
                    name="field"
                    suggestions={(parentValues.requestMethod === 1 ||
                    relatedProperty
                      ? loop_properties
                      : account_data
                    ).map(({ label, field, type, value }) => ({
                      label,
                      value: field,
                    }))}
                    setFieldFormik={setFieldValue}
                    valueFormik={values.field}
                  />
                  <AutoComplete
                    label="Type"
                    name="type"
                    setFieldFormik={setFieldValue}
                    suggestions={inputTypes}
                    valueFormik={type}
                    fetchOptions={() =>
                      fetchSelectOptions(
                        connectorId,
                        this.props.parentValues.entity,
                        values.field
                      )
                    }
                  />
                  <StyledCheckbox
                    name="requiredField"
                    text="Make this a required field"
                    onChange={() =>
                      setFieldValue('requiredField', !values.requiredField)
                    }
                    style={{ padding: 0, height: 30 }}
                  />
                  <StyledInput
                    type="number"
                    label="Maximum length"
                    name="length"
                    value={`${values.length}`}
                    onChange={value => setFieldValue('length', value)}
                  />
                  <StyledInput
                    type="text"
                    label="Custom CSS class"
                    name="customClass"
                    required={false}
                    onChange={value => setFieldValue('customClass', value)}
                  />
                  <footer>
                    <StyledButton
                      action={() => {
                        toggleInputModal();
                        resetInitialValues();
                      }}
                      width="auto"
                      variant="cancel"
                    >
                      Cancel
                    </StyledButton>
                    <StyledButton
                      type="button"
                      width="auto"
                      action={handleSubmit}
                      disabled={isDisabled}
                    >
                      {currentValues ? 'Change' : 'Add'}
                    </StyledButton>
                  </footer>
                </form>
              );
            }}
          />
        </section>
      </Modal>
    );
  }
}

SelectInputModal.propTypes = {
  toggleInputModal: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

const mapStateToProps = store => {
  return {
    connectorId: store.fuses.selectedConnectorId,
    selectOptions: store.fuses.selectOptions,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSelectOptions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectInputModal);
