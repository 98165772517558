import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    wide: PropTypes.bool,
    padding: PropTypes.number,
  };

  render() {
    const { children, title, isOpen, padding, className } = this.props;
    const modalStyles = {
      overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(198,203,212, .6)',
        zIndex: 995,
      },
      content: {
        top: 'initial',
        bottom: 'initial',
        left: 'initial',
        right: 'initial',
        position: 'relative',
        width: '600px',
        maxHeight: '100vh',
        outline: 'none',
        padding: '0',
        borderRadius: '10px',
        backgroundColor: '#fff',
        boxShadow: '0 8px 14px 0 #c6c6c6',
      },
    };
    return (
      <ReactModal
        padding={padding}
        isOpen={isOpen}
        ariaHideApp={false}
        style={modalStyles}
        contentLabel={title}
        className={className}
      >
        {children}
      </ReactModal>
    );
  }
}
