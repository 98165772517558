import React from 'react';
import { Button, Icon } from './ToolbarControls';

const hasAlign = (value, className, editor) => {
  return value.blocks.some(node => node.data.get('className') === className);
};

export const AlignButton = ({ editor, value, className, icon }) => {
  const isActive = hasAlign(value, className, editor);

  return (
    <Button
      active={isActive}
      onMouseDown={event => onClickAlign({ event, editor, value, className })}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const onClickAlign = ({ event, editor, value, className }) => {
  event.preventDefault();

  return editor
    .unwrapBlock('div')
    .wrapBlock({ data: { className }, type: 'div' });
};
