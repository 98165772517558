import React from 'react';
import {
  BarcodeSettings,
  CurrencySettings,
  DateSettings,
  ImageSettings,
  NumberSettings,
  PercentageSettings,
  TimeSettings,
} from './States/';

export const settingsSwitcher = ({
  values,
  handleChange,
  settings_locales,
  settings_currencies,
}) => {
  switch (values.format) {
    case 'currency':
      return (
        <CurrencySettings
          handleChange={handleChange}
          values={values}
          settings={{
            locales: settings_locales,
            currencies: settings_currencies,
          }}
        />
      );
    case 'number':
      return (
        <NumberSettings
          handleChange={handleChange}
          values={values}
          settings={{
            locales: settings_locales,
            currencies: settings_currencies,
          }}
        />
      );
    case 'date':
      return (
        <DateSettings
          handleChange={handleChange}
          values={values}
          settings={{
            locales: settings_locales,
            currencies: settings_currencies,
          }}
        />
      );
    case 'time':
      return (
        <TimeSettings
          handleChange={handleChange}
          values={values}
          settings={{
            locales: settings_locales,
            currencies: settings_currencies,
          }}
        />
      );
    case 'percentage':
      return (
        <PercentageSettings
          handleChange={handleChange}
          values={values}
          settings={{
            locales: settings_locales,
            currencies: settings_currencies,
          }}
        />
      );
    case 'barcode':
      return (
        <BarcodeSettings
          handleChange={handleChange}
          values={values}
          settings={{
            locales: settings_locales,
            currencies: settings_currencies,
          }}
        />
      );
    case 'image':
      return <ImageSettings handleChange={handleChange} values={values} />;
    default:
      return null;
  }
};
