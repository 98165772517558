import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import { createUser } from '../../../store/users';
import { StyledInput, StyledButton } from '../../common';
import { validateEmail } from '../../../helpers/validations';

import theme from './styles.module.scss';

class CreateNewUserModal extends Component {
  render() {
    const { isOpened, handleOpenModal, createUser } = this.props;
    return (
      <Modal
        className={theme.createUserWrapper}
        isOpen={isOpened}
        title="Create new User"
      >
        <div className={theme.createUser}>
          <header>
            <h1>Create new user</h1>
            <p>
              Create a new User. Complete the details. The User will be
              activated upon verifying its account through the link that can be
              sent automatically or by yourself by copying it.
            </p>
          </header>
          <Formik
            initialValues={{ firstName: '', lastName: '', email: '' }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .min(2, 'Please enter first name')
                .required('Required'),
              lastName: Yup.string()
                .min(2, 'Please enter last name')
                .required('Required'),
              email: Yup.string()
                .email()
                .required('Required')
                .test(
                  'validateEmail',
                  'E-mail is not allowed, please use a registered company domain',
                  value => validateEmail(value)
                ),
            })}
            onSubmit={values => {
              createUser(values);
              handleOpenModal();
            }}
          >
            {({ isValid, values }) => {
              const copyLink = () => {
                createUser(values, true);
                handleOpenModal();
              };
              return (
                <Form>
                  <StyledInput
                    type="text"
                    name="firstName"
                    label="First Name"
                  />
                  <StyledInput type="text" name="lastName" label="Last Name" />
                  <StyledInput
                    type="email"
                    name="email"
                    label="E-mail address"
                  />
                  <footer>
                    <StyledButton
                      className="cancelButton"
                      action={handleOpenModal}
                      variant="cancel"
                    >
                      Cancel
                    </StyledButton>
                    <StyledButton type="submit" disabled={!isValid}>
                      Send Invite
                    </StyledButton>
                    <StyledButton
                      type="button"
                      disabled={!isValid}
                      action={copyLink}
                    >
                      Copy invite link
                    </StyledButton>
                  </footer>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createUser,
    },
    dispatch
  );

CreateNewUserModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(CreateNewUserModal);
