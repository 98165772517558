import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Treebeard, decorators } from 'react-treebeard';
import uuidv4 from 'uuid/v4';

import {
  fetchLoopRelations,
  fetchLoopRecords,
  fetchParentLoopData,
  setCurrentLoopPath,
  setCurrentElementIndex,
  setDepthOfAttachment,
  fetchLoopProperties,
} from '../../../store/loops';
import './style.scss';
import clsx from 'clsx';

class TreeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      inputData: '',
      filteredData: [],
      inputValue: props.values.openEditingLoopModal ? props.values.name : '',
    };
    this.onToggle = this.onToggle.bind(this);
  }

  openDropdown(openedDropdown) {
    this.setState({ openedDropdown });
  }

  async onToggle(node, toggled) {
    const { cursor, data } = this.state;
    if (cursor) {
      this.setState(() => ({ cursor, active: false }));
    }
    node.toggled = toggled;
    node.active = true;
    await this.props.fetchLoopRelations(
      this.props.connectorId,
      node.entity,
      node
    );
    this.setState(() => ({ cursor: node, data: Object.assign({}, data) }));
  }

  handleInputChange = event => {
    const inputValue = event.target.value;

    this.setState(prevState => {
      const filteredData = prevState.data.filter(element => {
        return element.name.toLowerCase().includes(inputValue.toLowerCase());
      });

      return {
        inputValue,
        filteredData,
      };
    });
  };

  constructPath = (tree, finalNode, count = 0) => {
    const node = Object.values(tree).find(node => {
      return node === finalNode || (node && node.toggled);
    });
    if (node) {
      return {
        ...node,
        deep: count,
        id: `loop-${uuidv4()}`,
        settings: {
          sorting: {
            sortingOrder: '0',
          },
          grouping: {
            groupingOrder: '0',
          },
        },
        children:
          node === finalNode
            ? null
            : [this.constructPath(node.children, finalNode, count + 1)],
      };
    } else {
      return [];
    }
  };

  render() {
    const { inputValue, showItems } = this.state;
    const {
      fetchLoopRecords,
      connectorId,
      fetchLoopProperties,
      setInputValue,
      loops_data,
      parentLoopTree,
      disabled = false,
      setValues,
      values,
      additionalFunction,
    } = this.props;

    const Header = ({ style, node }) => {
      const selectedLoopPath = parentLoopTree
        ? this.constructPath([parentLoopTree], node)
        : this.constructPath(this.props.loops_data, node);
      const loopDeep = selectedLoopPath.children ? 1 : 0;

      return (
        <div
          className="tree-label"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            this.setState(
              { inputValue: node.label, showItems: false },
              setValues &&
                setValues({ ...values, name: node.label, fields: [] })
            );

            !parentLoopTree &&
              fetchLoopRecords(connectorId, selectedLoopPath.property);
            !parentLoopTree && fetchLoopProperties(connectorId, node.entity);
            additionalFunction && additionalFunction(loopDeep);
            setInputValue(event, {
              selectedLoopPath,
              filteringDeep: node.deep ? node.deep : 0,
              loopDeep,
            });
          }}
          style={style.base}
        >
          {node.label}
        </div>
      );
    };
    return (
      <div className="treeSelect">
        <input
          className={clsx(
            inputValue && 'addLoop-input-success',
            'addLoop-input'
          )}
          type="text"
          placeholder="Select..."
          value={inputValue}
          disabled={disabled}
          onChange={
            (e => this.setState({ inputValue: e.target.value }),
            this.handleInputChange)
          }
          onClick={() => {
            this.setState({
              data: loops_data,
              filteredData: loops_data,
              showItems: true,
            });
          }}
        />
        <div className="overflow-block">
          {showItems === true ? (
            <Treebeard
              data={parentLoopTree || this.state.filteredData}
              decorators={{ ...decorators, Header }}
              onToggle={parentLoopTree ? () => {} : this.onToggle}
              constructPath={this.constructPath}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    loops_data: store.loops.loops_data,
    connectorId: store.fuses.selectedConnectorId,
    loops_relations: store.loops.relations,
    loop_properties: store.loops.loop_properties,
    currentPath: store.loops.currentPath,
    currentElementIndex: store.loops.currentElementIndex,
    depthOfAttachment: store.loops.depthOfAttachment,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLoopRelations: (connectorId, entity, path) =>
    dispatch(fetchLoopRelations(connectorId, entity, path)),
  fetchLoopRecords: (connectorId, entity) =>
    dispatch(fetchLoopRecords(connectorId, entity)),
  fetchParentLoopData: connectorId =>
    dispatch(fetchParentLoopData(connectorId)),
  setCurrentLoopPath: currentPath => dispatch(setCurrentLoopPath(currentPath)),
  setCurrentElementIndex: currentElementIndex =>
    dispatch(setCurrentElementIndex(currentElementIndex)),
  setDepthOfAttachment: (depthOfAttachment, path) =>
    dispatch(setDepthOfAttachment(depthOfAttachment, path)),
  fetchLoopProperties: (connectorId, entity) =>
    dispatch(fetchLoopProperties(connectorId, entity)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeSelect);
