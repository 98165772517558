import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import yellow_triangle from '../../../assets/svgs/sidebar-triangle.svg';
import green_triangle from '../../../assets/svgs/sidebar-triangle-2.svg';

import { Data } from '../../mocks/SideBar';
import theme from './styles.module.scss';

export const SideBar = () => {
  return (
    <section className={theme.sidebarContainer}>
      <div className={theme.navbar}>
        <ul className={clsx(theme.links, theme.topLinks)}>
          {Data.main.map((item, index) => (
            <li className={theme.navigationLinks} key={index}>
              {item.customClass ? (
                <p>
                  <img src={item.icon} alt="icon" />
                  {item.text}
                </p>
              ) : (
                <NavLink to={item.to} activeClassName={theme.activeLink}>
                  <img src={item.icon} alt="icon" />
                  {item.text}
                </NavLink>
              )}
            </li>
          ))}
          <ul className={theme.links}>
            {Data.admin.map((item, index) => (
              <li
                className={clsx(theme.navigationLinks, theme.subItems)}
                key={index}
              >
                <NavLink to={item.to} activeClassName={theme.activeLink}>
                  <img src={item.icon} alt="icon" />
                  {item.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </ul>
      </div>
      <div className={theme.imgBox}>
        <img
          className={theme.yellowIcon}
          src={yellow_triangle}
          alt="back-icon"
        />
        <img className={theme.greenIcon} src={green_triangle} alt="back-icon" />
      </div>
    </section>
  );
};
