import view from '../../../assets/svgs/view.svg';
import edit from '../../../assets/svgs/edit.svg';
import cross from '../../../assets/svgs/cross.svg';

export const headRows = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'connectorName',
    label: 'Connector',
  },
  {
    id: 'owner',
    label: 'Owner',
  },
];

export const icons = {
  view,
  edit,
  cross,
};
