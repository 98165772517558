import React, { Component } from 'react';
import { Checkbox, Button } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import { StyledTableRow, StyledTableCell } from '../../components/Table/style';
import TableComponent from '../../components/Table';
import CreateNewUserModal from '../../components/Users/CreateNewUserModal';
import {
  fetchUsers,
  removeUsers,
  sendInviteLink,
  resetNotifications,
} from '../../store/users';
import { headRows, icons } from '../../components/mocks/Users';
import { DashboardLayout } from '../../components/Dashboard/DashboardLayout';
import { StyledButton } from '../../components/common';
import { ConfirmModal } from '../../components/common/ConfirmModal/ConfirmModal';
import { SnackBar } from '../../components';
import { getDeletedItems } from '../../helpers/getDeletedItems';

import orderBy from 'lodash/orderBy';

import { invertDirection } from '../../helpers/invertDirection';

class Users extends Component {
  state = {
    openModal: false,
    selectedUsers: [],
    page: 0,
    rowsPerPage: 5,
    showConfirmModal: false,
    deleteCurrentUser: false,
    columnToSort: '',
    sortDirection: '',
  };

  componentDidMount() {
    this.props.fetchUsers();
  }

  handleOpenModal = () => {
    this.setState(prevState => ({
      openModal: false,
      showConfirmModal: false,
    }));
  };

  closeNotification = () => {
    this.setState({ deleteCurrentUser: false });
    this.props.resetNotifications();
  };

  handleSelectAllClick = () => {
    const { users } = this.props;
    const { selectedUsers } = this.state;
    if (selectedUsers.length === 0) {
      const selectAll = users.map(user => user.id);
      return this.setState({ selectedUsers: selectAll });
    }
    this.setState({ selectedUsers: [] });
  };

  handleClick = (event, id) => {
    const { selectedUsers } = this.state;
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedUsers: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  openConfirmModal = () => {
    if (this.state.selectedUsers.length > 0) {
      this.setState({ showConfirmModal: true });
    }
  };

  handleRemoveUsers = () => {
    const { selectedUsers } = this.state;
    const deleteVerify = selectedUsers.find(user => {
      return user === this.props.currentUser.id;
    });
    if (deleteVerify) {
      this.setState({ showConfirmModal: false, deleteCurrentUser: true });
    } else {
      this.handleOpenModal();
      this.props.removeUsers(selectedUsers);
      this.setState({ selectedUsers: [] });
    }
  };

  handleEditButton = () => {
    return this.state.selectedUsers.length === 1 &&
      this.state.selectedUsers.find(user => this.props.currentUser.id === user)
      ? false
      : true;
  };

  sendInviteButton = () => {
    const { selectedUsers } = this.state;
    const activatedUsers = this.props.users
      .filter(user => {
        if (user.isEnabled === true) {
          return user.id;
        }
        return false;
      })
      .map(user => user.id);
    const buttonStatus = activatedUsers.some(activatedUser =>
      selectedUsers.includes(activatedUser)
    );

    return buttonStatus || !selectedUsers.length ? true : false;
  };

  handleSort = columnName => {
    this.setState(prevState => ({
      columnToSort: columnName,
      sortDirection:
        prevState.columnToSort === columnName
          ? invertDirection[prevState.sortDirection]
          : 'asc',
    }));
  };

  render() {
    const {
      openModal,
      selectedUsers,
      rowsPerPage,
      page,
      showConfirmModal,
      deleteCurrentUser,
      columnToSort,
      sortDirection,
    } = this.state;
    const {
      users,
      history,
      sendInviteLink,
      showNotification,
      notificationMessage,
      notificationVariant,
      resetNotifications,
    } = this.props;

    const isSelected = id => selectedUsers.indexOf(id) !== -1;
    return (
      <DashboardLayout>
        <section className="table-wrapper">
          <SnackBar
            open={showNotification || deleteCurrentUser}
            message={
              deleteCurrentUser
                ? 'Please contact Webfuse if you want to delete your own user at support@webfuse.io'
                : notificationMessage
            }
            variant={deleteCurrentUser ? 'Error' : notificationVariant}
            close={
              deleteCurrentUser ? this.closeNotification : resetNotifications
            }
          />
          <p className="title-text">
            Users are something cool. They are the representatives of your
            company and the creators of Fuses. Cherish them!
            <br />
            Invite as many as you like or remove them if you don’t like them
            anymore.
          </p>
          <div>
            <StyledButton
              action={() => this.setState({ openModal: true })}
              width="auto"
            >
              + Create new User
            </StyledButton>
          </div>
          <TableComponent
            headRows={headRows}
            handleSelectAllClick={this.handleSelectAllClick}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            selectedLength={selectedUsers.length}
            totalLength={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            handleSort={this.handleSort}
            columnToSort={columnToSort}
            sortDirection={sortDirection}
            headNav={
              <div>
                <Button
                  className="head_nav"
                  disabled={this.handleEditButton()}
                  onClick={() => history.push('/profile')}
                >
                  <img src={icons.edit} alt="nav" className="head_nav_icon" />
                  Edit
                </Button>

                <Button
                  className="head_nav"
                  onClick={this.openConfirmModal}
                  disabled={!selectedUsers.length}
                >
                  <img src={icons.cross} alt="nav" className="head_nav_icon" />
                  Remove
                </Button>
                <Button
                  className="head_nav"
                  disabled={this.sendInviteButton()}
                  onClick={() => {
                    sendInviteLink(selectedUsers);
                    this.setState(({ notifications }) => {
                      return {
                        notifications: {
                          successSendInvite: true,
                          errorSendInvite: true,
                        },
                      };
                    });
                  }}
                >
                  <i
                    className={clsx('fas fa-paper-plane', ' head_nav_icon')}
                  ></i>
                  Send invite
                </Button>
              </div>
            }
          >
            {users &&
              orderBy(users, columnToSort, sortDirection)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const isUserSelected = isSelected(row.id);
                  return (
                    <StyledTableRow
                      hover
                      key={row.id}
                      onClick={async event => {
                        if (this.props.currentUser.id === row.id) {
                          history.push('/profile');
                        }
                      }}
                      role="checkbox"
                      selected={isUserSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="default"
                          onClick={event => {
                            event.stopPropagation();
                            this.handleClick(event, row.id);
                          }}
                          checked={isUserSelected}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.firstName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.lastName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.isEnabled ? 'Yes' : 'No'}
                      </StyledTableCell>
                      {/* <StyledTableCell component="th" scope="row">
                        {row.roles}
                      </StyledTableCell> */}
                    </StyledTableRow>
                  );
                })}
          </TableComponent>
          <CreateNewUserModal
            isOpened={openModal}
            handleOpenModal={this.handleOpenModal}
          />
          <ConfirmModal
            open={showConfirmModal}
            header={
              selectedUsers &&
              getDeletedItems(users, selectedUsers, 'firstName').length > 1
                ? 'Remove users'
                : 'Remove user'
            }
            text={
              selectedUsers &&
              getDeletedItems(users, selectedUsers, 'firstName').length > 1
                ? 'Are you sure you want to remove users '
                : 'Are you sure you want to remove user '
            }
            deletedItems={getDeletedItems(users, selectedUsers, 'firstName')}
            action={this.handleRemoveUsers}
            handleClose={this.handleOpenModal}
          />
        </section>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = store => {
  return {
    users: store.users.users,
    user_id: store.users.user_id,
    currentUser: store.profile.userData,
    showNotification: store.users.showNotification,
    notificationMessage: store.users.notificationMessage,
    notificationVariant: store.users.notificationVariant,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchUsers, removeUsers, sendInviteLink, resetNotifications },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Users));
