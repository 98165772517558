import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Modal from '../../Modal';
import theme from './styles.module.scss';

class ConnectorNotificationModal extends Component {
  render() {
    const { isOpened, handleNotificationModal, errorMessage } = this.props;
    return (
      <Modal
        isOpen={isOpened}
        title="Connector notification modal"
        className={theme.connectorNotificationWrapper}
      >
        <div className={theme.connectorNotification}>
          <header className={theme.connectorNotificationHeader}>
            <h1>Make a new Connector</h1>
          </header>
          {errorMessage ? (
            <main>
              <i
                className={clsx('fas', theme.fas, 'fa-exclamation-triangle')}
              ></i>
              <p>Error, something went wrong. Failed to create Connector. Please check your URL or environment and try again later.</p>
            </main>
          ) : (
            <main>
              {' '}
              <i className={clsx('fas', theme.fas, 'fa-medal')} />
              <p>
                Congratulations! <br /> You`ve successfully authorized your
                connection to your Dynamics 365 or Dataverse environment.
                <br />
                You can now make Fuses with this Connector.
              </p>
            </main>
          )}
          <footer className={theme.connectorFooter}>
            <button
              className={theme.connectorButton}
              onClick={handleNotificationModal}
            >
              Finish
            </button>
          </footer>
        </div>
      </Modal>
    );
  }
}

ConnectorNotificationModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleNotificationModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.bool,
};

export default ConnectorNotificationModal;
