import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import CheckboxInputPair from './CheckboxInputPair';
import theme from './styles.module.scss';
import { StyledButton } from '../../common';

import { getLastField } from '../HtmlEditor/helpers/helpers';

class LoopSettings extends Component {
  render() {
    const { selectedLoop, handleLoopSettings, handleCloseSidebar } = this.props;

    return (
      <section className={theme.loopSettings}>
        <Formik
          initialValues={
            selectedLoop.settings && {
              sorting: selectedLoop.settings.sorting,
              limiting: selectedLoop.settings.limiting,
              grouping: selectedLoop.settings.grouping,
            }
          }
          enableReinitialize={true}
          onSubmit={values => {
            handleLoopSettings(values);
            handleCloseSidebar();
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue }) => {
            return (
              <>
                <header>
                  <h3>Loop settings</h3>
                </header>

                <article className={theme.generalContainer}>
                  <div className={theme.articleSection}>
                    <div className={theme.header_wrapper}>
                      <h4>Sorting options </h4>
                      {/* <StyledButton
                        customStyle={{
                          width: '100px',
                          color: '#226cad',
                          background: 'transparent',
                          padding: '0',
                          margin: '0',
                          height: 'auto',
                        }}
                        action={openEditLoopModal}
                      >
                        <i className="fas fa-cog"></i>Edit
                      </StyledButton> */}
                    </div>

                    <label htmlFor="sorting">Field to sort on</label>
                    <select
                      name="sorting.field"
                      className={theme.loopSettingsSelect}
                      onChange={handleChange}
                      value={values.sorting.field || ''}
                    >
                      {getLastField(selectedLoop).length > 1 && (
                        <option value="">Choose item</option>
                      )}
                      {selectedLoop &&
                        getLastField(selectedLoop).map(
                          ({ field, settings }, index) => (
                            <option
                              key={`${index}${field}`}
                              value={field}
                              name="sorting"
                            >
                              {field}
                            </option>
                          )
                        )}
                    </select>
                    <p>Sorting order </p>
                    <label>
                      <input
                        className={theme.radioBox}
                        type="radio"
                        name="sorting.sortingOrder"
                        value={'0'}
                        onChange={handleChange}
                        checked={'0' === `${values.sorting.sortingOrder}`}
                      />
                      Default
                    </label>
                    <label>
                      <input
                        className={theme.radioBox}
                        type="radio"
                        name="sorting.sortingOrder"
                        value={'1'}
                        onChange={handleChange}
                        checked={'1' === `${values.sorting.sortingOrder}`}
                      />
                      Ascending (A to Z)
                    </label>

                    <label>
                      <input
                        className={theme.radioBox}
                        type="radio"
                        name="sorting.sortingOrder"
                        value={'2'}
                        onChange={handleChange}
                        checked={'2' === `${values.sorting.sortingOrder}`}
                      />
                      Descending (Z to A)
                    </label>
                  </div>
                </article>
                <article className={theme.generalContainer}>
                  <div className={theme.articleSection}>
                    <h4>Limiting options</h4>
                    <CheckboxInputPair
                      label="showFirst"
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      selectedLoop={selectedLoop}
                      nameOfField={'only show first few items'}
                      descriptionField="items to show"
                    />
                    <CheckboxInputPair
                      label="showLast"
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      selectedLoop={selectedLoop}
                      nameOfField={'only show last few items'}
                      descriptionField="items to show"
                    />
                    <CheckboxInputPair
                      label="exceptFirst"
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      selectedLoop={selectedLoop}
                      nameOfField={'show all items, except'}
                      descriptionField="first items"
                    />
                    <CheckboxInputPair
                      label="exceptLast"
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      selectedLoop={selectedLoop}
                      nameOfField={'show all items, except '}
                      descriptionField="last items"
                    />
                  </div>
                </article>
                <article className={theme.generalContainer}>
                  <div className={theme.articleSection}>
                    <h4>Grouping options</h4>
                    <label htmlFor="sortOn">Field to group on</label>
                    <select
                      name="grouping.field"
                      className={theme.loopSettingsSelect}
                      onChange={handleChange}
                      value={values.grouping.field || ''}
                    >
                      {getLastField(selectedLoop).length > 1 && (
                        <option value="">Choose item</option>
                      )}
                      {selectedLoop &&
                        getLastField(selectedLoop).map(
                          ({ field, settings }, index) => (
                            <option
                              key={`${index}${field}`}
                              value={field}
                              name="grouping"
                            >
                              {field}
                            </option>
                          )
                        )}
                    </select>
                    <p>Grouping order </p>
                    <label>
                      <input
                        className={theme.radioBox}
                        type="radio"
                        name="grouping.groupingOrder"
                        value={'0'}
                        onChange={handleChange}
                        checked={'0' === `${values.grouping.groupingOrder}`}
                      />
                      Default
                    </label>
                    <label>
                      <input
                        className={theme.radioBox}
                        type="radio"
                        name="grouping.groupingOrder"
                        value={'1'}
                        onChange={handleChange}
                        checked={'1' === `${values.grouping.groupingOrder}`}
                      />
                      Ascending (A to Z)
                    </label>

                    <label>
                      <input
                        className={theme.radioBox}
                        type="radio"
                        name="grouping.groupingOrder"
                        value={'2'}
                        onChange={handleChange}
                        checked={'2' === `${values.grouping.groupingOrder}`}
                      />
                      Descending (Z to A)
                    </label>
                    <StyledButton
                      action={handleSubmit}
                      customStyle={{ margin: '20px 10px' }}
                    >
                      Apply
                    </StyledButton>
                  </div>
                </article>
              </>
            );
          }}
        </Formik>
      </section>
    );
  }
}

LoopSettings.propTypes = {
  handleLoopSettings: PropTypes.func.isRequired,
  selectedLoop: PropTypes.object.isRequired,
};

export default LoopSettings;
