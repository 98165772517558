import Immutable from 'seamless-immutable';

import { baseRequest } from '../helpers/base-request';
import { isString } from '../helpers/isString';

const types = {
  FETCH_PARENT_LOOP_DATA_REQUEST: 'FETCH_PARENT_LOOP_DATA_REQUEST',
  FETCH_PARENT_LOOP_DATA_SUCCESS: 'FETCH_PARENT_LOOP_DATA_SUCCESS',
  FETCH_PARENT_LOOP_DATA_ERROR: 'FETCH_PARENT_LOOP_DATA_ERROR',

  FETCH_RELATIONS_DATA_REQUEST: 'FETCH_RELATIONS_DATA_REQUEST',
  FETCH_RELATIONS_DATA_SUCCESS: 'FETCH_RELATIONS_DATA_SUCCESS',
  FETCH_RELATIONS_DATA_ERROR: 'FETCH_RELATIONS_DATA_ERROR',

  FETCH_PROPERTIES_DATA_REQUEST: 'FETCH_PROPERTIES_DATA_REQUEST',
  FETCH_PROPERTIES_DATA_SUCCESS: 'FETCH_PROPERTIES_DATA_SUCCESS',
  FETCH_PROPERTIES_DATA_ERROR: 'FETCH_PROPERTIES_DATA_ERROR',

  FETCH_LOOP_RECORDS_REQUEST: 'FETCH_LOOP_RECORDS_REQUEST',
  FETCH_LOOP_RECORDS_SUCCESS: 'FETCH_LOOP_RECORDS_SUCCESS',
  FETCH_LOOP_RECORDS_ERROR: 'FETCH_LOOP_RECORDS_ERROR',

  SET_CURRENT_LOOP_PATH: 'SET_CURRENT_LOOP_PATH',
  SET_LOOP_INDEX: 'SET_LOOP_INDEX',

  SET_DEPTH_OF_ATTACHMENT: 'SET_DEPTH_OF_ATTACHMENT',

  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
};

const initialState = Immutable({
  loops_data: [],
  relations: [],
  loop_properties: [],
  loop_records: [],
  currentPath: null,
  currentElementIndex: null,
  depthOfAttachment: 0,
  loading: false,
  showNotification: false,
  notificationVariant: '',
  notificationMessage: '',
});

const fetchParentLoopDataRequest = () => ({
  type: types.FETCH_PARENT_LOOP_DATA_REQUEST,
});

const fetchRelationsDataRequest = () => ({
  type: types.FETCH_RELATIONS_DATA_REQUEST,
});

const fetchPropertiesDataRequest = () => ({
  type: types.FETCH_PROPERTIES_DATA_REQUEST,
});

const fetchLoopRecordsRequest = () => ({
  type: types.FETCH_LOOP_RECORDS_REQUEST,
});

export const setDepthOfAttachment = (depthOfAttachment, path) => ({
  type: types.SET_DEPTH_OF_ATTACHMENT,
  depthOfAttachment,
  path,
});

export const setCurrentLoopPath = currentPath => ({
  type: types.SET_CURRENT_LOOP_PATH,
  currentPath,
});

export const setCurrentElementIndex = currentElementIndex => ({
  type: types.SET_LOOP_INDEX,
  currentElementIndex,
});

export const resetNotifications = () => ({
  type: types.RESET_NOTIFICATIONS,
});

export const fetchParentLoopData = connectorId => {
  const success = response => ({
    type: types.FETCH_PARENT_LOOP_DATA_SUCCESS,
    loops_data: response,
  });

  const failure = error => ({
    type: types.FETCH_PARENT_LOOP_DATA_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchParentLoopDataRequest());
    return baseRequest('GET', `/api/Connectors/${connectorId}/entities`)
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchLoopRelations = (connectorId, entity, node) => {
  const success = response => ({
    type: types.FETCH_RELATIONS_DATA_SUCCESS,
    relations: response,
    node,
  });

  const failure = error => ({
    type: types.FETCH_RELATIONS_DATA_ERROR,
    payload: isString(error),
  });

  node.loading = true;
  return dispatch => {
    dispatch(fetchRelationsDataRequest());
    return baseRequest(
      'GET',
      `/api/Connectors/${connectorId}/entities/${entity}/relations`
    )
      .then(response => {
        node.loading = false;
        dispatch(success(response));
      })
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchLoopProperties = (connectorId, entity) => {
  const success = response => {
    return {
      type: types.FETCH_PROPERTIES_DATA_SUCCESS,
      loop_properties: response,
    };
  };

  const failure = error => ({
    type: types.FETCH_PROPERTIES_DATA_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchPropertiesDataRequest());
    return baseRequest(
      'GET',
      `/api/Connectors/test/${connectorId}/entities/${entity}/properties`
    )
      .then(response => dispatch(success(response)))
      .catch(error => dispatch(failure(error)));
  };
};

export const fetchLoopRecords = (connectorId, entity) => {
  const success = response => {
    return {
      type: types.FETCH_LOOP_RECORDS_SUCCESS,
      loop_records: response,
    };
  };

  const failure = error => ({
    type: types.FETCH_LOOP_RECORDS_ERROR,
    payload: isString(error),
  });

  return dispatch => {
    dispatch(fetchLoopRecordsRequest());
    return baseRequest(
      'GET',
      `/api/Connectors/${connectorId}/entities/${entity}/records`
    )
      .then(response => dispatch(success(response.data)))
      .catch(error => dispatch(failure(error)));
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PARENT_LOOP_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_PARENT_LOOP_DATA_SUCCESS:
      return {
        ...state,
        loops_data: action.loops_data.data.map(item => ({
          ...item,
          name: item.label,
          children: [],
        })),
        loading: false,
      };

    case types.FETCH_PARENT_LOOP_DATA_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_RELATIONS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_RELATIONS_DATA_SUCCESS:
      action.node.children = action.relations.data.map(item => ({
        ...item,
        name: item.entity,
        children: [],
      }));
      action.node.loading = false;
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_RELATIONS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_PROPERTIES_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_PROPERTIES_DATA_SUCCESS:
      return {
        ...state,
        loop_properties: action.loop_properties.data,
        loading: false,
      };

    case types.FETCH_PROPERTIES_DATA_ERROR:
      return {
        ...state,
        loop_properties: action.loop_properties
          ? action.loop_properties.data
          : {},
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.FETCH_LOOP_RECORDS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_LOOP_RECORDS_SUCCESS:
      return {
        ...state,
        loop_records: action.loop_records,
        loading: false,
      };

    case types.FETCH_LOOP_RECORDS_ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        notificationVariant: 'error',
        notificationMessage: action.payload,
      };

    case types.SET_CURRENT_LOOP_PATH:
      return Immutable.set(state, 'currentPath', action.currentPath);

    case types.SET_LOOP_INDEX:
      return Immutable.set(
        state,
        'currentElementIndex',
        action.currentElementIndex
      );

    case types.SET_DEPTH_OF_ATTACHMENT:
      const currentDeepPath = state.depthOfAttachment;
      const newDeepOfAttachment = action.depthOfAttachment;
      const currPath = state.currentPath;
      const newPath = action.path;
      if (currentDeepPath > newDeepOfAttachment) {
        const deepPath = newDeepOfAttachment - currentDeepPath;
        return (
          Immutable.set(
            state,
            ['currentPath'],
            currPath.slice(0, -11 * deepPath)
          ),
          Immutable.setIn(state, ['depthOfAttachment'], newDeepOfAttachment)
        );
      } else if (currentDeepPath < newDeepOfAttachment) {
        return Immutable.setIn(state, ['currentPath'], newPath);
      } else return state;

    case types.RESET_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        showNotification: false,
        notificationVariant: '',
        notificationMessage: '',
      };
    default:
      return state;
  }
}
