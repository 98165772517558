import React from 'react';
import { Button, Icon } from './ToolbarControls';

const DEFAULT_NODE = 'p';

const hasBlock = (value, type) => {
  return value.blocks.some(node => node.type === type);
};

export const BlockButton = ({ editor, value, type, icon }) => {
  let isActive = hasBlock(value, type);

  if (['ol', 'ul'].includes(type)) {
    const { document, blocks } = value;

    if (blocks.size > 0) {
      const parent = document.getParent(blocks.first().key);
      isActive = hasBlock(value, 'li') && parent && parent.type === type;
    }
  }

  return (
    <Button
      active={isActive}
      onMouseDown={event => onClickBlock(event, editor, value, type)}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const onClickBlock = (event, editor, value, type) => {
  event.preventDefault();

  const { document } = value;

  // Handle everything but list buttons.
  if (type !== 'ol' && type !== 'ul') {
    const isActive = hasBlock(value, type);
    const isList = hasBlock(value, 'li');

    if (isList) {
      editor
        .setBlocks(isActive ? DEFAULT_NODE : type)
        .unwrapBlock('ol')
        .unwrapBlock('ul');
    } else {
      editor.setBlocks(isActive ? DEFAULT_NODE : type);
    }
  } else {
    // Handle the extra wrapping required for list buttons.
    const isList = hasBlock(value, 'li');
    const isType = value.blocks.some(block => {
      return !!document.getClosest(block.key, parent => parent.type === type);
    });

    if (isList && isType) {
      editor
        .setBlocks(DEFAULT_NODE)
        .unwrapBlock('ol')
        .unwrapBlock('ul');
    } else if (isList) {
      editor.unwrapBlock(type === 'ul' ? 'ol' : 'ul').wrapBlock(type);
    } else {
      editor.setBlocks('li').wrapBlock(type);
    }
  }
};
