import { baseRequest } from '../helpers/base-request';

const types = {
  FETCH_FORM_RELATIONS_REQUEST: 'FETCH_FORM_RELATIONS_REQUEST',
  FETCH_FORM_RELATIONS_SUCCESS: 'FETCH_FORM_RELATIONS_SUCCESS',
  FETCH_FORM_RELATIONS_ERROR: 'FETCH_FORM_RELATIONS_ERROR',
};

const initialState = {
  relations: [],
};

const request = () => ({
  type: types.FETCH_FORM_RELATIONS_REQUEST,
});

const success = data => ({
  type: types.FETCH_FORM_RELATIONS_SUCCESS,
  payload: data,
});

const failure = error => ({
  type: types.FETCH_FORM_RELATIONS_ERROR,
  payload: error,
});

export const fetchFormRelations = (connectorId, entity) => {
  return dispatch => {
    dispatch(request());
    return baseRequest(
      'GET',
      `/api/Connectors/${connectorId}/entities/${entity}/relations`
    )
      .then(response => {
        dispatch(success(response.data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_FORM_RELATIONS_REQUEST:
      return {
        ...state,
        isRequesting: true,
        isFailure: false,
        isSuccessful: false,
      };
    case types.FETCH_FORM_RELATIONS_SUCCESS:
      return {
        ...state,
        relations: action.payload,
        isRequesting: false,
        isFailure: false,
        isSuccessful: true,
      };
    case types.FETCH_FORM_RELATIONS_ERROR:
      return {
        ...state,
        isRequesting: false,
        isFailure: true,
        isSuccessful: false,
      };
    default:
      return state;
  }
}
