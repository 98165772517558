import React from 'react';

import Modal from '../../Modal';
import { StyledButton } from '../../common';
import AutoComplete from '../AutoComplete';
import TreeSelect from './TreeComponent';

const SelectFieldToLoop = ({
  values,
  showModalField,
  hideModalField,
  isOpen,
  setSelectedFieldLoop,
  setFieldValue,
  loop_properties,
  parentLoopTree,
}) => {
  const setValue = (event, { selectedLoopPath, filteringDeep }) => {
    setSelectedFieldLoop(event, { setFieldValue, filteringDeep });
  };
  const close = () => {
    hideModalField();
    setFieldValue('currentCondition.field', '');
  };

  const { field, dynamicsEntity } = values.currentCondition;

  const isDisabled = !(field && dynamicsEntity);

  return (
    <Modal title="Field to compare" isOpen={isOpen}>
      <section className="select-field-to-loop">
        <header>
          <h1>Select field</h1>
          <p>Select a field for use in your condition.</p>
        </header>
        <div>
          <label className="head-txt">Dynamics 365 entity</label>
          <TreeSelect
            setInputValue={setValue}
            parentLoopTree={parentLoopTree}
            values={values}
          />
          <AutoComplete
            label="Field"
            name="currentCondition.field"
            suggestions={loop_properties.map(
              ({ label, field, type, value }) => ({
                label: label,
                value: field,
              })
            )}
            setFieldFormik={setFieldValue}
            valueFormik={field}
            isDisabled={!dynamicsEntity}
          />
        </div>
        <footer>
          <StyledButton type="button" action={close} variant="cancel">
            Cancel
          </StyledButton>
          <StyledButton
            type="button"
            action={hideModalField}
            disabled={isDisabled}
          >
            Add
          </StyledButton>
        </footer>
      </section>
    </Modal>
  );
};

export default SelectFieldToLoop;
