import React from 'react';
import PropTypes from 'prop-types';

import theme from './styles.module.scss';

const Container = ({ children, width }) => (
  <div style={{ width: width }} className={theme.mainContainer}>
    <div className={theme.mainContainerContent}>{children}</div>
  </div>
);

Container.propTypes = {
  width: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Container;
