import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import { createConnector, editConnector } from '../../../store/connectors';
import { StyledInput, StyledButton } from '../../common';
import {
  validateNameforSpaces,
  validateConnectorName,
  validateConnectorUrl,
} from '../../../helpers/validations';

import theme from './styles.module.scss';

class ConnectorModal extends Component {
  state = {
    editDisableButton: true,
  };

  handleChange = ({ target }) => {
    if (target.value === this.props.connectorData[target.name]) {
      this.setState({ editDisableButton: true });
    } else if (
      this.props.connectorData[target.name] &&
      validateNameforSpaces(target.value)
    ) {
      this.setState({ editDisableButton: false });
    } else {
      this.setState({ editDisableButton: true });
    }
  };
  render() {
    const { editDisableButton } = this.state;
    const {
      isOpened,
      handleOpenModal,
      edit = false,
      connectorData,
      createConnector,
      editConnector,
    } = this.props;

    return (
      <Modal
        className={theme.createConnectorWrapper}
        isOpen={isOpened}
        title="Create Connector"
      >
        <div className={theme.createConnector}>
          <header className={theme.createConnectorHeader}>
            {edit ? (
              <h1>Edit Connector</h1>
            ) : (
              <>
                <h1>Create new Connector</h1>
                <p>
                  Create a new Connector. Give it a name and use the URL of your
                  Dynamics 365 or Dataverse environment e.g:
                  https://example.crm4.dynamics.com.
                </p>
              </>
            )}
          </header>
          <Formik
            initialValues={
              connectorData
                ? {
                    name: this.props.connectorData.name,
                    url: this.props.connectorData.resource,
                  }
                : { name: '', url: '' }
            }
            validationSchema={
              edit
                ? Yup.object().shape({
                    name: Yup.string()
                      .min(2, 'Please enter a Name')
                      .required('Required')
                      .test(
                        'This name is already in use, please choose another one',
                        'This name is already in use, please choose another one',
                        value =>
                          validateConnectorName(this.props.connectors, value)
                      ),
                  })
                : Yup.object().shape({
                    name: Yup.string()
                      .min(2, 'Please enter a Name')
                      .required('Required')
                      .test(
                        'This name is already in use, please choose another one',
                        'This name is already in use, please choose another one',
                        value =>
                          validateConnectorName(this.props.connectors, value)
                      ),

                    url: Yup.string()
                      .url('Please enter a valid URL')
                      .required('Required')
                      .test(
                        'This Dynamics 365 environment is already connected to Webfuse',
                        'This Dynamics 365 environment is already connected to Webfuse',
                        value =>
                          validateConnectorUrl(this.props.connectors, value)
                      ),
                  })
            }
            onSubmit={values => {
              const { name, url } = values;
              if (edit) {
                editConnector(connectorData.id, name);
                this.setState({ editDisableButton: true });
                handleOpenModal();
              } else {
                createConnector(name, url);
                handleOpenModal();
              }
            }}
          >
            {({ handleSubmit, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {edit ? (
                    <StyledInput
                      type="text"
                      name="name"
                      label="Name"
                      value={values.name}
                      handleChange={this.handleChange}
                    />
                  ) : (
                    <StyledInput
                      type="text"
                      name="name"
                      label="Name"
                      value={values.name}
                    />
                  )}
                  <StyledInput
                    type="text"
                    name="url"
                    label="Dynamics 365 URL"
                    value={values.url}
                    disabled={edit && true}
                  />
                  <footer>
                    <StyledButton
                      width="100"
                      action={() => {
                        handleOpenModal();
                        this.setState({ editDisableButton: true });
                      }}
                      variant="cancel"
                    >
                      Cancel
                    </StyledButton>
                    {edit ? (
                      <StyledButton
                        width="100"
                        type="submit"
                        disabled={editDisableButton}
                      >
                        Edit
                      </StyledButton>
                    ) : (
                      <StyledButton
                        width="100"
                        type="submit"
                        disabled={Object.values(values).some(
                          value => value === ''
                        )}
                      >
                        Authorize
                      </StyledButton>
                    )}
                  </footer>
                </form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = store => ({
  connectors: store.connectors.connectors,
  connectorNameIndex: store.connectors.connectorNameIndex,
});

const mapDispatchToProps = dispatch => ({
  createConnector: (name, url) => dispatch(createConnector(name, url)),
  editConnector: (id, name) => dispatch(editConnector(id, name)),
});

ConnectorModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  connectorData: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectorModal);
