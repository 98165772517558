import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { validateNameforSpaces } from '../../helpers/validations';

import { passwordStrength } from '../../helpers/validations';

import styles from './styles.module.scss';

const PasswordStrength = ({ password }) => {
  const status =
    password.length > 5 && validateNameforSpaces(password)
      ? passwordStrength(password)
      : null;
  return (
    status && (
      <div className={styles.notification}>
        <span className="description">Password strength: </span>
        <div className={clsx(styles.tooltipBox, styles[status.class])}>
          {status.title}
          <span
            className={clsx(
              styles.hiddenTooltip,
              status.title !== 'Medium' && styles.tooltipSize
            )}
          >
            <p>{status.tooltip}</p>
          </span>
        </div>
      </div>
    )
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string,
};

export default PasswordStrength;
