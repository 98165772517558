import React from 'react';

export class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBlockBtn: false,
    };
  }

  onInsertTable = () => {
    const { onChange, editor } = this.props;
    onChange(editor.insertTable());
  };

  onInsertColumn = () => {
    const { onChange, editor } = this.props;
    onChange(editor.insertColumn());
  };

  onInsertRow = () => {
    const { onChange, editor } = this.props;
    onChange(editor.insertRow());
  };

  onRemoveColumn = () => {
    const { onChange, editor } = this.props;
    onChange(editor.removeColumn());
  };

  onRemoveRow = () => {
    const { onChange, editor } = this.props;
    onChange(editor.removeRow());
  };

  onRemoveTable = () => {
    const { onChange, editor } = this.props;
    onChange(editor.removeTable());
  };

  onToggleHeaders = () => {
    const { onChange, editor } = this.props;
    onChange(editor.toggleTableHeaders());
  };

  renderNormalToolbar = () => {
    return (
      <div className="insertTableButton buttons">
        <button onClick={this.onInsertTable}>Insert Table</button>
      </div>
    );
  };

  renderTableToolbar = () => {
    return (
      <div className="buttons">
        <button onClick={this.onInsertTable}>
          Insert Table
          <span className="icon material-icons">keyboard_arrow_down</span>
        </button>
        <div className="additionalButtons">
          <button onClick={this.onInsertColumn}>Insert Column</button>
          <button onClick={this.onInsertRow}>Insert Row</button>
          <button onClick={this.onRemoveColumn}>Remove Column</button>
          <button onClick={this.onRemoveRow}>Remove Row</button>
          <button onClick={this.onRemoveTable}>Remove Table</button>
          <button onClick={this.onToggleHeaders}>Toggle Headers</button>
        </div>
      </div>
    );
  };

  render() {
    const { editor, value } = this.props;
    const isTable = editor && editor.isSelectionInTable(value);

    return (
      <div>
        {isTable ? this.renderTableToolbar() : this.renderNormalToolbar()}
      </div>
    );
  }
}
